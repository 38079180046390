import { useCallback, useMemo, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useAuth } from "context/AuthContext";
import { useError } from "context/ErrorContext";

import {
  getHistoriesPage,
  createHistory,
  updateHistory,
  deleteHistory,
  deleteHistoryBulk,
} from "api/history";

import {
  Table,
  Actions,
  Pagination,
  BulkActions,
} from "components/Table/Table";
import { Checkbox } from "components/Checkbox/Checkbox";
import Spinner from "components/Spinner/Spinner";
import EditHistoryModal from "components/EditHistoryModal";
import ConfirmModal from "components/ConfirmModal";

/**
 * HISTORIE
 *
 * /history
 *
 */

const History = () => {
  const { authData } = useAuth();
  const { showError } = useError();
  const { t, i18n } = useTranslation();

  const [histories, setHistories] = useState([]); // all the visible rows in the table
  const [history, setHistory] = useState(null); // the selected row in the table

  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showConfirmBulkDeleteModal, setShowConfirmBulkDeleteModal] =
    useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // callback for selecting a row with the checkbox

  const handleRowSelect = useCallback(
    (row) => {
      const selectedIndex = selectedRows.findIndex(
        (selectedRow) => selectedRow.id === row.id,
      );

      if (selectedIndex > -1) {
        setSelectedRows((prevSelectedRows) =>
          prevSelectedRows.filter((r) => r.id !== row.id),
        );
      } else {
        setSelectedRows((prevSelectedRows) => [...prevSelectedRows, row]);
      }
    },
    [selectedRows],
  );

  // handle sorting change

  const handleSortingChange = async (sortBy) => {
    if (sortBy.length) {
      setSortBy(sortBy[0].id);
      setSortOrder(sortBy[0].desc ? "DESC" : "ASC");
    }
  };

  // callback for selecting all rows with the checkbox in the header

  const handleRowSelectAll = useCallback(() => {
    if (selectedRows.length === histories.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(histories);
    }
  }, [selectedRows, histories]);

  // callbacks for the row actions

  const handleRowEdit = (data) => {
    setHistory(data);
    setShowEditModal(true);
  };

  const handleRowDelete = (data) => {
    setHistory(data);
    setShowConfirmDeleteModal(true);
  };

  // callbacks for the bulk actions

  const handleBulkDelete = (data) => {
    setShowConfirmBulkDeleteModal(true);
  };

  // callback for submitting the edit form

  const handleSubmitEdit = async (data) => {
    if (history) {
      const response = await updateHistory({
        token: authData?.access_token,
        ...data,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        console.log(response);
      } else {
        toast.success(t("History record updated!"));
        setRefreshTable(true);
      }
    } else {
      const response = await createHistory({
        token: authData?.access_token,
        ...data,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        console.log(response);
      } else {
        toast.success(t("History record created!"));
        setRefreshTable(true);
      }
    }
  };

  // callbacks for the confirm modals

  const handleConfirmDelete = async () => {
    try {
      setShowConfirmDeleteModal(false);

      const response = await deleteHistory({
        token: authData.access_token,
        id: history.id,
      });

      if (response.status === 200 || response.status === 204) {
        setHistory(null);
        setRefreshTable(true);
        toast.success(t("History record deleted!"));
      }
    } catch (error) {
      console.error("Error deleting history record:", error);
      showError(error.response.data.detail);
    }
  };

  const handleConfirmBulkDelete = async () => {
    try {
      setShowConfirmBulkDeleteModal(false);

      console.log(
        "deleting",
        selectedRows.map((row) => row.id),
      );

      const response = await deleteHistoryBulk({
        token: authData.access_token,
        ids: selectedRows.map((row) => row.id),
      });

      if (response?.status === 200 || response?.status === 204) {
        setHistory(null);
        setSelectedRows([]);
        setRefreshTable(true);
        toast.success(t("{{count}} history records deleted!"));
      }
    } catch (error) {
      console.error("Error deleting history records:", error);
      showError(error.response.data.detail);
    }
  };

  // table - row actions

  const actions = useMemo(
    () => [
      {
        name: "edit",
        icon: "ri-pencil-line",
        command: handleRowEdit,
        title: t("Edit"),
      },
      {
        name: "delete",
        icon: "ri-delete-bin-line",
        command: handleRowDelete,
        title: t("Delete"),
      },
    ],
    [t],
  );

  // table - bulk actions. appear under the table when at least one row is selected

  const bulkActions = useMemo(
    () => [
      {
        name: "bulkDelete",
        command: handleBulkDelete,
        title: t("Delete selected"),
      },
    ],
    [t],
  );

  // table - columns

  const columns = useMemo(
    () =>
      [
        {
          // the checkboxes in the header and in each row for multi-select
          Header: (
            <Checkbox
              checked={selectedRows.length === histories.length}
              onChange={handleRowSelectAll}
            />
          ),
          accessor: "select",
          cssClass: "min-cell-width text-center",
          disableSortBy: true,
          Cell: ({ row: { original } }) => (
            <Checkbox
              checked={selectedRows.some((r) => r.id === original.id)}
              onChange={() => handleRowSelect(original)}
            />
          ),
        },
        {
          Header: t("Year"),
          accessor: "year",
          cssClass: "min-cell-width",
        },
        {
          Header: t("Fish type"),
          accessor: "fish_type_name",
          cssClass: "min-cell-width",
        },
        {
          Header: t("Fish age"),
          accessor: "fish_age_name",
          cssClass: "min-cell-width",
        },
        {
          Header: t("Count"),
          accessor: "count",
          cssClass: "min-cell-width text-right",
          Cell: ({ value, row }) => value?.toLocaleString(i18n.language),
        },
        {
          Header: t("Weight"),
          accessor: "weight",
          cssClass:
            "min-cell-width text-right max-w-[100px] overflow-hidden text-ellipsis",
          Cell: ({ value, row }) => value?.toLocaleString(i18n.language),
        },
        {
          Header: t("Fish origin"),
          accessor: "fish_origin_name",
          cssClass: "min-cell-width",
        },
        {
          Header: t("Location"),
          accessor: "city_name",
        },
        {
          Header: t("Source"),
          accessor: "source",
        },
        actions.length > 0 && {
          Header: t("Actions"),
          accessor: "accessor",
          cssClass: "min-cell-width text-center",
          disableSortBy: true,
          Cell: ({ row: { original } }) => (
            <Actions row={original} actions={actions} />
          ),
        },
      ].filter((item) => item !== false),
    [
      actions,
      t,
      i18n,
      selectedRows,
      histories,
      handleRowSelect,
      handleRowSelectAll,
    ],
  );

  // get all history items

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const data = await getHistoriesPage({
          pageNumber,
          pageSize,
          sortBy,
          sortOrder,
        });

        setHistories(data.data);
        setPageCount(data.pagination.totalPages);
        setItemsCount(data.pagination.totalItems);
      } catch (error) {
        console.error("Error getting history records:", error);
        showError(error.response.data.detail);
      } finally {
        setIsLoading(false);
        setRefreshTable(true);
      }
    })();
  }, [
    refreshTable,
    t,
    pageNumber,
    pageSize,
    sortBy,
    sortOrder,
    itemsCount,
    showError,
  ]);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay absolute z-50 h-full w-full flex justify-center items-center">
          <Spinner />
        </div>
      )}
      <div className="histories-container relative flex-grow h-full px-16 overflow-auto">
        <div className="content-wrapper max-h-full flex flex-col">
          <h1 className="flex items-center">
            {t("History")}
            <span className="ml-4 text-sm text-secondary">
              <NavLink to="/dashboard">{t("Back to Dashboard")}</NavLink>
            </span>
          </h1>
          <p className="max-w-prose">
            Sed turpis tincidunt id aliquet. Feugiat sed lectus vestibulum
            mattis ullamcorper velit sed ullamcorper morbi. Facilisis mauris sit
            amet massa vitae tortor condimentum lacinia quis. Elementum
            curabitur vitae nunc sed velit dignissim.
          </p>
          <Table
            className="max-h-full"
            columns={columns}
            data={histories}
            newAction={() => {
              setHistory(null);
              setShowEditModal(true);
            }}
            newActionLabel={t("Create new history record")}
            searchLabel={t("Search entire history")}
            manualSortBy={true}
            isLoading={isLoading}
            onRowClick={(row) => handleRowEdit(row.original)}
            onSortingChange={(sortBy) => handleSortingChange(sortBy)}
          />
          <BulkActions data={selectedRows} actions={bulkActions} />
          <Pagination
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageCount={pageCount}
            pageSize={pageSize}
            setPageSize={setPageSize}
            itemsCount={itemsCount}
          />
        </div>
      </div>

      {showEditModal && (
        <EditHistoryModal
          data={history}
          onClose={() => setShowEditModal(false)}
          onSubmit={(data) => handleSubmitEdit(data)}
        />
      )}

      {showConfirmDeleteModal && (
        <ConfirmModal
          title={t("Delete history record?")}
          message=<span>
            {t("Are you sure you want to delete the selected history record?")}
          </span>
          yesMessage={t("Delete")}
          destructive={true}
          onClose={() => {
            setHistory(null);
            setShowConfirmDeleteModal(false);
          }}
          onSubmit={handleConfirmDelete}
        />
      )}

      {showConfirmBulkDeleteModal && (
        <ConfirmModal
          title={t("Delete history records?")}
          message=<div>
            <p>
              {t(
                "Are you sure you want to bulk delete {{count}} selected history records?",
                { count: selectedRows.length },
              )}
            </p>
            <table className="block pl-8 pt-4 text-sm">
              <thead>
                <tr>
                  <td className="pr-4"></td>
                  <td className="pr-4">{t("Year")}</td>
                  <td className="pr-4">{t("Fish type")}</td>
                  <td className="pr-4">{t("Source")}</td>
                </tr>
              </thead>
              <tbody className="font-light align-top">
                {selectedRows.slice(0, 5).map((row, index) => (
                  <tr key={index}>
                    <td className="pr-4">{index + 1}.</td>
                    <td className="pr-4">{row.year}</td>
                    <td className="pr-4">{row.fish_type_name || "-"}</td>
                    <td>{row.source || "-"}</td>
                  </tr>
                ))}
                {selectedRows.length > 5 && (
                  <>
                    <tr className="font-light">
                      <td>...</td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        {t(
                          "Another {{count}} row(s) selected but not displayed.",
                          {
                            count: selectedRows.length - 5,
                          },
                        )}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
          yesMessage={t("Delete")}
          destructive={true}
          onClose={() => {
            setHistory(null);
            setShowConfirmBulkDeleteModal(false);
          }}
          onSubmit={handleConfirmBulkDelete}
        />
      )}
    </>
  );
};

export default History;
