import { useMemo, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useAuth } from "context/AuthContext";
import { useError } from "context/ErrorContext";

import { getStates, createState, updateState, deleteState } from "api/states";

import { Table, Actions } from "components/Table/Table";
import EditStateModal from "components/EditStateModal";
import ConfirmModal from "components/ConfirmModal";

/**
 * BUNDESLÄNDER
 *
 * /states
 *
 */

const States = () => {
  const { authData } = useAuth();
  const { showError } = useError();
  const { t } = useTranslation();

  const [states, setStates] = useState([]);
  const [state, setState] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);

  const handleRowEdit = (row) => {
    setState(row);
    setShowEditModal(true);
  };

  function handleRowDelete(row) {
    setState(row);
    setShowConfirmDeleteModal(true);
  }

  const handleSubmitEdit = async (data) => {
    if (state) {
      const response = await updateState({
        token: authData?.access_token,
        ...data,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        console.log(response);
      } else {
        toast.success(t("State updated!"));
        setRefreshTable(true);
      }
    } else {
      const response = await createState({
        token: authData?.access_token,
        ...data,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        console.log(response);
      } else {
        toast.success(t("State created!"));
        setRefreshTable(true);
      }
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setShowConfirmDeleteModal(false);

      const response = await deleteState({
        token: authData.access_token,
        id: state.id,
      });

      if (response.status === 200 || response.status === 204) {
        setState(null);
        setRefreshTable(true);
        toast.success(t("State deleted!"));
      }
    } catch (error) {
      console.error("Error deleting state:", error);
      showError(error.response.data.detail);
    }
  };

  // table - row actions

  const actions = useMemo(
    () => [
      {
        name: "edit",
        icon: "ri-pencil-line",
        command: handleRowEdit,
        title: t("Edit"),
      },
      {
        name: "delete",
        icon: "ri-delete-bin-line",
        command: handleRowDelete,
        title: t("Delete"),
      },
    ],
    [t],
  );

  // table - columns

  const columns = useMemo(
    () =>
      [
        /* {
          Header: t("ID"),
          accessor: "id",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) => addLeadingZeros(value, 5),
        }, */
        {
          Header: t("Name"),
          accessor: "name",
          cssClass: "min-cell-width",
        },
        {
          Header: t("Country"),
          accessor: "country_name",
          cssClass: "text-center",
        },
        actions.length > 0 && {
          Header: t("Actions"),
          accessor: "accessor",
          cssClass: "text-center min-cell-width",
          disableSortBy: true,
          Cell: ({ row: { original } }) => (
            <Actions row={original} actions={actions} />
          ),
        },
      ].filter((item) => item !== false),
    [actions, t],
  );

  // get all state (counties)

  useEffect(() => {
    (async () => {
      const data = await getStates();
      if (data.code === "ERR_BAD_REQUEST") {
        toast.error(t(data.response.data.detail));
        console.log(data);
      } else {
        setStates(data);
        setRefreshTable(false);
      }
    })();
  }, [refreshTable, t]);

  return (
    <>
      <div className="states-container relative flex-grow h-full px-16 overflow-auto">
        <div className="content-wrapper max-h-full flex flex-col">
          <h1 className="flex items-center">
            {t("States")}
            <span className="ml-4 text-sm text-secondary">
              <NavLink to="/dashboard">{t("Back to Dashboard")}</NavLink>
            </span>
          </h1>
          <p className="max-w-prose">
            Sed turpis tincidunt id aliquet. Feugiat sed lectus vestibulum
            mattis ullamcorper velit sed ullamcorper morbi. Facilisis mauris sit
            amet massa vitae tortor condimentum lacinia quis. Elementum
            curabitur vitae nunc sed velit dignissim.
          </p>
          <Table
            className="max-h-full"
            columns={columns}
            data={states}
            newAction={() => {
              setState(null);
              setShowEditModal(true);
            }}
            newActionLabel={t("Create new state")}
            searchLabel={t("Search by state or country")}
            searchColumns={["name", "country_name"]}
            onRowClick={(row) => handleRowEdit(row.original)}
          />
        </div>
      </div>

      {showEditModal && (
        <EditStateModal
          data={state}
          onClose={() => setShowEditModal(false)}
          onSubmit={(data) => handleSubmitEdit(data)}
        />
      )}

      {showConfirmDeleteModal && (
        <ConfirmModal
          title={t("Delete state?")}
          message=<span>
            {t("Are you sure you want to delete the state {{name}}?", {
              name: `${state.name}`,
            })}
          </span>
          yesMessage={t("Delete")}
          destructive={true}
          onClose={() => {
            setState(null);
            setShowConfirmDeleteModal(false);
          }}
          onSubmit={handleConfirmDelete}
        />
      )}
    </>
  );
};

export default States;
