import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 5000,
});

axiosInstance.createCancelToken = () => {
  return axios.CancelToken.source();
};

axiosInstance.isCancel = (error) => {
  return axios.isCancel(error); // Delegate to axios's isCancel function
};

axiosInstance.interceptors.request.use(
  (config) => {
    // add headers or perform other actions before making a request
    return config;
  },
  (error) => {
    // handle request errors here (e.g., network issues)
    return Promise.reject(error);
  },
);

// Axios response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axiosInstance;
