import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./Dashboard.scss";

const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <div className="dashboard-container relative flex-grow h-full px-16 overflow-scroll">
      <div className="content-wrapper max-w-[800px]">
        <h1>Dashboard</h1>
        <p>
          Donec adipiscing tristique risus nec feugiat in fermentum. Est
          ultricies integer quis auctor elit sed vulputate mi. At augue eget
          arcu dictum. Non nisi est sit amet facilisis magna etiam tempor orci.
        </p>
        <div className="card-wrapper mt-8 grid gap-12 grid-cols-3">
          <div className="card">
            <h2>{t("Data")}</h2>
            <nav>
              <ul>
                <li>
                  <NavLink to="/fish-releases">
                    {t("Stocking measures")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/fish-returners">{t("Returners")}</NavLink>
                </li>
                <li>
                  <NavLink to="/history">{t("History")}</NavLink>
                </li>
              </ul>
            </nav>
          </div>
          <div className="card">
            <h2>{t("Fishes")}</h2>
            <nav>
              <ul>
                <li>
                  <NavLink to="/fish-types">{t("Fish types")}</NavLink>
                </li>
                <li>
                  <NavLink to="/markings">{t("Markings")}</NavLink>
                </li>
                <li>
                  <NavLink to="/origins">{t("Fish origins")}</NavLink>
                </li>
              </ul>
            </nav>
          </div>
          <div className="card">
            <h2>{t("Import")}</h2>
            <nav>
              <ul>
                <li>
                  <NavLink to="/import">
                    {t("Stocking measures") + " (in work)"}
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
          <div className="card">
            <h2>{t("Water bodies")}</h2>
            <nav>
              <ul>
                <li>
                  <NavLink to="/areas">{t("Areas")}</NavLink>
                </li>
                <li>
                  <NavLink to="/regions">{t("Regions")}</NavLink>
                </li>
                <li>
                  <NavLink to="/waters">{t("Water bodies")}</NavLink>
                </li>
                <li>
                  <NavLink to="/locations">{t("Locations")}</NavLink>
                </li>
              </ul>
            </nav>
          </div>
          <div className="card">
            <h2>{t("Countries")}</h2>
            <nav>
              <ul>
                <li>
                  <NavLink to="/countries">{t("Countries")}</NavLink>
                </li>
                <li>
                  <NavLink to="/states">{t("States")}</NavLink>
                </li>
              </ul>
            </nav>
          </div>
          <div className="card">
            <h2>{t("Organizations")}</h2>
            <nav>
              <ul>
                <li>
                  <NavLink to="/organizations">{t("Organizations")}</NavLink>
                </li>
                <li>
                  <NavLink to="/profiles">{t("Profiles")}</NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
