import { Checkbox } from "components/Checkbox/Checkbox";

export const Filters = ({ options, value, onChange }) => {
  const handleCheckboxChange = (index, isChecked) => {
    const updatedSelectedOptions = new Set(value);

    if (isChecked) {
      updatedSelectedOptions.add(options[index].id);
    } else {
      updatedSelectedOptions.delete(options[index].id);
    }

    if (onChange) onChange(updatedSelectedOptions);
  };

  if (options.length === 0) return;

  return (
    <div className="filters-window min-h-[36px] flex gap-4 shrink-0">
      {Array.from(options).map((filter, index) => (
        <Checkbox
          key={index}
          className="px-4 rounded-full bg-white text-neutral-600 text-sm font-semibold whitespace-nowrap shadow hover:text-black hover:bg-neutral-100"
          checked={value.has(filter.id)}
          onChange={(event) =>
            handleCheckboxChange(index, event.target.checked)
          }
          label={
            <>
              {filter.icon && <i className={`${filter.icon} mr-1`} />}
              {filter.label}
            </>
          }
          disabled={filter.disabled}
        />
      ))}
    </div>
  );
};
