import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useError } from "context/ErrorContext";
import { useConsent, NoConsentMessage } from "context/ConsentContext";

import { useAxios } from "hooks/useAxios";

import { GoogleMaps } from "components/MapViewer/GoogleMaps";
import { Filters } from "components/MapViewer/Filters";
import { Details } from "components/MapViewer/Details";

import { getOrganization } from "api/organizations";
import { getStockingHistory } from "api/fishReleases";
import { getReturnersHistory } from "api/fishReturners";
import { getLocationHistory } from "api/history";

import "./Map.scss";

const Map = () => {
  const { t } = useTranslation();
  const { showError } = useError();
  const { consentData, setShowConsentModal } = useConsent();

  const [organizations, setOrganizations] = useState([]);
  const [stockingLocations, setStockingLocations] = useState([]);
  const [returnersLocations, setReturnersLocations] = useState([]);
  const [historyLocations, setHistoryLocations] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(new Set());
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedMarkerDetails, setSelectedMarkerDetails] = useState(null);
  const [showDetailsWindow, setShowDetailsWindow] = useState(false);
  const [zoom, setZoom] = useState(6.5);

  // center initially on Germany
  const [center, setCenter] = useState({
    lat: 51.1657,
    lng: 10.4515,
  });
  const germanyBounds = {
    north: 55.099161,
    south: 47.270111,
    west: 5.866315,
    east: 15.041931,
  };

  // mapper functions
  const organizationMapper = ({ id, name, gps_lat, gps_long }) => ({
    id,
    title: name,
    lat: Number(gps_lat),
    lng: Number(gps_long),
    type: "organization",
    typeSlug: "Organization",
  });

  const stockingMapper = ({ id, name, gps_lat, gps_lng }) => ({
    id,
    title: name,
    lat: Number(gps_lat),
    lng: Number(gps_lng),
    type: "stocking",
    typeSlug: "Stocking measures",
  });

  const returnerMapper = ({ id, name, gps_lat, gps_lng }) => ({
    id,
    title: name,
    lat: Number(gps_lat),
    lng: Number(gps_lng),
    type: "returners",
    typeSlug: "Returners",
  });

  const historyMapper = ({ id, name, gps_lat, gps_lng }) => ({
    id,
    title: name,
    lat: Number(gps_lat),
    lng: Number(gps_lng),
    type: "history",
    typeSlug: "Historical data",
  });

  // get all organizations

  const {
    data: dataOrga,
    error: errorOrga,
    isLoading: isLoadingOrga,
  } = useAxios("/organization");

  useEffect(() => {
    setOrganizations(dataOrga.map(organizationMapper));
    if (errorOrga) showError(t("Loading the organizations failed."), errorOrga);
  }, [dataOrga, errorOrga, showError, t]);

  // get all stocking locations

  const {
    data: dataStck,
    error: errorStck,
    isLoading: isLoadingStck,
  } = useAxios("/location/fish_release");

  useEffect(() => {
    setStockingLocations(dataStck.map(stockingMapper));
    if (errorStck) showError(t("Loading stocking measures failed."), errorStck);
  }, [dataStck, errorStck, showError, t]);

  // get all returners

  const {
    data: dataRtrn,
    error: errorRtrn,
    isLoading: isLoadingRtrn,
  } = useAxios("/location/fish_return");

  useEffect(() => {
    setReturnersLocations(dataRtrn.map(returnerMapper));
    if (errorRtrn) showError(t("Loading returner records failed."), errorRtrn);
  }, [dataRtrn, errorRtrn, showError, t]);

  // get all locations that have history items

  const {
    data: dataHist,
    error: errorHist,
    isLoading: isLoadingHist,
  } = useAxios("/location/history");

  useEffect(() => {
    setHistoryLocations(dataHist.map(historyMapper));
    if (errorHist) showError(t("Loading history data failed."), errorHist);
  }, [dataHist, errorHist, showError, t]);

  const filters = useMemo(
    () => [
      {
        id: "organizations",
        label: t("Organizations"),
        // icon: "ri-building-line",
        zIndex: 10,
        data: organizations,
        disabled: errorOrga,
      },
      {
        id: "besatz",
        label: t("Stocking measures"),
        // icon: "ri-add-line",
        zIndex: 20,
        data: stockingLocations,
        disabled: errorStck,
      },
      {
        id: "return",
        label: t("Returners"),
        // icon: "ri-arrow-go-back-line",
        zIndex: 30,
        data: returnersLocations,
        disabled: errorRtrn,
      },
      {
        id: "history",
        label: t("Historical data"),
        // icon: "ri-book-marked-line",
        zIndex: 40,
        data: historyLocations,
        disabled: errorHist,
      },
    ],
    [
      organizations,
      errorOrga,
      stockingLocations,
      errorStck,
      returnersLocations,
      errorRtrn,
      historyLocations,
      errorHist,
      t,
    ],
  );

  // update the markers array based on the selected filters

  useEffect(() => {
    const filteredData = filters
      .filter((filter) => selectedFilters.has(filter.id))
      .flatMap((filter) => filter.data);

    if (!filteredData.length) setShowDetailsWindow(false);

    setMarkers(filteredData);
  }, [filters, selectedFilters]);

  // reset the filters when revoking consent

  useEffect(() => {
    if (!consentData) setSelectedFilters(new Set());
  }, [consentData]);

  // get marker details on click

  useEffect(() => {
    if (!selectedMarker) {
      setSelectedMarkerDetails(null);
      return;
    }

    (async () => {
      let data = null;
      switch (selectedMarker.type) {
        case "organization":
          data = await getOrganization({ id: selectedMarker?.id });
          break;
        case "stocking":
          data = await getStockingHistory({ id: selectedMarker?.id });
          break;
        case "returners":
          data = await getReturnersHistory({ id: selectedMarker?.id });
          break;
        case "history":
          data = await getLocationHistory({ id: selectedMarker?.id });
          break;
        default:
      }

      if (data.code) {
        // toast.error(t(data.response.statusText));
        console.log(data.response);
      } else {
        setSelectedMarkerDetails({
          data: data,
          type: selectedMarker.type,
          typeSlug: selectedMarker.typeSlug,
          title: selectedMarker.title,
        });
      }
    })();
  }, [selectedMarker, t]);

  const handleMapLoad = (map) => {
    setMap(map);
    map.fitBounds(germanyBounds);
  };

  const handleMarkerClick = (marker) => {
    map.panTo({ lat: marker.lat, lng: marker.lng });
    setZoom(7);
    setCenter(null);
    setSelectedMarker(marker);
    setShowDetailsWindow(true);
  };

  if (!consentData)
    return <NoConsentMessage onClick={() => setShowConsentModal(true)} />;

  return (
    <div className="map-container relative w-full">
      <div className="absolute right-0 p-2 text-right text-sm text-gray-400">
        {isLoadingOrga && <div>{t("Loading organizations...")}</div>}
        {isLoadingStck && <div>{t("Loading stocking measures...")}</div>}
        {isLoadingRtrn && <div>{t("Loading returner records...")}</div>}
        {isLoadingHist && <div>{t("Loading history data...")}</div>}
      </div>
      <div className="map-wrapper h-full flex justify-center items-center">
        <GoogleMaps
          center={center}
          zoom={zoom}
          markers={markers}
          markerOnClick={handleMarkerClick}
          onLoad={handleMapLoad}
        />
      </div>
      <div className="filters-wrapper absolute w-[calc(100%-10px-40px-10px-170px)] top-[10px] right-[calc(10px+40px)] px-6">
        <Filters
          options={filters}
          value={selectedFilters}
          onChange={(newFilters) => setSelectedFilters(newFilters)}
        />
      </div>
      {selectedMarkerDetails && showDetailsWindow && (
        <Details
          data={selectedMarkerDetails}
          onClose={() => {
            setSelectedMarker(null);
            setShowDetailsWindow(false);
          }}
        />
      )}
    </div>
  );
};

export default Map;
