import { useMemo, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useAuth } from "context/AuthContext";
import { useError } from "context/ErrorContext";

import {
  getFishReleasesPage,
  createFishRelease,
  updateFishRelease,
  deleteFishRelease,
} from "api/fishReleases";

import { Table, Actions, Pagination } from "components/Table/Table";
import EditFishReleaseModal from "components/EditFishReleaseModal";
import ConfirmModal from "components/ConfirmModal";

import { getDate } from "utils/utils";

/**
 * FISCHARTEN
 *
 * /fish-types
 *
 */

const FishReleases = () => {
  const { authData } = useAuth();
  const { showError } = useError();
  const { t, i18n } = useTranslation();

  const [fishReleases, setFishReleases] = useState([]);
  const [fishRelease, setFishRelease] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [itemsCount, setItemsCount] = useState();

  const handleRowEdit = (data) => {
    setFishRelease(data);
    setShowEditModal(true);
  };

  const handleRowDelete = (data) => {
    setFishRelease(data);
    setShowConfirmDeleteModal(true);
  };

  const handleSubmitEdit = async (data) => {
    if (fishRelease) {
      const response = await updateFishRelease({
        token: authData?.access_token,
        ...data,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        console.log(response);
      } else {
        toast.success(t("Stocking measure updated!"));
        setRefreshTable(true);
      }
    } else {
      const response = await createFishRelease({
        token: authData?.access_token,
        ...data,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        console.log(response);
      } else {
        toast.success(t("Stocking measure created!"));
        setRefreshTable(true);
      }
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setShowConfirmDeleteModal(false);

      const response = await deleteFishRelease({
        token: authData.access_token,
        id: fishRelease.id,
      });

      if (response.status === 200 || response.status === 204) {
        setFishRelease(null);
        setRefreshTable(true);
        toast.success(t("Stocking measure deleted!"));
      }
    } catch (error) {
      console.error("Error deleting stocking measure:", error);
      showError(error.response.data.detail);
    }
  };

  // table - row actions

  const actions = useMemo(
    () => [
      {
        name: "edit",
        icon: "ri-pencil-line",
        command: handleRowEdit,
        title: t("Edit"),
      },
      {
        name: "delete",
        icon: "ri-delete-bin-line",
        command: handleRowDelete,
        title: t("Delete"),
      },
    ],
    [t],
  );

  // table - columns

  const columns = useMemo(
    () =>
      [
        {
          Header: t("Year"),
          accessor: "year",
          cssClass: "min-cell-width",
        },
        {
          Header: t("Fish type"),
          accessor: "fish_type_name",
          cssClass: "min-cell-width",
        },
        {
          Header: t("Fish age"),
          accessor: "fish_age_name",
          cssClass: "min-cell-width",
        },
        {
          Header: t("Count"),
          accessor: "count",
          cssClass: "min-cell-width text-right",
          Cell: ({ value, row }) => value?.toLocaleString(i18n.language),
        },
        {
          Header: t("Fish origin"),
          accessor: "fish_origin_name",
          cssClass: "min-cell-width",
        },
        {
          Header: t("Water body"),
          //accessor: "fish_origin_name",
        },
        {
          Header: t("Area"),
          //accessor: "fish_origin_name",
        },
        {
          Header: t("Location"),
          accessor: "city_name",
        },
        {
          Header: t("Created by"),
          accessor: "create_user_name",
          cssClass: "min-cell-width whitespace-nowrap",
        },
        {
          Header: t("Created on"),
          accessor: "create_date",
          cssClass: "text-center whitespace-nowrap",
          Cell: ({ cell: { value } }) => getDate(value),
        },
        {
          Header: t("Updated on"),
          accessor: "modify_date",
          cssClass: "w-min text-center whitespace-nowrap",
          Cell: ({ cell: { value } }) => getDate(value),
        },
        actions.length > 0 && {
          Header: t("Actions"),
          accessor: "accessor",
          cssClass: "text-center min-cell-width",
          disableSortBy: true,
          Cell: ({ row: { original } }) => (
            <Actions row={original} actions={actions} />
          ),
        },
      ].filter((item) => item !== false),
    [actions, t, i18n],
  );

  // get all fishReleases with pagination

  useEffect(() => {
    (async () => {
      const data = await getFishReleasesPage({
        pageNumber: pageNumber,
        pageSize: pageSize,
      });
      if (data.code === "ERR_BAD_REQUEST") {
        toast.error(t(data.response.data.detail));
        console.log(data);
      } else {
        setFishReleases(data.data);
        setPageCount(data.pagination.totalPages);
        setItemsCount(data.pagination.totalItems);
        setRefreshTable(false);
      }
    })();
  }, [refreshTable, t, pageNumber, pageSize, itemsCount]);

  return (
    <>
      <div className="fish-releases-container relative flex-grow h-full px-16 overflow-auto">
        <div className="content-wrapper max-h-full flex flex-col">
          <h1 className="flex items-center">
            {t("Stocking measures")}
            <span className="ml-4 text-sm text-secondary">
              <NavLink to="/dashboard">{t("Back to Dashboard")}</NavLink>
            </span>
          </h1>
          <p className="max-w-prose">
            Sed turpis tincidunt id aliquet. Feugiat sed lectus vestibulum
            mattis ullamcorper velit sed ullamcorper morbi. Facilisis mauris sit
            amet massa vitae tortor condimentum lacinia quis. Elementum
            curabitur vitae nunc sed velit dignissim.
          </p>
          <Table
            className="max-h-full"
            columns={columns}
            data={fishReleases}
            newAction={() => {
              setFishRelease(null);
              setShowEditModal(true);
            }}
            newActionLabel={t("Create new stocking measure record")}
            searchLabel={t("Search entire stocking measures data")}
            manualSortBy={true}
            onRowClick={(row) => handleRowEdit(row.original)}
            onSortingChange={(sort) => console.log(sort)}
          />
          <Pagination
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageCount={pageCount}
            pageSize={pageSize}
            setPageSize={setPageSize}
            itemsCount={itemsCount}
          />
        </div>
      </div>

      {showEditModal && (
        <EditFishReleaseModal
          data={fishRelease}
          onClose={() => setShowEditModal(false)}
          onSubmit={(data) => handleSubmitEdit(data)}
        />
      )}

      {showConfirmDeleteModal && (
        <ConfirmModal
          title={t("Delete stocking measure?")}
          message=<span>
            {t(
              "Are you sure you want to delete the selected stocking measure?",
            )}
          </span>
          yesMessage={t("Delete")}
          destructive={true}
          onClose={() => {
            setFishRelease(null);
            setShowConfirmDeleteModal(false);
          }}
          onSubmit={handleConfirmDelete}
        />
      )}
    </>
  );
};

export default FishReleases;
