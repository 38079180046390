import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import Modal from "components/Modal/Modal";
import Input from "components/Input/Input";
import SortableList from "components/SortableList/SortableList";
import Button from "components/Button/Button";

const EditFishTypeModal = ({ data, onClose, onSubmit }) => {
	const { t } = useTranslation();
	const [fishType, setFishType] = useState(
		_.cloneDeep(data) || { id: "", name: "", stadiums: [] },
	);

	const title = fishType.id ? t("Edit fish type") : t("Create new fish type");

	const description =
		(fishType.id
			? t("You can change the name or the available stadiums of the fish type.")
			: t("Fill in the name and stadiums of the fish type.")) +
		t(
			"\nTo make the changes permanent, click the Save button. To discard the changes, close the window.",
		);

	// table - columns

	const columns = useMemo(
		() =>
			[
				/*{
					Header: t("ID"),
					accessor: "fish_age_id",
					cssClass: "text-center min-cell-width",
				},*/
				{
					Header: t("Fish age"),
					accessor: "fish_age_name",
					cssClass: "editable",
					allowEdit: true,
				},
				/*{
					Header: t("Pos"),
					accessor: "pos",
					cssClass: "w-24",
				},*/
				{
					accessor: "changed",
					cssClass: "text-center w-8",
					Cell: ({ value, row }) => (value ? "*" : ""),
				},
			].filter((item) => item !== false),
		[t],
	);

	const validate = (data) => {
		console.log("validating", data);
		// if the stadium is a newly added one, remove the undefined fish_age_id
		// and set pos to 0
		// TODO: move this considtion in the backend
		data.stadiums.forEach((obj, index) => {
			if (obj.fish_age_id === undefined) delete obj.fish_age_id;
			if (obj.pos === undefined) obj.pos = 0;
			else obj.pos = index;
		});
		setFishType(data);
	};

	const handleClose = () => {
		console.log("closing & resetting changes", data);
		setFishType(data);
		if (typeof onClose === "function") onClose();
	};

	const handleSubmit = () => {
		validate(fishType);
		console.log("submitting", data);
		if (typeof onSubmit === "function") onSubmit(fishType);
		setFishType(null);
		onClose();
	};

	return (
		<Modal
			className="max-w-lg max-h-[min(640px,90%)]"
			title={title}
			onClose={handleClose}
			onSubmit={onSubmit}
		>
			<form
				className="p-[1px] flex flex-col gap-8 w-full overflow-hidden"
				onSubmit={handleSubmit}
			>
				<p className="m-0 text-sm whitespace-pre-line">{description}</p>
				<Input
					id="fish-type-name"
					className="w-[calc(100%-46px)]"
					label={t("Fish type")}
					type="text"
					name="fish-type-name"
					value={fishType.name}
					onChange={(e) => setFishType({ ...fishType, name: e.target.value })}
					autoFocus
					required
				/>
				<SortableList
					label={t("Stages")}
					data={fishType.stadiums}
					columns={columns}
					onChange={(stadiums) =>
						setFishType({ ...fishType, stadiums: stadiums })
					}
				/>
				<div className="mt-4 flex gap-4 justify-center">
					<Button className="btn-primary" onClick={handleSubmit}>
						{t("Save")}
					</Button>
					<Button className="btn" onClick={handleClose}>
						{t("Cancel")}
					</Button>
				</div>
			</form>
		</Modal>
	);
};

export default EditFishTypeModal;
