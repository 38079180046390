import { useTranslation } from "react-i18next";

import { getDate } from "utils/utils";

const ContentMeta = ({ data }) => {
	const { t } = useTranslation();

	return (
		<>
			{data.id && data.create_user_name && data.create_date && (
				<ul className="ml-3 mb-4 border border-transparent text-sm font-light leading-relaxed">
					<li>
						{t("Created by {{name}} on {{date}}", {
							name: data.create_user_name,
							date: getDate(data.create_date),
						})}
					</li>
					{data.modify_user_name && (
						<li>
							{t("Last updated by {{name}} on {{date}}", {
								name: data.modify_user_name,
								date: getDate(data.modify_date),
							})}
						</li>
					)}
				</ul>
			)}
		</>
	);
};

export default ContentMeta;
