import { useMemo, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useAuth } from "context/AuthContext";
import { useError } from "context/ErrorContext";

import { getWaters, createWater, updateWater, deleteWater } from "api/waters";

import { Table, Actions } from "components/Table/Table";
import EditWaterModal from "components/EditWaterModal";
import ConfirmModal from "components/ConfirmModal";

/**
 * GEWÄSSER (water bodies)
 *
 * /waters
 *
 */

const Waters = () => {
  const { authData } = useAuth();
  const { showError } = useError();
  const { t } = useTranslation();

  const [waters, setWaters] = useState([]);
  const [water, setWater] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);

  const handleRowEdit = (data) => {
    setWater(data);
    setShowEditModal(true);
  };

  const handleRowDelete = (data) => {
    setWater(data);
    setShowConfirmDeleteModal(true);
  };

  const handleSubmitEdit = async (data) => {
    if (water) {
      const response = await updateWater({
        token: authData?.access_token,
        ...data,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        console.log(response);
      } else {
        toast.success(t("Water body updated!"));
        setRefreshTable(true);
      }
    } else {
      const response = await createWater({
        token: authData?.access_token,
        ...data,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        console.log(response);
      } else {
        toast.success(t("Water body created!"));
        setRefreshTable(true);
      }
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setShowConfirmDeleteModal(false);

      const response = await deleteWater({
        token: authData.access_token,
        id: water.id,
      });

      if (response.status === 200 || response.status === 204) {
        setWater(null);
        setRefreshTable(true);
        toast.success(t("Water body deleted!"));
      }
    } catch (error) {
      console.error("Error deleting water body:", error);
      showError(error.response.data.detail);
    }
  };

  // table - row actions

  const actions = useMemo(
    () => [
      {
        name: "edit",
        icon: "ri-pencil-line",
        command: handleRowEdit,
        title: t("Edit"),
      },
      {
        name: "delete",
        icon: "ri-delete-bin-line",
        command: handleRowDelete,
        title: t("Delete"),
      },
    ],
    [t],
  );

  // table - columns

  const columns = useMemo(
    () =>
      [
        /* {
          Header: t("ID"),
          accessor: "id",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) => addLeadingZeros(value, 5),
        }, */
        {
          Header: t("Water body"),
          accessor: "name",
        },
        {
          Header: t("Area"),
          accessor: "area_name",
        },
        {
          Header: t("GPS Lat"),
          accessor: "gps_lat",
          disableSortBy: true,
        },
        {
          Header: t("GPS Long"),
          accessor: "gps_lng",
          disableSortBy: true,
        },
        actions.length > 0 && {
          Header: t("Actions"),
          accessor: "accessor",
          cssClass: "text-center min-cell-width",
          disableSortBy: true,
          Cell: ({ row: { original } }) => (
            <Actions row={original} actions={actions} />
          ),
        },
      ].filter((item) => item !== false),
    [actions, t],
  );

  // get all waters

  useEffect(() => {
    (async () => {
      const data = await getWaters();
      if (data.code === "ERR_BAD_REQUEST") {
        toast.error(t(data.response.data.detail));
        console.log(data);
      } else {
        setWaters(data);
        setRefreshTable(false);
      }
    })();
  }, [refreshTable, t]);

  return (
    <>
      <div className="waters-container relative flex-grow h-full px-16 overflow-auto">
        <div className="content-wrapper max-h-full flex flex-col">
          <h1 className="flex items-center">
            {t("Water bodies")}
            <span className="ml-4 text-sm text-secondary">
              <NavLink to="/dashboard">{t("Back to Dashboard")}</NavLink>
            </span>
          </h1>
          <p className="max-w-prose">
            Sed turpis tincidunt id aliquet. Feugiat sed lectus vestibulum
            mattis ullamcorper velit sed ullamcorper morbi. Facilisis mauris sit
            amet massa vitae tortor condimentum lacinia quis. Elementum
            curabitur vitae nunc sed velit dignissim.
          </p>
          <Table
            className="max-h-full"
            columns={columns}
            data={waters}
            newAction={() => {
              setWater(null);
              setShowEditModal(true);
            }}
            newActionLabel={t("Create new water body")}
            searchLabel={t("Search by water body or area")}
            searchColumns={["name", "area_name"]}
            onRowClick={(row) => handleRowEdit(row.original)}
          />
        </div>
      </div>

      {showEditModal && (
        <EditWaterModal
          data={water}
          onClose={() => setShowEditModal(false)}
          onSubmit={(data) => handleSubmitEdit(data)}
        />
      )}

      {showConfirmDeleteModal && (
        <ConfirmModal
          title={t("Delete water body?")}
          message=<span>
            {t("Are you sure you want to delete the water body {{name}}?", {
              name: `${water.name}`,
            })}
          </span>
          yesMessage={t("Delete")}
          destructive={true}
          onClose={() => {
            setWater(null);
            setShowConfirmDeleteModal(false);
          }}
          onSubmit={handleConfirmDelete}
        />
      )}
    </>
  );
};

export default Waters;
