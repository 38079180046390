import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { getCountries } from "api/countries";

import Modal from "components/Modal/Modal";
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import Button from "components/Button/Button";
import ErrorHint from "components/ErrorHint/ErrorHint";
import ContentMeta from "components/ContentMeta/ContentMeta";

const EditStateModal = ({ data, onClose, onSubmit }) => {
	const { t } = useTranslation();
	const [state, setState] = useState(
		data || { id: "", name: "", country_id: "", country_name: "" },
	);
	const [countries, setCountries] = useState([]);
	const [errors, setErrors] = useState({});

	const title = state.id ? t("Edit state") : t("Create new state");
	const description = state.id
		? t("You can change the name of the state or the country.")
		: t("Fill in the name of the state and select the country.");

	// get all countries

	useEffect(() => {
		(async () => {
			const data = await getCountries();

			if (data.code === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.data.detail));
				console.log(data);
			} else {
				const countries = data.map((obj) => ({
					value: obj.id,
					label: obj.name,
				}));
				setCountries(countries);
			}
		})();
	}, [state, t]);

	const handleChangeName = (e) => {
		setState({ ...state, name: e.target.value });
		if (errors.name) setErrors({ ...errors, name: "" });
	};

	const handleChangeCountry = (value) => {
		setState({
			...state,
			country_id: value.value,
			country_name: value.label,
		});
		if (errors.country_id) setErrors({ ...errors, country_id: "" });
	};

	const validateData = () => {
		const errors = {};

		if (!state.name.trim()) {
			errors.name = t("State name is required");
		}
		if (!state.country_id) {
			errors.country_id = t("Country is required");
		}

		return errors;
	};

	const handleSubmit = () => {
		const validationErrors = validateData();

		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
			return;
		}

		if (typeof onSubmit === "function") onSubmit(state);
		setState(null);
		onClose();
	};

	return (
		<Modal title={title} onClose={onClose} onSubmit={onSubmit}>
			<form className="flex flex-col gap-10 w-full" onSubmit={handleSubmit}>
				<p className="text-sm">{description}</p>
				<Input
					id="state-name"
					label={t("State name")}
					type="text"
					name="state-name"
					value={state.name}
					onChange={handleChangeName}
					autoFocus
					required
				/>
				<ErrorHint message={errors.name} />

				<Select
					label={t("Country")}
					options={countries}
					value={{ label: state.country_name, value: state.country_id }}
					allowNone={false}
					onChange={handleChangeCountry}
					required
				/>
				<ErrorHint message={errors.country_id} />

				<ContentMeta data={state} />

				<div className="flex gap-4 justify-center">
					<Button className="btn-primary" onClick={handleSubmit}>
						{t("Save")}
					</Button>
					<Button className="btn" onClick={onClose}>
						{t("Cancel")}
					</Button>
				</div>
			</form>
		</Modal>
	);
};

export default EditStateModal;
