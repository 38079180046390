import axiosInstance from "api/axiosInstance";

/**
 *  GET /marking
 *
 *  get all markings
 */
export const getMarkings = async () => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get("/marking", { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /marking/{id}
 *
 *  get an marking by id
 */
export const getMarking = async ({ id }) => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get(`/marking/${id}`, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  POST /marking
 *
 *  create an marking
 */
export const createMarking = async ({ token, name }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
	};

	try {
		const response = await axiosInstance.post(`/marking`, data, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  PUT /marking/{id}
 *
 *  update an marking
 */
export const updateMarking = async ({ token, id, name }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
	};

	try {
		const response = await axiosInstance.put(`/marking/${id}`, data, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  DELETE /marking/{id}
 *
 *  delete an marking
 */
export const deleteMarking = async ({ token, id }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.delete(`/marking/${id}`, { headers });
		return response;
	} catch (error) {
		throw error;
	}
};
