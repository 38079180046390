import React, { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const ErrorContext = createContext();

const ErrorProvider = ({ children }) => {
	const { t } = useTranslation();
	const [errors, setErrors] = useState([]);

	const showError = (message, errorCode = "") => {
		console.log(message, errorCode);
		setErrors((prevMessages) => {
			// check if the message already exists in the errorMessages array
			if (!prevMessages.includes(message)) {
				toast.error(
					<>
						{t(message)}
						{errorCode && (
							<div className="mt-4 mb-1 text-gray-500 text-xs">
								{t("Server response:")} {errorCode}
							</div>
						)}
					</>,
				);
				// add the new message to the beginning of the array
				return [message, ...prevMessages];
			}

			// return the previous array without changes
			return prevMessages;
		});
	};

	const clearErrors = () => {
		setErrors([]);
	};

	return (
		<ErrorContext.Provider value={{ errors, showError, clearErrors }}>
			{children}
		</ErrorContext.Provider>
	);
};

const useError = () => {
	const context = useContext(ErrorContext);

	if (!context) {
		throw new Error("useError must be used within an ErrorProvider");
	}
	return context;
};

export { ErrorProvider, useError };
