import axiosInstance from "api/axiosInstance";

/**
 *  GET /fish_return
 *
 *  get all returner records
 */
export const getReturners = async () => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get("/fish_return", { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /fish_return/page/{page_number}
 *
 *  get all returner records using pagination
 */
export const getReturnersPage = async ({ pageNumber, pageSize = 20 }) => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get(
			`/fish_return/page?page_number=${pageNumber}&page_size=${pageSize}`,
			{
				headers,
			},
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /fish_return/{id}
 *
 *  get a returner record by id
 */
export const getFishReturner = async ({ id }) => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get(`/fish_return/${id}`, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /fish_return/location/{id}
 *
 *  get all returners records for a specific location
 */
export const getReturnersHistory = async ({ id }) => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get(`/fish_return/location/${id}`, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  POST /fish_return
 *
 *  create a returner record
 */
export const createFishReturner = async ({
	token,
	year,
	fish_type_id,
	fish_age_id,
	fish_origin_id,
	count,
	city_id,
	comment,
	source,
	import_id,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		year: year,
		fish_type_id: fish_type_id,
		fish_age_id: fish_age_id,
		fish_origin_id: fish_origin_id,
		count: count,
		city_id: city_id,
		comment: comment,
		source: source,
		import_id: import_id,
	};

	try {
		const response = await axiosInstance.post(`/fish_return`, data, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  PUT /fish_return/{id}
 *
 *  update a returner record
 */
export const updateFishReturner = async ({
	id,
	token,
	year,
	fish_type_id,
	fish_age_id,
	fish_origin_id,
	count,
	city_id,
	comment,
	source,
	import_id,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		year: year,
		fish_type_id: fish_type_id,
		fish_age_id: fish_age_id,
		fish_origin_id: fish_origin_id,
		count: count,
		city_id: city_id,
		comment: comment,
		source: source,
		import_id: import_id,
	};

	try {
		const response = await axiosInstance.put(`/fish_return/${id}`, data, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  DELETE /fish_return/{id}
 *
 *  delete a returner record
 */
export const deleteFishReturner = async ({ token, id }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.delete(`/fish_return/${id}`, {
			headers,
		});
		return response;
	} catch (error) {
		throw error;
	}
};
