import { useState } from "react";
import { /*Link*,*/ useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useAuth } from "context/AuthContext";
import { getAuthToken } from "api/auth";
import { useError } from "context/ErrorContext";

import Input from "components/Input/Input";
import Password from "components/Input/Password";
import Button from "components/Button/Button";

const Login = () => {
  const { setAuthData } = useAuth();
  const { showError } = useError();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await getAuthToken({
        username,
        password,
      });

      setAuthData(response);
      setUsername("");
      setPassword("");
      toast.success(response);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error logging in:", error);
      showError(error.response.data.detail);
    }
  };

  return (
    <div className="login-container relative flex-grow h-full overflow-scroll flex justify-center items-center">
      <div className="content-wrapper mb-32 flex flex-col gap-4 items-center">
        <h1>{t("Log in")}</h1>
        <form className="card w-80 flex flex-col gap-8" onSubmit={handleSubmit}>
          <Input
            id="username"
            label="E-mail"
            // type="email"
            value={username}
            required
            onChange={(e) => setUsername(e.target.value)}
          />
          <div>
            <Password
              id="password"
              label={t("Password")}
              name="password"
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            {/*<div className="text-right">
              <Link to="/reset" className="text-sm">
                {t("Forgot password?")}
              </Link>
            </div>*/}
          </div>
          <Button className="btn-primary mt-4">{t("Log in")}</Button>
        </form>
        {/*<Link className="text-sm" to="/register">
          {t("I don't have an account yet")}
        </Link>*/}
      </div>
    </div>
  );
};

export default Login;
