import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useConsent } from "context/ConsentContext";

import Modal from "components/Modal/Modal";
import Button from "components/Button/Button";

const CookieConsentModal = ({ onClose }) => {
  const { setConsentData } = useConsent();
  const { t } = useTranslation();

  const handleConsent = (value) => {
    setConsentData(value);
    onClose();
  };

  return (
    <Modal
      title={t("This website uses cookies")}
      className="cookie-consent"
      onClose={onClose}
    >
      <p className="text-sm font-normal whitespace-pre-line">
        {t(
          "By allowing Google Maps to load, you consent to the use of cookies or similar technologies that may be necessary for its functioning.\nYour choice will be saved for future visits to ensure seamless access to this service. You can change your preferences at any time.",
        )}
      </p>
      <p className="text-sm font-normal text-gray-600">
        {t(
          "If you are under 16 years old, you cannot provide consent on your own. Please seek approval from your parent or legal guardian to agree to these terms together with you.",
        )}
      </p>
      <div className="mt-4 flex gap-4 justify-center items-center">
        <Button className="btn-primary" onClick={() => handleConsent(true)}>
          {t("Agree")}
        </Button>
        <Button onClick={() => handleConsent(false)}>{t("Decline")}</Button>
        <Link
          to={"/privacy"}
          className="inline-link ml-auto mr-4 underline text-sm"
          onClick={onClose}
          title={t("Privacy")}
        >
          {t("Learn more")}
        </Link>
      </div>
    </Modal>
  );
};

export default CookieConsentModal;
