import React, { createContext, useContext, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useTranslation, Trans } from "react-i18next";

const ConsentContext = createContext();

const ConsentProvider = ({ children }) => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const [consentData, setConsentData] = useState(null);
  const [showConsentModal, setShowConsentModal] = useState(false);

  // Load consent data from the cookie on mount
  useEffect(() => {
    const savedConsentData = cookies.cookieConsent;

    if (savedConsentData) {
      setConsentData(savedConsentData);
    }
  }, [cookies.cookieConsent]);

  // Save consent data as a cookie whenever it changes
  useEffect(() => {
    if (consentData !== null) {
      setCookie("cookieConsent", consentData, { sameSite: true });
    }
  }, [consentData, setCookie]);

  return (
    <ConsentContext.Provider
      value={{
        consentData,
        setConsentData,
        showConsentModal,
        setShowConsentModal,
        NoConsentMessage,
      }}
    >
      {children}
    </ConsentContext.Provider>
  );
};

const NoConsentMessage = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="map-container no-consent w-full h-full flex flex-col items-center justify-center">
      <div className="bg-white px-4 py-8 flex flex-col gap-4 items-center">
        <i className="ri-cake-3-line text-3xl text-primary" />
        <h3 className="text-center">
          {t("Google Maps blocked due to privacy settings.")}
          <br />
          <Trans i18nKey="cookiesLink">
            Change your preferences{" "}
            <button className="underline" onClick={onClick && onClick}>
              here
            </button>
            .
          </Trans>
        </h3>
      </div>
    </div>
  );
};

const useConsent = () => {
  const context = useContext(ConsentContext);

  if (!context) {
    throw new Error("useConsent must be used within an ConsentProvider");
  }
  return context;
};

export { ConsentProvider, useConsent, NoConsentMessage };
