import { useMemo, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useAuth } from "context/AuthContext";
import { useError } from "context/ErrorContext";

import {
  getRegions,
  createRegion,
  updateRegion,
  deleteRegion,
} from "api/regions";

import { Table, Actions } from "components/Table/Table";
import EditRegionModal from "components/EditRegionModal";
import ConfirmModal from "components/ConfirmModal";

import { getDate } from "utils/utils";

/**
 * FLUSSREGIONEN
 *
 * /regions
 *
 */

const Regions = () => {
  const { authData } = useAuth();
  const { showError } = useError();
  const { t } = useTranslation();

  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);

  const handleRowEdit = (data) => {
    setRegion(data);
    setShowEditModal(true);
  };

  const handleRowDelete = (data) => {
    setRegion(data);
    setShowConfirmDeleteModal(true);
  };

  const handleSubmitEdit = async (data) => {
    if (region) {
      const response = await updateRegion({
        token: authData?.access_token,
        ...data,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        console.log(response);
      } else {
        toast.success(t("Region updated!"));
        setRefreshTable(true);
      }
    } else {
      const response = await createRegion({
        token: authData?.access_token,
        ...data,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        console.log(response);
      } else {
        toast.success(t("Region created!"));
        setRefreshTable(true);
      }
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setShowConfirmDeleteModal(false);

      const response = await deleteRegion({
        token: authData.access_token,
        id: region.id,
      });

      if (response.status === 200 || response.status === 204) {
        setRegion(null);
        setRefreshTable(true);
        toast.success(t("Region deleted!"));
      }
    } catch (error) {
      console.error("Error deleting region:", error);
      showError(error.response.data.detail);
    }
  };

  // table - row actions

  const actions = useMemo(
    () => [
      {
        name: "edit",
        icon: "ri-pencil-line",
        command: handleRowEdit,
        title: t("Edit"),
      },
      {
        name: "delete",
        icon: "ri-delete-bin-line",
        command: handleRowDelete,
        title: t("Delete"),
      },
    ],
    [t],
  );

  // table - columns

  const columns = useMemo(
    () =>
      [
        {
          Header: t("Region"),
          accessor: "name",
        },
        {
          Header: t("Area"),
          accessor: "area_name",
        },
        {
          Header: t("Created by"),
          accessor: "create_user_name",
          cssClass: "min-cell-width",
        },
        {
          Header: t("Created on"),
          accessor: "create_date",
          cssClass: "text-center",
          Cell: ({ cell: { value } }) => getDate(value),
        },
        {
          Header: t("Updated by"),
          accessor: "modify_user_name",
          cssClass: "min-cell-width",
        },
        {
          Header: t("Updated on"),
          accessor: "modify_date",
          cssClass: "text-center",
          Cell: ({ cell: { value } }) => getDate(value),
        },
        actions.length > 0 && {
          Header: t("Actions"),
          accessor: "accessor",
          cssClass: "text-center min-cell-width",
          disableSortBy: true,
          Cell: ({ row: { original } }) => (
            <Actions row={original} actions={actions} />
          ),
        },
      ].filter((item) => item !== false),
    [actions, t],
  );

  // get all regions

  useEffect(() => {
    (async () => {
      const data = await getRegions();
      if (data.code === "ERR_BAD_REQUEST") {
        toast.error(t(data.response.data.detail));
        console.log(data);
      } else {
        setRegions(data);
        setRefreshTable(false);
      }
    })();
  }, [refreshTable, t]);

  return (
    <>
      <div className="regions-container relative flex-grow h-full px-16 overflow-auto">
        <div className="content-wrapper max-h-full flex flex-col">
          <h1 className="flex items-center">
            {t("Water regions")}
            <span className="ml-4 text-sm text-secondary">
              <NavLink to="/dashboard">{t("Back to Dashboard")}</NavLink>
            </span>
          </h1>
          <p className="max-w-prose">
            Sed turpis tincidunt id aliquet. Feugiat sed lectus vestibulum
            mattis ullamcorper velit sed ullamcorper morbi. Facilisis mauris sit
            amet massa vitae tortor condimentum lacinia quis. Elementum
            curabitur vitae nunc sed velit dignissim.
          </p>
          <Table
            className="max-h-full"
            columns={columns}
            data={regions}
            newAction={() => {
              setRegion(null);
              setShowEditModal(true);
            }}
            newActionLabel={t("Create new region")}
            searchLabel={t("Search by region or area")}
            searchColumns={["name", "area_name"]}
            onRowClick={(row) => handleRowEdit(row.original)}
          />
        </div>
      </div>

      {showEditModal && (
        <EditRegionModal
          data={region}
          onClose={() => setShowEditModal(false)}
          onSubmit={(data) => handleSubmitEdit(data)}
        />
      )}

      {showConfirmDeleteModal && (
        <ConfirmModal
          title={t("Delete region?")}
          message=<span>
            {t("Are you sure you want to delete the region {{name}}?", {
              name: `${region.name}`,
            })}
          </span>
          yesMessage={t("Delete")}
          destructive={true}
          onClose={() => {
            setRegion(null);
            setShowConfirmDeleteModal(false);
          }}
          onSubmit={handleConfirmDelete}
        />
      )}
    </>
  );
};

export default Regions;
