import axiosInstance from "api/axiosInstance";

/**
 *  GET /fish_release
 *
 *  get all fish release records
 */
export const getFishReleases = async () => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get("/fish_release", { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /fish_release/page/{page_number}
 *
 *  get all fish release records using pagination
 */
export const getFishReleasesPage = async ({ pageNumber, pageSize = 20 }) => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get(
			`/fish_release/page?page_number=${pageNumber}&page_size=${pageSize}`,
			{
				headers,
			},
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /fish_release/{id}
 *
 *  get a fish release record by id
 */
export const getFishRelease = async ({ id }) => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get(`/fish_release/${id}`, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /fish_release/location/{id}
 *
 *  get all fish release records for a specific location
 */
export const getStockingHistory = async ({ id }) => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get(`/fish_release/location/${id}`, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  POST /fish_release
 *
 *  create a fish release record
 */
export const createFishRelease = async ({
	token,
	year,
	fish_type_id,
	fish_age_id,
	fish_origin_id,
	count,
	city_id,
	comment,
	source,
	import_id,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		year: year,
		fish_type_id: fish_type_id,
		fish_age_id: fish_age_id,
		fish_origin_id: fish_origin_id,
		count: count,
		city_id: city_id,
		comment: comment,
		source: source,
		import_id: import_id,
	};

	try {
		const response = await axiosInstance.post(`/fish_release`, data, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  PUT /fish_release/{id}
 *
 *  update a fish release record
 */
export const updateFishRelease = async ({
	id,
	token,
	year,
	fish_type_id,
	fish_age_id,
	fish_origin_id,
	count,
	city_id,
	comment,
	source,
	import_id,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		year: year,
		fish_type_id: fish_type_id,
		fish_age_id: fish_age_id,
		fish_origin_id: fish_origin_id,
		count: count,
		city_id: city_id,
		comment: comment,
		source: source,
		import_id: import_id,
	};

	try {
		const response = await axiosInstance.put(`/fish_release/${id}`, data, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  DELETE /fish_release/{id}
 *
 *  delete a fish release record
 */
export const deleteFishRelease = async ({ token, id }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.delete(`/fish_release/${id}`, {
			headers,
		});
		return response;
	} catch (error) {
		throw error;
	}
};
