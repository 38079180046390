import { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal/Modal";
import Input from "components/Input/Input";
import Button from "components/Button/Button";

const EditMarkingModal = ({ data, onClose, onSubmit }) => {
	const { t } = useTranslation();
	const [marking, setMarking] = useState(data || { id: "", name: "" });

	const title = marking.id ? t("Edit marking") : t("Create new marking");
	const description = marking.id
		? t("You can change the name of the marking.")
		: t("Fill in the name of the marking.");

	const handleSubmit = () => {
		if (typeof onSubmit === "function") onSubmit(marking);
		setMarking(null);
		onClose();
	};

	return (
		<Modal title={title} onClose={onClose} onSubmit={onSubmit}>
			<form className="flex flex-col gap-8 w-full" onSubmit={handleSubmit}>
				<p className="m-0 text-sm">{description}</p>
				<Input
					id="marking-name"
					label={t("Marking name")}
					type="text"
					name="marking-name"
					value={marking.name}
					onChange={(e) => setMarking({ ...marking, name: e.target.value })}
					autoFocus
					required
				/>
				<div className="mt-4 flex gap-4 justify-center">
					<Button className="btn-primary" onClick={handleSubmit}>
						{t("Save")}
					</Button>
					<Button className="btn" onClick={onClose}>
						{t("Cancel")}
					</Button>
				</div>
			</form>
		</Modal>
	);
};

export default EditMarkingModal;
