import { useTranslation } from "react-i18next";

const Policies = () => {
  const { t } = useTranslation();

  return (
    <div className="content-container relative flex-grow h-full px-16 overflow-scroll">
      <div className="content-wrapper max-w-prose">
        <h1>{t("Datenschutz")}</h1>
        <p>
          Zur Erfüllung der Informationspflichten aus Art. 13 DSGVO muss in
          jedem Fall{" "}
          <strong>
            über die Datenverarbeitung durch Google Maps in der
            Datenschutzerklärung der Website informiert werden
          </strong>
          . Hier muss genau beschrieben werden, unter welchen Voraussetzungen
          personenbezogene Daten der BesucherInnen an Drittanbieter
          weitergegeben werden sowie welche Daten, wie lange, zu welchem Zweck
          erhoben werden.
        </p>
        <p>
          Die Einbindung von Google Maps bedeutet, dass beim Aufruf der Seite
          ggf. Cookies von Google (mindestens das sogenannte NID-Cookie) gesetzt
          werden, welche Nutzereinstellungen und -informationen speichern und
          eine Verbindung zum Goolge-Netzwerk aufnehmen. Auf diese Weise können
          dort innerhalb von Nutzerprofilen diverse Informationen ausgewertet
          werden (auch wenn sie nicht in ein Google-Konto eingeloggt sind). Aus
          diesem Grund müssen Cookies von Google Maps als Analyse-Cookies
          kategorisiert werden und sind im <strong>Cookie Banner</strong> mit
          einer granularen Opt-In-Funktion aufzuführen.
        </p>
      </div>
    </div>
  );
};

export default Policies;
