import { useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitch = ({ languages, ...props }) => {
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLang(i18n.language);
  };

  return (
    <ul
      className={`language-switch mx-2 flex flex-nowrap gap-2 text-sm ${props.className}`}
    >
      {languages &&
        languages.map(({ name, code }, index, array) => (
          <li key={index} className="whitespace-nowrap">
            <button
              className={`language ${code === currentLang ? "font-bold" : ""}`}
              onClick={() => changeLanguage(code)}
            >
              {name}
            </button>
            {index !== array.length - 1 && <span className="ml-2">|</span>}
          </li>
        ))}
    </ul>
  );
};

export default LanguageSwitch;
