import { useId } from "react";

import "./Checkbox.scss";

export const Checkbox = ({
  className,
  label,
  checked = false,
  disabled = false,
  onChange,
  ...props
}) => {
  const id = "checkbox_" + useId();

  return (
    <div
      className={`checkbox-container flex items-center ${
        className ? className : ""
      } ${disabled ? "disabled" : ""}`}
    >
      <input
        id={id}
        type="checkbox"
        className="cursor-pointer"
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        onClick={(event) => event.stopPropagation()}
      />
      <label htmlFor={id} className="pl-2 cursor-pointer">
        {label}
      </label>
    </div>
  );
};
