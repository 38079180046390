import { useState } from "react";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";

import mapStyles from "./GoogleMapStyles";

// using the functional MarkerF instead of the class component Marker
// since Marker does not show in StrictMode
// https://github.com/JustFly1984/react-google-maps-api/issues/2978

import "./GoogleMaps.scss";

export const getCenter = (obj) => {
  return typeof obj.gps_lat === "number" || typeof obj.gps_lng === "number"
    ? {
        name: obj.name,
        lat: obj.gps_lat,
        lng: obj.gps_lng,
      }
    : false;
};

export const getZoom = (obj) => {
  return typeof obj.gps_lat === "number" || typeof obj.gps_lng === "number"
    ? 10
    : 6;
};

export const getMarker = (obj) => {
  return typeof obj.gps_lat === "number" || typeof obj.gps_lng === "number"
    ? [
        {
          name: obj.name,
          lat: obj.gps_lat,
          lng: obj.gps_lng,
        },
      ]
    : false;
};

export const GoogleMaps = ({
  center = { lat: 51.1657, lng: 10.4515 },
  markers = [],
  zoom = 10,
  markerOnClick = null,
  onLoad = null,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    language: "de",
  });

  const getMapCenter = () => {
    return typeof center == "object" ? center : { lat: 51.1657, lng: 10.4515 };
  };

  const [selectedMarker, setSelectedMarker] = useState("");

  const orgaMarker = require("./../../assets/icons/marker-organization.png");
  const orgaMarkerSelected = require("./../../assets/icons/marker-organization-selected.png");
  const stockingMarker = require("./../../assets/icons/marker-stocking.png");
  const stockingMarkerSelected = require("./../../assets/icons/marker-stocking-selected.png");
  const returnersMarker = require("./../../assets/icons/marker-returners.png");
  const returnersMarkerSelected = require("./../../assets/icons/marker-returners-selected.png");
  const historyMarker = require("./../../assets/icons/marker-history.png");
  const historyMarkerSelected = require("./../../assets/icons/marker-history-selected.png");

  const getMarkerIcon = (marker, selectedMarker) => {
    switch (marker.type) {
      case "organization":
        return marker.id === selectedMarker.id &&
          marker.type === selectedMarker.type
          ? orgaMarkerSelected
          : orgaMarker;
      case "stocking":
        return marker.id === selectedMarker.id &&
          marker.type === selectedMarker.type
          ? stockingMarkerSelected
          : stockingMarker;
      case "returners":
        return marker.id === selectedMarker.id &&
          marker.type === selectedMarker.type
          ? returnersMarkerSelected
          : returnersMarker;
      case "history":
        return marker.id === selectedMarker.id &&
          marker.type === selectedMarker.type
          ? historyMarkerSelected
          : historyMarker;
      default:
        return "";
    }
  };

  const getMarkerZIndex = (marker, selectedMarker) => {
    return marker.id === selectedMarker.id &&
      marker.type === selectedMarker.type
      ? 999
      : marker.zIndex;
  };

  const handleMarkerClick = (marker) => {
    setSelectedMarker({ id: marker.id, type: marker.type });
    markerOnClick && markerOnClick(marker);
  };

  return (
    <>
      {!isLoaded ? (
        <h3 className="text-center">Loading map...</h3>
      ) : (
        <GoogleMap
          mapContainerClassName="map h-full w-full"
          center={getMapCenter()}
          zoom={zoom}
          options={{
            styles: mapStyles,
            fullscreenControl: false,
          }}
          onLoad={(map) => onLoad && onLoad(map)}
          defaultMapTypeId={"custom_style"}
          scaleControl={true}
        >
          {markers &&
            markers.map((marker, index) => {
              const icon = getMarkerIcon(marker, selectedMarker);
              const zIndex = getMarkerZIndex(marker, selectedMarker);

              return (
                <MarkerF
                  key={index}
                  title={marker.title}
                  position={{ lat: marker.lat, lng: marker.lng }}
                  options={{
                    icon: icon,
                    zIndex: zIndex,
                  }}
                  optimized={true}
                  onClick={() => handleMarkerClick(marker)}
                />
              );
            })}
        </GoogleMap>
      )}
    </>
  );
};
