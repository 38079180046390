import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { getAreas } from "api/areas";

import Modal from "components/Modal/Modal";
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import Button from "components/Button/Button";
import ErrorHint from "components/ErrorHint/ErrorHint";
import ContentMeta from "components/ContentMeta/ContentMeta";
import {
	GoogleMaps,
	getCenter,
	getZoom,
	getMarker,
} from "components/MapViewer/GoogleMaps";

const EditWaterModal = ({ data, onClose, onSubmit }) => {
	const { t } = useTranslation();
	const [mapRef, setMapRef] = useState();
	const [water, setWater] = useState(
		data || {
			id: "",
			name: "",
			area_id: "",
			area_name: "",
			gps_lat: "",
			gps_lng: "",
		},
	);
	const [areas, setAreas] = useState([]);
	const [errors, setErrors] = useState({});

	const title = water.id ? t("Edit water body") : t("Create new water body");
	const description = water.id
		? t("You can change the name of the water body or the area.")
		: t("Fill in the name of the water body and select the area.");

	// get all areas

	useEffect(() => {
		(async () => {
			const areasData = await getAreas();

			if (areasData.code === "ERR_BAD_REQUEST") {
				toast.error(t(areasData.response.data.detail));
				console.log(areasData);
			} else {
				const areas = areasData.map((obj) => ({
					value: obj.id,
					label: obj.name,
				}));
				setAreas(areas);
			}
		})();
	}, [water, t]);

	const handleChangeName = (e) => {
		setWater({ ...water, name: e.target.value });
		if (errors.name) setErrors({ ...errors, name: "" });
	};

	const handleChangeArea = (value) => {
		setWater({
			...water,
			area_id: value.value,
			area_name: value.label,
		});
		if (errors.area_id) setErrors({ ...errors, area_id: "" });
	};

	const handleChangeGPSLat = (e) => {
		setWater({ ...water, gps_lat: Number(e.target.value) });
		if (errors.gps) setErrors({ ...errors, gps: "" });
	};

	const handleChangeGPSLng = (e) => {
		setWater({ ...water, gps_lng: Number(e.target.value) });
		if (errors.gps) setErrors({ ...errors, gps: "" });
	};

	const handleUpdateCoords = () => {
		if (mapRef) {
			setWater({
				...water,
				gps_lat: mapRef.center.lat(),
				gps_lng: mapRef.center.lng(),
			});
		}
		if (errors.gps) setErrors({ ...errors, gps: "" });
	};

	const validateData = () => {
		const errors = {};

		if (!water.name.trim()) {
			errors.name = t("Water body name is required");
		}
		if (!water.area_id) {
			errors.area_id = t("Area is required");
		}
		if (!water.gps_lat || !water.gps_lng) {
			errors.gps = t("GPS coordinates are required");
		}

		return errors;
	};

	const handleSubmit = () => {
		const validationErrors = validateData();

		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
			return;
		}

		if (typeof onSubmit === "function") onSubmit(water);
		setWater(null);
		onClose();
	};

	return (
		<Modal
			className="w-3/4 h-5/6"
			title={title}
			onClose={onClose}
			onSubmit={onSubmit}
		>
			<div className="h-full flex gap-8">
				<form
					className="min-w-80 basis-1/3 flex flex-col gap-10 w-full overflow-y-auto px-4 py-6 -my-6 -mx-4"
					onSubmit={handleSubmit}
				>
					<p className="m-0 text-sm">{description}</p>
					<Input
						id="water-name"
						label={t("Water body name")}
						type="text"
						name="water-name"
						value={water.name}
						onChange={handleChangeName}
						autoFocus
						required
					/>
					<ErrorHint message={errors.name} />

					<Select
						label={t("Area name")}
						options={areas}
						value={{ label: water.area_name, value: water.area_id }}
						allowNone={false}
						onChange={handleChangeArea}
						required
					/>
					<ErrorHint message={errors.area_id} />

					<div className="flex gap-1">
						<Input
							id="gps-lat"
							label={t("GPS Lat")}
							name="gps-lat"
							value={water.gps_lat}
							onChange={handleChangeGPSLat}
							required
						/>
						<Input
							id="gps-lng"
							label={t("GPS Lng")}
							name="gps-lng"
							value={water.gps_lng}
							onChange={handleChangeGPSLng}
							required
						/>
						<Button
							className="btn shrink w-10 min-w-10 bg-transparent"
							title={t("Update coordinates")}
							onClick={handleUpdateCoords}
						>
							<i className="ri-loop-left-line"></i>
						</Button>
					</div>
					<ErrorHint message={errors.gps} />

					<ContentMeta data={water} />

					<div className="mt-auto flex justify-between">
						<Button className="btn-primary" onClick={handleSubmit}>
							{t("Save")}
						</Button>
						<Button className="btn" onClick={onClose}>
							{t("Cancel")}
						</Button>
					</div>
				</form>

				<div className="h-auto w-full relative -my-6 -mx-4">
					<GoogleMaps
						center={getCenter(water)}
						zoom={getZoom(water)}
						markers={getMarker(water)}
						onLoad={(map) => setMapRef(map)}
						onMapClick={(event) =>
							console.log(
								"hoi",
								event.map.getCenter().lat(),
								event.map.getCenter().lng(),
							)
						}
					/>
					<div className="absolute top-1/2 left-1/2 text-red-500 text-2xl translate-x-[-50%] translate-y-[-50%]">
						<i className="ri-add-line"></i>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default EditWaterModal;
