import axiosInstance from "api/axiosInstance";

/**
 *  GET /country
 *
 *  get all countries
 */
export const getCountries = async () => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get("/country", { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /country/{id}
 *
 *  get a country by id
 */
export const getCountry = async ({ id }) => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get(`/country/${id}`, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  POST /country
 *
 *  create a country
 */
export const createCountry = async ({ token, name }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
	};

	try {
		const response = await axiosInstance.post(`/country`, data, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  PUT /country/{id}
 *
 *  update a country
 */
export const updateCountry = async ({ token, id, name }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
	};

	try {
		const response = await axiosInstance.put(`/country/${id}`, data, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  DELETE /country/{id}
 *
 *  delete a country
 */
export const deleteCountry = async ({ token, id }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.delete(`/country/${id}`, { headers });
		return response;
	} catch (error) {
		throw error;
	}
};
