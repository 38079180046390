import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";

// import { useAuth } from "context/AuthContext";

import File from "components/Input/File";
import Button from "components/Button/Button";
import { Checkbox } from "components/Checkbox/Checkbox";
import { Table } from "components/Table/Table";

import "./Import.scss";

/**
 * IMPORT
 *
 * /import
 *
 */

const Import = () => {
  // const { authData } = useAuth();
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [canSubmit, setCanSubmit] = useState(false);
  const [ignoreFirstRow, setIgnoreFirstRow] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleOpenFile = () => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          setData(rows);
          setFilteredData(rows);
        } catch (error) {
          toast(String(error));
          console.log("error", error);
        }
      };
      reader.readAsArrayBuffer(file);
      setCanSubmit(true);
    } else {
      console.log("No file selected.");
    }
  };

  const handleSubmit = () => {
    console.log("submitting filteredData");
    toast(t("File imported"));
  };

  /*
  const columns =
    data &&
    data[0]?.map((cell, index) => {
      console.log(cell);
      return {
        Header: String(cell),
        accessor: String(index),
      };
    });
  */

  const columns = [
    {
      Header: t("Year") + " *",
      accessor: "0",
      cssClass: "whitespace-nowrap",
      disableSortBy: true,
    },
    {
      Header: t("Fish type") + " *",
      accessor: "1",
      cssClass: "whitespace-nowrap",
      disableSortBy: true,
    },
    {
      Header: t("Fish origin"),
      accessor: "2",
      cssClass: "whitespace-nowrap",
      disableSortBy: true,
    },
    {
      Header: t("Fish age"),
      accessor: "3",
      cssClass: "whitespace-nowrap",
      disableSortBy: true,
    },
    {
      Header: t("Count") + " *",
      accessor: "4",
      cssClass: "whitespace-nowrap",
      disableSortBy: true,
    },
    {
      Header: t("Marking"),
      accessor: "5",
      cssClass: "whitespace-nowrap",
      disableSortBy: true,
    },
    {
      Header: t("Marked count"),
      accessor: "6",
      cssClass: "whitespace-nowrap",
      disableSortBy: true,
    },
    {
      Header: t("Water body"),
      accessor: "7",
      cssClass: "whitespace-nowrap",
      disableSortBy: true,
    },
    {
      Header: t("Location") + " *",
      accessor: "8",
      cssClass: "whitespace-nowrap",
      disableSortBy: true,
    },
    {
      Header: t("Organization"),
      accessor: "9",
      cssClass: "whitespace-nowrap",
      disableSortBy: true,
    },
    {
      Header: t("Comments"),
      accessor: "10",
      cssClass: "whitespace-nowrap",
      disableSortBy: true,
    },
    {
      Header: t("Source"),
      accessor: "11",
      cssClass: "whitespace-nowrap",
      disableSortBy: true,
    },
  ];

  useEffect(() => {
    setFilteredData(ignoreFirstRow ? data.slice(1) : data);
  }, [data, ignoreFirstRow]);

  return (
    <>
      <div className="import-container relative flex-grow h-full px-16 overflow-auto">
        <div className="content-wrapper max-h-full flex flex-col">
          <h1 className="flex items-center">
            {t("Import data - Stocking measures")}
            <span className="ml-4 text-sm text-secondary">
              <NavLink to="/dashboard">{t("Back to Dashboard")}</NavLink>
            </span>
          </h1>
          <div className="flex gap-16">
            <div className="left-side w-7/12 flex flex-col gap-8">
              <h2 className="-mb-1 font-light leading-none text-primary">
                {t("1. Open file")}
              </h2>
              <File
                className="w-full"
                accept=".xlsx, .xls, .ods, .csv"
                buttonCaption={t("Open")}
                description={
                  t("Supported file types:") + " *.xlsx *.xls *.ods *.csv"
                }
                onChange={handleFileChange}
                onOpen={handleOpenFile}
              />
              <div className="flex flex-col gap-2">
                <h2 className="font-light leading-none text-primary">
                  {t("2. Preview")}
                </h2>
                <p>
                  {t(
                    "The uploaded file is displayed here before it can be imported into the database.",
                  )}
                </p>
                <div className="preview-window h-[18rem] mt-4 border text-sm overflow-scroll">
                  <div className="w-auto">
                    <Table
                      className="overflow-visible"
                      columns={columns}
                      data={filteredData}
                      showSearch={false}
                    />
                  </div>
                </div>
                <Checkbox
                  id="ignore-first-row"
                  label={t("Exclude first row when importing")}
                  checked={ignoreFirstRow}
                  disabled={!canSubmit ? "disabled" : ""}
                  onChange={(event) => setIgnoreFirstRow(event.target.checked)}
                />
              </div>
              <div>
                <h2 className="mb-2 font-light leading-none text-primary">
                  {t("3. Import")}
                </h2>
                <div className="w-full flex items-start gap-4">
                  <p>{t("Click on Import after checking the data.")}</p>
                  <Button
                    className={`btn-primary ml-auto ${
                      !canSubmit ? "disabled" : ""
                    }`}
                    onClick={handleSubmit}
                  >
                    {t("Import")}
                  </Button>
                </div>
              </div>
            </div>
            <div className="right-side w-5/12">
              <h2 className="mb-4 font-light leading-none text-primary">
                {t("File characteristics (only DE)")}
              </h2>
              <div className="font-light text-sm">
                <p className="mb-0">
                  {t("The following file types are supported:")}
                </p>
                <table className="mb-4 max-w-full border-separate border-spacing-x-4 border-spacing-y-1">
                  <tbody className="align-top">
                    <tr>
                      <td className="min-cell-width">Excel</td>
                      <td>.xls(x)</td>
                    </tr>
                    <tr>
                      <td className="min-cell-width">OpenOffice</td>
                      <td>.ods</td>
                    </tr>
                    <tr>
                      <td className="min-cell-width">CSV</td>
                      <td>.csv</td>
                    </tr>
                  </tbody>
                </table>
                <p className="mb-0">
                  Die CSV Datei muss dem folgenden Format entsprechen:
                </p>
                <table className="mb-4 max-w-full border-separate border-spacing-x-4 border-spacing-y-1">
                  <tbody className="align-top">
                    <tr>
                      <td>Komma ( , ) als Zellen-Trenner</td>
                    </tr>
                    <tr>
                      <td>Anführungszeichen ( " ) als String-Trenner</td>
                    </tr>
                    <tr>
                      <td> Zeichensatz Western Europe (ISO-8859-15)</td>
                    </tr>
                  </tbody>
                </table>
                <p className="mb-0">
                  Die Datei muss über folgenden Spalten verfügen:
                </p>
                <table className="mb-4 max-w-full border-separate border-spacing-x-4 border-spacing-y-1">
                  <tbody className="align-top">
                    <tr>
                      <td>Jahr *</td>
                      <td>Jahr der Besatzmaßnahme</td>
                    </tr>
                    <tr>
                      <td>Art *</td>
                      <td>Fischart, z.B. Lachs</td>
                    </tr>
                    <tr>
                      <td>Stamm</td>
                      <td>Fischstamm, z.B. Lagan</td>
                    </tr>
                    <tr>
                      <td>Stadium</td>
                      <td>Stadium, z.B. Brütling</td>
                    </tr>
                    <tr>
                      <td>Anzahl *</td>
                      <td>Anzahl Fische</td>
                    </tr>
                    <tr>
                      <td>Markierung</td>
                      <td>Markierung der Fische</td>
                    </tr>
                    <tr>
                      <td>Menge</td>
                      <td>markierte Menge</td>
                    </tr>
                    <tr>
                      <td>Gewässer</td>
                      <td>Gewässer zur automatischen Bestimmung des Ortes</td>
                    </tr>
                    <tr>
                      <td>Ort *</td>
                      <td>
                        Ein im System bereits angelegter Ort, oder leer zur
                        automatischen Bestimmung anhand des Gewässers
                      </td>
                    </tr>
                    <tr>
                      <td>Organisation</td>
                      <td>Organisation der Besatzmaßnahme</td>
                    </tr>
                    <tr>
                      <td>Bemerkung</td>
                      <td>Bemerkung zur Besatzmaßnahme</td>
                    </tr>
                    <tr>
                      <td>Quelle</td>
                      <td>Quellenangabe zur Besatzmaßnahme</td>
                    </tr>
                  </tbody>
                </table>
                <p>* Pflichtfelder</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Import;
