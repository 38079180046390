import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Page404 = () => {
  const { t } = useTranslation();

  return (
    <div className="content-container relative flex-grow h-full px-16 overflow-scroll">
      <div className="content-wrapper max-w-prose">
        <h1>{t("Page not found or the access is restriced")}</h1>
        <p>Die Wanderfische Internet Datenbank ist für jedermann zugänglich.</p>
        <p>
          Die gewünschte Seite wurde nicht gefunden oder Sie haben nicht
          ausreichende Zugangsrechte. Falls Sie ein Konto haben,{" "}
          <NavLink to="/login">melden Sie sich an</NavLink> or navigieren Sie
          zurück auf der <NavLink to="/">Startseite</NavLink>.
        </p>
      </div>
    </div>
  );
};

export default Page404;
