import "./Button.scss";

const Button = ({
  className,
  children,
  onClick,
  disabled = false,
  ...rest
}) => {
  let classes = ["btn"];

  if (disabled) classes.push("disabled");

  if (className) classes.push(className);

  const handleClick = (event) => {
    if (onClick) {
      event.preventDefault();
      event.stopPropagation();
      onClick();
    }
  };

  return (
    <button
      className={classes.join(" ")}
      onClick={handleClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
