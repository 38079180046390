import Input from "components/Input/Input";

const SortableListInput = ({ value, editMode, onBlur, onChange, ...props }) => {
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (editMode) onBlur();
    }
  };

  console.log("inserting an inputCell with value", value);
  console.log("editMode", editMode);

  return (
    <>
      {editMode ? (
        <Input
          className="editable-input inline-block w-full leading-none"
          value={value}
          autoFocus={true}
          onBlur={onBlur}
          onChange={(e) => onChange && onChange(e.target.value)}
          onKeyPress={handleKeyPress}
          {...props}
        />
      ) : (
        <div className="editable-input value inline-block w-full">
          <span
            title={value}
            className="inline-block max-w-full whitespace-nowrap overflow-hidden overflow-ellipsis"
          >
            {value}
            <i className="ri-pencil-line ml-2 leading-none" />
          </span>
        </div>
      )}
    </>
  );
};

export default SortableListInput;
