import { Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAuth } from "context/AuthContext";

import MainLayout from "layouts/MainLayout";
import Map from "views/Map";
import About from "views/About";
import Contact from "views/Contact";
import Impressum from "views/Impressum";
import Login from "views/Login";
import Register from "views/Register";
import Terms from "views/Terms";
import Privacy from "views/Privacy";
import Dashboard from "views/Dashboard";
import Areas from "views/Areas";
import Regions from "views/Regions";
import Waters from "views/Waters";
import Locations from "views/Locations";
import Countries from "views/Countries";
import States from "views/States";
import Organizations from "views/Organizations";
import Profiles from "views/Profiles";
import FishReleases from "views/FishReleases";
import FishReturners from "views/FishReturners";
import History from "views/History";
import FishTypes from "views/FishTypes";
import Markings from "views/Markings";
import FishOrigins from "views/FishOrigins";
import Import from "views/Import";
import Page404 from "views/Page404";

import "./App.scss";

const App = () => {
  const { t } = useTranslation();
  const { authData, isLoading } = useAuth();
  const isAuthenticated = Boolean(authData && authData.access_token);

  if (isLoading) {
    return <div className="m-auto">{t("Loading...")}</div>;
  }

  return (
    <>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Map />} index />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          {isAuthenticated && (
            <>
              <Route path="/dashboard" element={<Dashboard />} />

              <Route path="/fish-releases" element={<FishReleases />} />
              <Route path="/fish-returners" element={<FishReturners />} />
              <Route path="/history" element={<History />} />

              <Route path="/fish-types" element={<FishTypes />} />
              <Route path="/markings" element={<Markings />} />
              <Route path="/origins" element={<FishOrigins />} />

              <Route path="/import" element={<Import />} />

              <Route path="/areas" element={<Areas />} />
              <Route path="/regions" element={<Regions />} />
              <Route path="/waters" element={<Waters />} />
              <Route path="/locations" element={<Locations />} />

              <Route path="/countries" element={<Countries />} />
              <Route path="/states" element={<States />} />

              <Route path="/organizations" element={<Organizations />} />
              <Route path="/profiles" element={<Profiles />} />
            </>
          )}
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </>
  );
};

// i'm not sure if withNamespaces() is needed in our case
// if translation fails, then swap the following lines
// export default withNamespaces()(App);
export default App;
