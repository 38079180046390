import { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal/Modal";
import Input from "components/Input/Input";
import Button from "components/Button/Button";
import ErrorHint from "components/ErrorHint/ErrorHint";

const EditCountryModal = ({ data, onClose, onSubmit }) => {
	const { t } = useTranslation();
	const [country, setCountry] = useState(data || { id: "", name: "" });
	const [errors, setErrors] = useState({});

	const title = country.id ? t("Edit country") : t("Create new country");
	const description = country.id
		? t("You can change the name of the country.")
		: t("Fill in the name of the country.");

	const handleChangeCountry = (e) => {
		setCountry({ ...country, name: e.target.value });
		if (errors.name) setErrors({ ...errors, name: "" });
	};

	const validateData = () => {
		const errors = {};

		if (!country.name.trim()) {
			errors.name = t("Country name is required");
		}

		return errors;
	};

	const handleSubmit = () => {
		const validationErrors = validateData();

		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
			return;
		}

		if (typeof onSubmit === "function") onSubmit(country);
		setCountry(null);
		onClose();
	};

	return (
		<Modal title={title} onClose={onClose} onSubmit={onSubmit}>
			<form className="flex flex-col gap-10 w-full" onSubmit={handleSubmit}>
				<p className="m-0 text-sm">{description}</p>
				<Input
					id="country-name"
					label={t("Country name")}
					type="text"
					name="country-name"
					value={country.name}
					onChange={handleChangeCountry}
					autoFocus
					required
				/>
				<ErrorHint message={errors.name} />

				<div className="mt-4 flex gap-4 justify-center">
					<Button className="btn-primary" onClick={handleSubmit}>
						{t("Save")}
					</Button>
					<Button className="btn" onClick={onClose}>
						{t("Cancel")}
					</Button>
				</div>
			</form>
		</Modal>
	);
};

export default EditCountryModal;
