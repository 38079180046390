import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { ErrorProvider } from "context/ErrorContext";
import { ConsentProvider } from "context/ConsentContext";
import { AuthProvider } from "context/AuthContext";

import App from "./App";

import "./i18n";

import "remixicon/fonts/remixicon.css";

import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ErrorProvider>
      <BrowserRouter>
        <ConsentProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </ConsentProvider>
      </BrowserRouter>
    </ErrorProvider>
  </React.StrictMode>,
);
