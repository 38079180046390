import { useMemo, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useAuth } from "context/AuthContext";
import { useError } from "context/ErrorContext";

import {
  getReturnersPage,
  createFishReturner,
  updateFishReturner,
  deleteFishReturner,
} from "api/fishReturners";

import { Table, Actions, Pagination } from "components/Table/Table";
import EditFishReturnerModal from "components/EditFishReturnerModal";
import ConfirmModal from "components/ConfirmModal";

import { getDate } from "utils/utils";

/**
 * RÜCKKEHRER
 *
 * /fish-returners
 *
 */

const FishReturners = () => {
  const { authData } = useAuth();
  const { showError } = useError();
  const { t, i18n } = useTranslation();

  const [fishReturners, setFishReturners] = useState([]);
  const [fishReturner, setFishReturner] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [itemsCount, setItemsCount] = useState();

  const handleRowEdit = (data) => {
    setFishReturner(data);
    setShowEditModal(true);
  };

  const handleRowDelete = (data) => {
    setFishReturner(data);
    setShowConfirmDeleteModal(true);
  };

  const handleSubmitEdit = async (data) => {
    if (fishReturner) {
      const response = await updateFishReturner({
        token: authData?.access_token,
        ...data,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        console.log(response);
      } else {
        toast.success(t("Returner record updated!"));
        setRefreshTable(true);
      }
    } else {
      const response = await createFishReturner({
        token: authData?.access_token,
        ...data,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        console.log(response);
      } else {
        toast.success(t("Returner record created!"));
        setRefreshTable(true);
      }
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setShowConfirmDeleteModal(false);

      const response = await deleteFishReturner({
        token: authData.access_token,
        id: fishReturner.id,
      });

      if (response.status === 200 || response.status === 204) {
        setFishReturner(null);
        setRefreshTable(true);
        toast.success(t("Returner record deleted!"));
      }
    } catch (error) {
      console.error("Error deleting returner record:", error);
      showError(error.response.data.detail);
    }
  };

  // table - row actions

  const actions = useMemo(
    () => [
      {
        name: "edit",
        icon: "ri-pencil-line",
        command: handleRowEdit,
        title: t("Edit"),
      },
      {
        name: "delete",
        icon: "ri-delete-bin-line",
        command: handleRowDelete,
        title: t("Delete"),
      },
    ],
    [t],
  );

  // table - columns

  const columns = useMemo(
    () =>
      [
        {
          Header: t("Year"),
          accessor: "year",
          cssClass: "min-cell-width",
        },
        {
          Header: t("Fish type"),
          accessor: "fish_type_name",
          cssClass: "min-cell-width",
        },
        {
          Header: t("Fish age"),
          accessor: "fish_age_name",
          cssClass: "min-cell-width",
        },
        {
          Header: t("Count"),
          accessor: "count",
          cssClass: "min-cell-width text-right",
          Cell: ({ value, row }) => value?.toLocaleString(i18n.language),
        },
        {
          Header: t("Fish origin"),
          accessor: "fish_origin_name",
          cssClass: "min-cell-width",
        },
        {
          Header: t("Water body"),
          //accessor: "fish_origin_name",
        },
        {
          Header: t("Area"),
          //accessor: "fish_origin_name",
        },
        {
          Header: t("Location"),
          accessor: "city_name",
        },
        {
          Header: t("Created by"),
          accessor: "create_user_name",
          cssClass: "min-cell-width whitespace-nowrap",
        },
        {
          Header: t("Created on"),
          accessor: "create_date",
          cssClass: "text-center whitespace-nowrap",
          Cell: ({ cell: { value } }) => getDate(value),
        },
        {
          Header: t("Updated on"),
          accessor: "modify_date",
          cssClass: "w-min text-center whitespace-nowrap",
          Cell: ({ cell: { value } }) => getDate(value),
        },
        actions.length > 0 && {
          Header: t("Actions"),
          accessor: "accessor",
          cssClass: "text-center min-cell-width",
          disableSortBy: true,
          Cell: ({ row: { original } }) => (
            <Actions row={original} actions={actions} />
          ),
        },
      ].filter((item) => item !== false),
    [actions, t, i18n],
  );

  // get all fishReturners with pagination

  useEffect(() => {
    (async () => {
      const data = await getReturnersPage({
        pageNumber: pageNumber,
        pageSize: pageSize,
      });
      if (data.code === "ERR_BAD_REQUEST") {
        toast.error(t(data.response.data.detail));
        console.log(data);
      } else {
        setFishReturners(data.data);
        setPageCount(data.pagination.totalPages);
        setItemsCount(data.pagination.totalItems);
        setRefreshTable(false);
      }
    })();
  }, [refreshTable, t, pageNumber, pageSize, itemsCount]);

  return (
    <>
      <div className="fish-returners-container relative flex-grow h-full px-16 overflow-auto">
        <div className="content-wrapper max-h-full flex flex-col">
          <h1 className="flex items-center">
            {t("Returners")}
            <span className="ml-4 text-sm text-secondary">
              <NavLink to="/dashboard">{t("Back to Dashboard")}</NavLink>
            </span>
          </h1>
          <p className="max-w-prose">
            Sed turpis tincidunt id aliquet. Feugiat sed lectus vestibulum
            mattis ullamcorper velit sed ullamcorper morbi. Facilisis mauris sit
            amet massa vitae tortor condimentum lacinia quis. Elementum
            curabitur vitae nunc sed velit dignissim.
          </p>
          <Table
            className="max-h-full"
            columns={columns}
            data={fishReturners}
            newAction={() => {
              setFishReturner(null);
              setShowEditModal(true);
            }}
            newActionLabel={t("Create new returner record")}
            searchLabel={t("Search entire returners data")}
            manualSortBy={true}
            onRowClick={(row) => handleRowEdit(row.original)}
            onSortingChange={(sort) => console.log(sort)}
          />
          <Pagination
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageCount={pageCount}
            pageSize={pageSize}
            setPageSize={setPageSize}
            itemsCount={itemsCount}
          />
        </div>
      </div>

      {showEditModal && (
        <EditFishReturnerModal
          data={fishReturner}
          onClose={() => setShowEditModal(false)}
          onSubmit={(data) => handleSubmitEdit(data)}
        />
      )}

      {showConfirmDeleteModal && (
        <ConfirmModal
          title={t("Delete returner record?")}
          message=<span>
            {t("Are you sure you want to delete the selected returner record?")}
          </span>
          yesMessage={t("Delete")}
          destructive={true}
          onClose={() => {
            setFishReturner(null);
            setShowConfirmDeleteModal(false);
          }}
          onSubmit={handleConfirmDelete}
        />
      )}
    </>
  );
};

export default FishReturners;
