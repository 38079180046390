import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const translations = {};
const languages = ["en", "de"];

languages.forEach((language) => {
	const translation = require(`../i18n/${language}/resource.json`);
	translations[language] = { translation };
});

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: translations,
		lng: "de", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
		// you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
		// if you're using a language detector, do not define the lng option
		fallbackLng: "en",
		fallbackNS: "translation",
		interpolation: {
			format: (value, format, lng) => {
				if (format === "number") {
					if (typeof value === "number")
						return new Intl.NumberFormat(lng, {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						}).format(value);
					else return value;
				}
			},
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
