import { useState, useEffect } from "react";
import axiosInstance from "api/axiosInstance";

export function useAxios(url, options = {}) {
	const {
		method = "get",
		body = null,
		headers = JSON.stringify({ "Content-Type": "application/json" }),
	} = options;
	const [data, setData] = useState([]);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		let isMounted = true; // only update data if the component is mounted
		const source = axiosInstance.createCancelToken(); // Create a cancel token source

		const fetchData = async () => {
			setIsLoading(true);
			try {
				let response;
				switch (method.toLowerCase()) {
					case "get":
						response = await axiosInstance.get(url, {
							headers: JSON.parse(headers),
							cancelToken: source.token,
						});
						break;
					case "post":
						response = await axiosInstance.post(url, JSON.parse(body), {
							headers: JSON.parse(headers),
							cancelToken: source.token,
						});
						break;
					case "put":
						response = await axiosInstance.put(url, JSON.parse(body), {
							headers: JSON.parse(headers),
							cancelToken: source.token,
						});
						break;
					case "delete":
						response = await axiosInstance.delete(url, {
							headers: JSON.parse(headers),
							cancelToken: source.token,
						});
						break;
					default:
						console.log("mounted");

						throw new Error(`Unsupported method: ${method}`);
				}
				if (isMounted) {
					setData(response.data);
					setError(null);
				}
			} catch (error) {
				if (isMounted) {
					setError(
						axiosInstance.isCancel(error) ? "Request canceled" : error.message,
					);
					setData([]);
				}
			} finally {
				if (isMounted) {
					setIsLoading(false);
				}
			}
		};

		if (url) {
			fetchData();
		}

		// cleaning up
		return () => {
			isMounted = false;
			source.cancel("Request canceled by the user.");
		};
	}, [url, method, body, headers]);

	return { data, error, isLoading };
}
