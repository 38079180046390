import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div className="content-container relative flex-grow h-full px-16 overflow-scroll">
      <div className="content-wrapper max-w-prose">
        <h1>{t("Contact")}</h1>
        <p>
          Bitte lassen Sie uns wissen, wenn Ihnen auffällt, dass etwas fehlt
          oder nicht korrekt ist. Wenn Sie selbst Daten beisteuern können,
          nehmen Sie bitte Kontakt mit uns. Wir würden uns freuen, wenn Sie die
          Datenbank mit Ihren Daten bereichern und besser machen können.
        </p>
        <p className="mt-6">
          <strong>Wanderfische ohne Grenzen - NASF Deutschland e.V.</strong>
          <br />
          Armin Weinbrenner
          <br />
          Johannes-Giesser-Strasse 8<br />
          71364 Winnenden
          <br />
        </p>
        <p>
          <NavLink to="mailto:mail@wanderfische.eu">
            mail(at)wanderfische.eu
          </NavLink>
        </p>
      </div>
    </div>
  );
};

export default Contact;
