import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useAuth } from "context/AuthContext";

import { getStates } from "api/states";
import { getU2O, getProfiles } from "api/profiles";

import Modal from "components/Modal/Modal";
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import Button from "components/Button/Button";
import Textarea from "components/Textarea/Textarea";
import ErrorHint from "components/ErrorHint/ErrorHint";
import ContentMeta from "components/ContentMeta/ContentMeta";
import SortableList from "components/SortableList/SortableList";
import {
	GoogleMaps,
	getCenter,
	getZoom,
	getMarker,
} from "components/MapViewer/GoogleMaps";

const EditOrganizationModal = ({ data, onClose, onSubmit }) => {
	const { authData } = useAuth();
	const { t } = useTranslation();
	const [activeLink, setActiveLink] = useState("");
	const [mapRef, setMapRef] = useState();
	const [organization, setOrganization] = useState(
		data || {
			id: "",
			name: "",
			street: "",
			zip_code: "",
			city: "",
			state_id: undefined,
			state_name: "",
			gps_lat: "",
			gps_lng: "",
			comment: "",
		},
	);
	const [profiles, setProfiles] = useState([]);
	const [states, setStates] = useState([]);
	const [errors, setErrors] = useState({});

	const title = organization.id
		? t("Edit organization")
		: t("Create new organization");
	const description = organization.id
		? t("You can change the information about the organization.")
		: t("Fill in the information about the organization.");

	// get all states

	useEffect(() => {
		(async () => {
			const data = await getStates();

			if (data.code === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.data.detail));
				console.log(data);
			} else {
				const states = data.map((obj) => ({
					value: obj.id,
					label: obj.name,
				}));
				setStates(states);
			}
		})();
	}, [organization, t]);

	// get with user_to_organization all profiles that belong to an organization

	useEffect(() => {
		(async () => {
			const data = await getU2O({ token: authData.access_token });
			if (data.code === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.data.detail));
				console.log(data);
			} else {
				setOrganization((prevOrganization) => ({
					...prevOrganization,
					profiles: data.filter(
						(obj) => obj.organization_id === prevOrganization.id,
					),
				}));
			}
		})();
	}, [t, authData, setOrganization]);

	// get all profiles

	useEffect(() => {
		(async () => {
			const data = await getProfiles({ token: authData.access_token });
			if (data.code === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.data.detail));
				console.log(data);
			} else {
				setProfiles(data);
			}
		})();
	}, [authData, t]);

	const handleChangeName = (e) => {
		setOrganization({ ...organization, name: e.target.value });
		if (errors.name) setErrors({ ...errors, name: "" });
	};

	const handleChangeStreet = (e) => {
		setOrganization({ ...organization, street: e.target.value });
	};

	const handleChangeZipCode = (e) => {
		setOrganization({ ...organization, zip_code: e.target.value });
	};

	const handleChangeCity = (e) => {
		setOrganization({ ...organization, city: e.target.value });
	};

	const handleChangeState = (value) => {
		if (value === "") value = { value: null, label: null };

		setOrganization({
			...organization,
			state_id: value.value,
			state_name: value.label,
		});
	};

	const handleChangeGPSLat = (e) => {
		setOrganization({ ...organization, gps_lat: Number(e.target.value) });
	};

	const handleUpdateCoords = () => {
		if (mapRef) {
			console.log(mapRef.center, mapRef.center.lng());
			setOrganization({
				...organization,
				gps_lat: mapRef.center.lat(),
				gps_lng: mapRef.center.lng(),
			});
		}
	};

	const handleChangeComment = (e) => {
		setOrganization({ ...organization, comment: e.target.value });
	};

	const validateData = () => {
		const errors = {};

		if (!organization.name.trim()) {
			errors.name = t("Organization name is required");
		}

		return errors;
	};

	const handleSubmit = () => {
		const validationErrors = validateData();

		console.log(organization);

		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
			return;
		}

		if (typeof onSubmit === "function") onSubmit(organization);
		setOrganization(null);
		onClose();
	};

	// table - columns

	const columns = useMemo(
		() =>
			[
				/*{
					Header: t("ID"),
					accessor: "fish_age_id",
					cssClass: "text-center min-cell-width",
				},*/
				{
					Header: t("Profile name"),
					accessor: "user_name",
					cssClass: "w-1/2",
					allowEdit: true,
					dataSource: profiles.map((organization) => ({
						value: organization.id,
						label: organization.name,
					})),
				},
				{
					Header: t("Position"),
					accessor: "function",
					cssClass: "w-[calc(50%-1rem)]",
					allowEdit: true,
				},
			].filter((item) => item !== false),
		[profiles, t],
	);

	useEffect(() => {
		const handleHashChange = () => {
			setActiveLink(window.location.hash);
		};

		window.addEventListener("hashchange", handleHashChange);

		// Cleanup function to remove the event listener when component unmounts
		return () => {
			window.removeEventListener("hashchange", handleHashChange);
		};
	}, []);

	/*
	const handleScroll = (event) => {
		const sections = event.target.querySelectorAll("section");

		let currentSectionId = "";
		sections.forEach((section) => {
			if (event.target.scrollTop >= section.offsetTop - 200) {
				currentSectionId = `#${section.id}`;
			}
		});

		setActiveLink(currentSectionId);

		event.target.addEventListener("scroll", handleScroll);

		// Cleanup function to remove the event listener when component unmounts
		return () => {
			event.target.removeEventListener("scroll", handleScroll);
		};
	};
	*/

	return (
		<Modal
			className="w-3/4 h-5/6 min-w-[1024px]"
			title={title}
			onClose={onClose}
			onSubmit={onSubmit}
		>
			<div className="organization-modal h-full -mx-4 flex">
				<div className="left-side h-full basis-1/2 pl-4 grid grid-cols-[min-content,1fr] gap-8">
					<p className="pr-8 m-0 col-span-2 text-sm">{description}</p>
					<div className="links -mt-3 text-sm">
						<ul className="flex flex-col leading-none text-primary">
							<li>
								<a
									href="#general"
									className={`block p-4 py-3 rounded hover:bg-neutral-200/50 font-light ${
										activeLink === "#general" ? "text-primary" : "text-black"
									}`}
								>
									{t("General information")}
								</a>
							</li>
							<li>
								<a
									href="#profiles"
									className={`block p-4 py-3 rounded hover:bg-neutral-200/50 font-light ${
										activeLink === "#profiles" ? "text-primary" : "text-black"
									}`}
								>
									{t("Contacts")}
								</a>
							</li>
							<li>
								<a
									href="#water-bodies"
									className={`block p-4 py-3 rounded hover:bg-neutral-200/50 font-light ${
										activeLink === "#water-bodies"
											? "text-primary"
											: "text-black"
									}`}
								>
									{t("Water bodies")}
								</a>
							</li>
							<li>
								<a
									href="#comments"
									className={`block p-4 py-3 rounded hover:bg-neutral-200/50 font-light ${
										activeLink === "#comments" ? "text-primary" : "text-black"
									}`}
								>
									{t("Comments")}
								</a>
							</li>
						</ul>
					</div>
					<form
						className="max-h-full pr-8 pl-[1px] flex flex-col gap-8 overflow-y-scroll scroll-smooth"
						tabIndex="-1"
						onSubmit={handleSubmit}
					>
						<fieldset id="general" className="flex flex-col gap-10">
							<legend className="pl-3 mb-6 font-light leading-none text-primary">
								{t("General information")}
							</legend>
							<Input
								id="organization-name"
								label={t("Name")}
								type="text"
								name="organization-name"
								value={organization.name}
								onChange={handleChangeName}
								autoFocus
								required
							/>
							<ErrorHint message={errors.name} />

							<Input
								id="street"
								label={t("Address")}
								type="text"
								name="organization-street"
								value={organization.street}
								onChange={handleChangeStreet}
							/>

							<div className="flex gap-4">
								<Input
									id="zip-code"
									className="w-auto flex-1 whitespace-nowrap min-w-20"
									label={t("ZIP Code")}
									type="text"
									name="organization-zip-code"
									value={organization.zip_code}
									onChange={handleChangeZipCode}
								/>
								<Input
									id="city"
									label={t("City")}
									type="text"
									name="organization-city"
									value={organization.city}
									onChange={handleChangeCity}
								/>
							</div>
							<Select
								label={t("State")}
								options={states}
								value={{
									label: organization.state_name,
									value: organization.state_id,
								}}
								allowNone={true}
								onChange={handleChangeState}
							/>
							<div className="flex gap-1">
								<Input
									id="gps-lat"
									label={t("GPS Lat")}
									name="gps-lat"
									value={organization.gps_lat}
									onChange={handleChangeGPSLat}
								/>
								<Input
									id="gps-lng"
									label={t("GPS Lng")}
									name="gps-lng"
									value={organization.gps_lng}
									onChange={(e) =>
										setOrganization({
											...organization,
											gps_lng: Number(e.target.value),
										})
									}
								/>
								<Button
									className="btn shrink w-10 min-w-10 bg-transparent"
									title={t("Update coordinates")}
									onClick={handleUpdateCoords}
								>
									<i className="ri-loop-left-line"></i>
								</Button>
							</div>
						</fieldset>
						<fieldset id="profiles" className="flex flex-col gap-8">
							<legend className="pl-3 mb-6 font-light leading-none text-primary">
								{t("Contacts")}
							</legend>
							<ul className="">
								{organization.profiles && (
									<>
										<SortableList
											className="h-52"
											data={organization.profiles}
											columns={columns}
											allowEdit={false}
											onChange={(profiles) => {
												setOrganization({
													...organization,
													profiles: profiles,
												});
											}}
										/>
									</>
								)}
							</ul>
						</fieldset>
						<fieldset id="water-bodies" className="flex flex-col gap-8">
							<legend className="pl-3 mb-6 font-light leading-none text-primary">
								{t("Water bodies")}
							</legend>
							<ul className="">
								{organization.profiles && (
									<>
										Noch nicht implementiert
										{/*<SortableList
											className="h-52"
											data={organization.profiles}
											columns={columns}
											allowEdit={false}
											onChange={(profiles) => {
												setOrganization({
													...organization,
													profiles: profiles,
												});
											}}
										/>*/}
									</>
								)}
							</ul>
						</fieldset>
						<fieldset id="comments" className="flex flex-col gap-8">
							<legend className="pl-3 mb-6 font-light leading-none text-primary">
								{t("Comments")}
							</legend>
							<Textarea
								id="comment"
								label={t("Additional information")}
								name="comment"
								value={organization.comment}
								onChange={handleChangeComment}
							/>
						</fieldset>
					</form>

					<ContentMeta data={organization} />

					<div className="pr-8 col-start-2 flex gap-4">
						<Button className="btn-primary" onClick={handleSubmit}>
							{t("Save")}
						</Button>
						<Button className="btn" onClick={onClose}>
							{t("Cancel")}
						</Button>
					</div>
				</div>
				<div className="right-side h-auto basis-1/2 relative -my-6">
					<GoogleMaps
						center={getCenter(organization)}
						zoom={getZoom(organization)}
						markers={getMarker(organization)}
						onLoad={(map) => setMapRef(map)}
						onMapClick={(event) =>
							console.log(
								"hoi",
								event.map.getCenter().lat(),
								event.map.getCenter().lng(),
							)
						}
					/>
					<div className="absolute top-1/2 left-1/2 text-red-500 text-2xl translate-x-[-50%] translate-y-[-50%]">
						<i className="ri-add-line"></i>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default EditOrganizationModal;
