import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { getFishTypes } from "api/fishTypes";
import { getFishOrigins } from "api/fishOrigins";
// import { getWaters } from "api/waters";
import { getLocations } from "api/locations";

import Modal from "components/Modal/Modal";
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import Textarea from "components/Textarea/Textarea";
import Button from "components/Button/Button";
import ContentMeta from "components/ContentMeta/ContentMeta";

const EditFishReturnerModal = ({ data, onClose, onSubmit }) => {
	const { t } = useTranslation();
	const [record, setRecord] = useState(
		data || {
			id: "",
			year: "",
			fish_type_id: "",
			fish_type_name: "",
			fish_age_id: "",
			fish_age_name: "",
			count: "",
			weight: "",
			location: "",
			source: "",
			comments: "",
		},
	);
	const [fishTypes, setFishTypes] = useState([]);
	const [fishStadiums, setFishStadiums] = useState([]);
	const [fishOrigins, setFishOrigins] = useState([]);
	// const [waters, setWaters] = useState([]);
	const [locations, setLocations] = useState([]);

	const title = record.id
		? t("Edit returner record")
		: t("Create new returner record");
	const description =
		(record.id
			? t("You can update the details here.")
			: t("Fill in the details.")) +
		t(
			"\nTo make the changes permanent, click the Save button. To discard the changes, close the window.",
		);

	const handleSubmit = () => {
		if (typeof onSubmit === "function") onSubmit(record);
		setRecord(null);
		onClose();
	};

	// get all fish types

	useEffect(() => {
		(async () => {
			const data = await getFishTypes();
			if (data.code === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.data.detail));
				console.log(data);
			} else {
				const fishTypes = data.map((obj) => ({
					value: obj.id,
					label: obj.name,
					stadiums: obj.stadiums,
				}));
				setFishTypes(fishTypes);
			}
		})();
	}, [t]);

	// set the stadiums based on the selected fish type

	useEffect(() => {
		const selectedFishType = fishTypes.find(
			(fishType) => fishType.value === record.fish_type_id,
		);
		setFishStadiums(
			selectedFishType &&
				selectedFishType.stadiums.map((stadium) => ({
					value: stadium.fish_age_id,
					label: stadium.fish_age_name,
				})),
		);
	}, [record, fishTypes]);

	// get all fish origins (Stämme)

	useEffect(() => {
		(async () => {
			const data = await getFishOrigins();
			if (data.code === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.data.detail));
				console.log(data);
			} else {
				const fishOrigins = data.map((obj) => ({
					value: obj.id,
					label: obj.name,
				}));
				setFishOrigins(fishOrigins);
			}
		})();
	}, [t]);

	// get all waters
	/*
	useEffect(() => {
		(async () => {
			const data = await getWaters();

			if (data.code === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.data.detail));
				console.log(data);
			} else {
				const rivers = data.map((obj) => ({
					value: obj.id,
					label: obj.name,
				}));
				setWaters(rivers);
			}
		})();
	}, [t]);
	*/
	// get all locations

	useEffect(() => {
		(async () => {
			const data = await getLocations();
			if (data.code === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.data.detail));
				console.log(data);
			} else {
				const locations = data.map((obj) => ({
					value: obj.id,
					label: obj.name,
				}));
				setLocations(locations);
			}
		})();
	}, [t]);

	return (
		<Modal
			className="w-[800px]"
			title={title}
			onClose={onClose}
			onSubmit={onSubmit}
		>
			<form className="flex flex-col gap-8 w-full" onSubmit={handleSubmit}>
				<p className="m-0 text-sm whitespace-pre-line">{description}</p>
				<div className="flex gap-16">
					<fieldset className="basis-1/2 flex-1 flex flex-col gap-8">
						<legend className="pl-3 mb-6 font-light leading-none text-primary">
							{t("General information")}
						</legend>
						<Input
							id="record-year"
							className="w-1/2"
							label={t("Year")}
							type="text"
							name="record-year"
							value={record.year}
							onChange={(e) => setRecord({ ...record, year: e.target.value })}
							autoFocus
							required
						/>
						<Select
							label={t("Fish type")}
							options={fishTypes}
							value={{
								label: record.fish_type_name,
								value: record.fish_type_id,
							}}
							allowNone={false}
							onChange={(value) =>
								setRecord({
									...record,
									fish_type_id: value.value,
									fish_type_name: value.label,
								})
							}
							required
						/>
						<Select
							label={t("Fish age")}
							options={fishStadiums}
							value={
								fishStadiums &&
								fishStadiums.find(
									(stadium) => stadium.value === record.fish_age_id,
								)
							}
							allowNone={false}
							onChange={(value) =>
								setRecord({
									...record,
									fish_age_id: value.value,
									fish_age_name: value.label,
								})
							}
							required
						/>
						<Input
							id="record-count"
							className="w-1/2"
							label={t("Count")}
							type="text"
							name="record-count"
							value={record.count}
							onChange={(e) => setRecord({ ...record, count: e.target.value })}
						/>
						<Select
							label={t("Fish origin")}
							options={fishOrigins}
							value={{
								label: record.fish_origin_name,
								value: record.fish_origin_id,
							}}
							allowNone={true}
							onChange={(value) =>
								setRecord({
									...record,
									fish_origin_id: value.value,
									fish_origin_name: value.label,
								})
							}
						/>
						{/*<Select
							label={t("Water body")}
							options={waters}
							value={
								waters &&
								waters.find((location) => location.value === record.city_id)
							}
							allowNone={false}
							onChange={(value) =>
								setRecord({
									...record,
									fish_location_id: value.value,
									fish_location_name: value.label,
								})
							}
							required
						/>*/}
						<Select
							label={t("Location")}
							options={locations}
							value={
								locations &&
								locations.find((location) => location.value === record.city_id)
							}
							allowNone={false}
							onChange={(value) =>
								setRecord({
									...record,
									city_id: value.value,
									city_name: value.label,
								})
							}
							required
						/>
					</fieldset>
					<fieldset className="basis-1/2 flex-1 flex flex-col gap-8">
						<legend className="pl-3 mb-6 font-light leading-none text-primary">
							{t("Misc")}
						</legend>
						<Input
							id="record-source"
							label={t("Source")}
							type="text"
							name="record-source"
							value={record.source}
							onChange={(e) => setRecord({ ...record, source: e.target.value })}
						/>
						<Textarea
							id="comment"
							label={t("Comments")}
							name="record-comment"
							value={record.comment}
							onChange={(e) =>
								setRecord({ ...record, comment: e.target.value })
							}
						/>

						<ContentMeta data={record} />
					</fieldset>
				</div>

				<div className="mt-4 flex gap-4 justify-center">
					<Button className="btn-primary" onClick={handleSubmit}>
						{t("Save")}
					</Button>
					<Button className="btn" onClick={onClose}>
						{t("Cancel")}
					</Button>
				</div>
			</form>
		</Modal>
	);
};

export default EditFishReturnerModal;
