import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="content-container relative flex-grow h-full px-16 overflow-scroll">
      <div className="content-wrapper max-w-prose">
        <h1>{t("About this interactive map")}</h1>
        <p>Die Wanderfische Internet Datenbank ist für jedermann zugänglich.</p>
        <p>
          Durch Anklicken der gewünschten Informationen wird auf der Karte
          jeweils ein Pin für jeden Eintrag dargestellt. Durch Doppelklick auf
          den Pin erhalten Sie die gewünschten Informationen auf der rechten
          Seite des Bildschirms.
        </p>
        <p>Probieren Sie es aus!</p>
        <p>
          Die Wanderfische Datenbank ist dynamisch aufgebaut und wird ständig
          aktualisiert.
        </p>
        <p>
          Die Vertreter der Wanderfisch Initiativen speisen ihre Daten (Besatz,
          Rückkehrer, Wanderhindernisse und historische Daten) direkt in die
          Datenbank ein.
        </p>
        <p>
          Es ist möglich, dass die Datenbank noch nicht mit allen relevanten
          Informationen versehen ist, da sie sich noch im Aufbau befindet.
        </p>
        <p>
          Bitte lassen Sie uns wissen, wenn Ihnen auffällt, dass etwas fehlt
          oder nicht korrekt ist. Wenn Sie selbst Daten beisteuern können,
          nehmen Sie bitte Kontakt zu unserem Datenbank Administrator auf{" "}
          <NavLink to="mailto:Stefan.Ludwig@wanderfische.eu">
            Stefan.Ludwig(at)wanderfische.eu
          </NavLink>
          .
        </p>
        <p>
          Wir würden uns freuen, wenn Sie die Datenbank mit Ihren Daten
          bereichern und besser machen können.
        </p>
      </div>
    </div>
  );
};

export default About;
