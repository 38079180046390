import { useMemo, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useAuth } from "context/AuthContext";
import { useError } from "context/ErrorContext";

import {
  getLocations,
  createLocation,
  updateLocation,
  deleteLocation,
} from "api/locations";

import { Table, Actions } from "components/Table/Table";
import EditLocationModal from "components/EditLocationModal";
import ConfirmModal from "components/ConfirmModal";

import { getDate } from "utils/utils";

/**
 * ORTE
 *
 * /locations
 *
 */

const Locations = () => {
  const { authData } = useAuth();
  const { showError } = useError();
  const { t } = useTranslation();

  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);

  const handleRowEdit = (data) => {
    setLocation(data);
    setShowEditModal(true);
  };

  const handleRowDelete = (data) => {
    setLocation(data);
    setShowConfirmDeleteModal(true);
  };

  const handleSubmitEdit = async (data) => {
    if (location) {
      const response = await updateLocation({
        token: authData?.access_token,
        ...data,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        console.log(response);
      } else {
        toast.success(t("Location updated!"));
        setRefreshTable(true);
      }
    } else {
      const response = await createLocation({
        token: authData?.access_token,
        ...data,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        console.log(response);
      } else {
        toast.success(t("Location created!"));
        setRefreshTable(true);
      }
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setShowConfirmDeleteModal(false);

      const response = await deleteLocation({
        token: authData.access_token,
        id: location.id,
      });

      if (response.status === 200 || response.status === 204) {
        setLocation(null);
        setRefreshTable(true);
        toast.success(t("Location deleted!"));
      }
    } catch (error) {
      console.error("Error deleting location:", error);
      showError(error.response.data.detail);
    }
  };

  // table - row actions

  const actions = useMemo(
    () => [
      {
        name: "edit",
        icon: "ri-pencil-line",
        command: handleRowEdit,
        title: t("Edit"),
      },
      {
        name: "delete",
        icon: "ri-delete-bin-line",
        command: handleRowDelete,
        title: t("Delete"),
      },
    ],
    [t],
  );

  // table - columns

  const columns = useMemo(
    () =>
      [
        {
          Header: t("Location"),
          accessor: "name",
          cssClass: "w-[15%] whitespace-nowrap overflow-hidden text-ellipsis",
        },
        {
          Header: t("Water body"),
          accessor: "river_name",
          cssClass: "w-[15%] whitespace-nowrap overflow-hidden text-ellipsis",
        },
        {
          Header: t("Region"),
          accessor: "region_name",
          cssClass: "w-[10%] whitespace-nowrap overflow-hidden text-ellipsis",
        },
        {
          Header: t("Area"),
          accessor: "region_area_name",
          cssClass: "w-[10%] whitespace-nowrap",
        },
        {
          Header: t("Country"),
          accessor: "country_name",
          cssClass: "w-[10%] whitespace-nowrap overflow-hidden text-ellipsis",
        },
        /*
        {
          Header: t("GPS Lat"),
          accessor: "gps_lat",
          cssClass: "w-[10%]",
          Cell: ({ value, row }) => value?.toFixed(5),
        },
        {
          Header: t("GPS Lng"),
          accessor: "gps_lng",
          cssClass: "w-[10%] max-w-[14ch] overflow-hidden text-ellipsis",
          Cell: ({ value, row }) => value?.toFixed(5),
        },
        */
        {
          Header: t("Created by"),
          accessor: "create_user_name",
          cssClass: "w-auto overflow-hidden whitespace-nowrap text-ellipsis",
        },
        {
          Header: t("Created on"),
          accessor: "create_date",
          cssClass: "w-min text-center whitespace-nowrap",
          Cell: ({ cell: { value } }) => getDate(value),
        },
        {
          Header: t("Updated on"),
          accessor: "modify_date",
          cssClass: "w-min text-center whitespace-nowrap",
          Cell: ({ cell: { value } }) => getDate(value),
        },
        actions.length > 0 && {
          Header: t("Actions"),
          accessor: "accessor",
          cssClass: "w-[7rem] text-center",
          disableSortBy: true,
          Cell: ({ row: { original } }) => (
            <Actions row={original} actions={actions} />
          ),
        },
      ].filter((item) => item !== false),
    [actions, t],
  );

  // get all locations

  useEffect(() => {
    (async () => {
      const data = await getLocations();
      if (data.code === "ERR_BAD_REQUEST") {
        toast.error(t(data.response.data.detail));
        console.log(data);
      } else {
        setLocations(data);
        setRefreshTable(false);
      }
    })();
  }, [refreshTable, t]);

  return (
    <>
      <div className="locations-container relative flex-grow h-full px-16 overflow-scroll">
        <div className="content-wrapper max-h-full flex flex-col">
          <h1 className="flex items-center">
            {t("Locations")}
            <span className="ml-4 text-sm text-secondary">
              <NavLink to="/dashboard">{t("Back to Dashboard")}</NavLink>
            </span>
          </h1>
          <p className="max-w-prose">
            Sed turpis tincidunt id aliquet. Feugiat sed lectus vestibulum
            mattis ullamcorper velit sed ullamcorper morbi. Facilisis mauris sit
            amet massa vitae tortor condimentum lacinia quis. Elementum
            curabitur vitae nunc sed velit dignissim.
          </p>
          <Table
            className="max-h-full max-w-full table-fixed"
            columns={columns}
            data={locations}
            newAction={() => {
              setLocation(null);
              setShowEditModal(true);
            }}
            newActionLabel={t("Create new location")}
            searchLabel={t("Search by location")}
            searchColumns={["name"]}
            onRowClick={(row) => handleRowEdit(row.original)}
          />
        </div>
      </div>

      {showEditModal && (
        <EditLocationModal
          data={location}
          onClose={() => setShowEditModal(false)}
          onSubmit={(data) => handleSubmitEdit(data)}
        />
      )}

      {showConfirmDeleteModal && (
        <ConfirmModal
          title={t("Delete location?")}
          message=<span>
            {t("Are you sure you want to delete the location {{name}}?", {
              name: `${location.name}`,
            })}
          </span>
          yesMessage={t("Delete")}
          destructive={true}
          onClose={() => {
            setLocation(null);
            setShowConfirmDeleteModal(false);
          }}
          onSubmit={handleConfirmDelete}
        />
      )}
    </>
  );
};

export default Locations;
