import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  // Load auth data from local storage on mount
  useEffect(() => {
    const savedAuthData = JSON.parse(localStorage.getItem("authData"));

    if (savedAuthData) {
      const isExpired =
        parseJwt(savedAuthData.access_token)?.exp <
        Math.floor(Date.now() / 1000);

      if (!isExpired) {
        setAuthData(savedAuthData);
      } else {
        localStorage.removeItem("authData");
        navigate("/login");
      }
    }

    setIsLoading(false);
  }, [location, navigate]);

  // Save auth data to local storage whenever it changes
  useEffect(() => {
    if (authData) {
      localStorage.setItem("authData", JSON.stringify(authData));
    } else {
      localStorage.removeItem("authData");
    }
  }, [authData]);

  const login = (token) => {
    setAuthData({ token });
  };

  const logout = () => {
    setAuthData(null);
    localStorage.removeItem("authData");
  };

  return (
    <AuthContext.Provider
      value={{ authData, setAuthData, login, logout, isLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
