import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Impressum = () => {
  const { t } = useTranslation();

  return (
    <div className="content-container relative flex-grow h-full px-16 overflow-scroll">
      <div className="content-wrapper max-w-prose">
        <h1>{t("Impressum")}</h1>
        <h2>Rechtsform, Register</h2>
        <p>
          "Wanderfische ohne Grenzen - NASF Deutschland e.V." ist ein Verein im
          Sinne der §§ 21 ff. BGB
        </p>
        <p>
          Der Verein ist eingetragen beim Amtsgericht Stuttgart im
          Vereinsregister 721829.
        </p>
        <p>
          Bundesweit anerkannter Naturschutzverband nach §3
          Umwelt-Rechtsbehelfsgesetz (UmwRG)
        </p>
        <h2>Betreiber und Kontakt</h2>
        <p>
          Diese Internetseite wird betrieben vom Verein "Wanderfische ohne
          Grenzen - NASF Deutschland e.V." mit Sitz in Johannes-Giesser-Strasse
          8, 71364 Winnenden.
          <br />
          E-Mail:{" "}
          <NavLink to="mailto:mail@wanderfische.eu">
            mail(at)wanderfische.eu
          </NavLink>
        </p>
        <h2>Vertretung</h2>
        <p>Armin Weinbrenner, 1. Vorsitzender des Vereins</p>
        <h2>
          Verantwortlicher für journalistisch-redaktionelle Inhalte gem. § 55 II
          RstV
        </h2>
        <p>Armin Weinbrenner</p>
        <h2>Fotos</h2>
        <p>
          Hans van Klinken, Daniel Götz, fotolia, guillaumeleBlloas, Stefan
          Ludwig, Manfred Raguse, Armin Weinbrenner
        </p>
        <h2>Webdesign und Realisation</h2>
        <p>
          <NavLink to="https://remotedots.com">remotedots.com</NavLink>
        </p>
        <h2>Hinweis</h2>
        <p>
          Mit Urteil des Landgerichts Hamburg vom 12. Mai 1998 wurde
          entschieden, dass ein Internetbetreiber durch Anbringung eines Links
          die Inhalte der verlinkten Seite ggf. mit zu verantworten hat. Das
          kann nur dadurch verhindert werden, dass sich der Internetbetreiber
          ausdrücklich von diesen Inhalten distanziert. Sofern der Verein
          "Wanderfische ohne Grenzen" derartige Verlinkungen vornimmt, erklärt
          dieser, dass er keinerlei Einfluss auf die Gestaltung und die Inhalte
          der verlinkten Seiten hat. "Wanderfische ohne Grenzen" distanziert
          sich hiermit ausdrücklich von allen Inhalten aller verlinkten Seiten
          auf dieser Homepage und macht sich ihre Inhalte nicht zu Eigen. Diese
          Erklärung gilt für alle auf dieser Homepage angebrachten Links.
        </p>
      </div>
    </div>
  );
};

export default Impressum;
