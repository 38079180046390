import { OrganizationDetails } from "./OrganizationDetails";
import { StockingDetails } from "./StockingDetails";
import { ReturnersDetails } from "./ReturnersDetails";
import { HistoryDetails } from "./HistoryDetails";

import { useTranslation } from "react-i18next";

import { ReactComponent as OrganizationMarker } from "assets/icons/marker-organization.svg";
import { ReactComponent as StockingMarker } from "assets/icons/marker-stocking.svg";
import { ReactComponent as ReturnersMarker } from "assets/icons/marker-returners.svg";
import { ReactComponent as HistoryMarker } from "assets/icons/marker-history.svg";

import "./Details.scss";

export const Details = ({ data, onClose }) => {
  const { t } = useTranslation();

  if (!data) return;

  const getWindowWidth = () => {
    switch (data.type) {
      case "organization":
        return "w-1/2";
      case "history":
        return "w-full";
      default:
        return "w-auto";
    }
  };

  const getIcon = (data) => {
    switch (data.type) {
      case "organization":
        return <OrganizationMarker />;
      case "stocking":
        return <StockingMarker />;
      case "returners":
        return <ReturnersMarker />;
      case "history":
        return <HistoryMarker />;
      default:
    }
  };

  const renderContent = () => {
    switch (data.type) {
      case "organization":
        return <OrganizationDetails data={data.data} />;
      case "stocking":
        return <StockingDetails data={data.data} />;
      case "returners":
        return <ReturnersDetails data={data.data} />;
      case "history":
        return <HistoryDetails data={data.data} />;
      default:
        console.log(data.data);
    }
  };

  return (
    <div
      className={`details-window ${getWindowWidth()} min-w-[50%] max-w-[1200px] max-h-80 py-6 pl-4 pr-4 flex flex-col gap-8 bg-white rounded-sm shadow absolute bottom-6 left-1/2 -translate-x-[calc(50%+40px)]`}
    >
      <button
        className="btn-close hover:text-black top-[6px] right-1"
        onClick={onClose}
        title={t("Close")}
      >
        <i className="ri-close-line text-xl"></i>
      </button>
      <div className="modal-header flex gap-2">
        <div className="icon w-[24px]">{getIcon(data)}</div>
        <div>
          <h2 className="text-xl font-medium">{data.title}</h2>
          <p className="subtitle text-sm -mt-[4px] font-normal text-gray-500">
            {t(data.typeSlug)}
          </p>
        </div>
      </div>
      <div className="modal-content h-full max-w-full max-h-full pb-2 overflow-scroll empty:hidden">
        {renderContent()}
      </div>
    </div>
  );
};
