export const getDate = (inputDate) => {
  const date = new Date(inputDate);

  if (date.getTime() === new Date("1970-01-01").getTime()) return "-";

  if (isNaN(date)) {
    return "Invalid date format";
    // throw new Error("Invalid date");
  }

  var dateStr = date.toLocaleString(["de-DE"], {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  return dateStr;
};
