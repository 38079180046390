import { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal/Modal";
import Input from "components/Input/Input";
import Button from "components/Button/Button";

const EditFishOriginModal = ({ data, onClose, onSubmit }) => {
	const { t } = useTranslation();
	const [fishOrigin, setFishOrigin] = useState(data || { id: "", name: "" });

	const title = fishOrigin.id
		? t("Edit fish origin")
		: t("Create new fish origin");
	const description = fishOrigin.id
		? t("You can change the name of the fish origin.")
		: t("Fill in the name of the fish origin.");

	const handleSubmit = () => {
		if (typeof onSubmit === "function") onSubmit(fishOrigin);
		setFishOrigin(null);
		onClose();
	};

	return (
		<Modal title={title} onClose={onClose} onSubmit={onSubmit}>
			<form className="flex flex-col gap-8 w-full" onSubmit={handleSubmit}>
				<p className="m-0 text-sm">{description}</p>
				<Input
					id="fish-origin-name"
					label={t("Name")}
					type="text"
					name="fish-origin-name"
					value={fishOrigin.name}
					onChange={(e) =>
						setFishOrigin({ ...fishOrigin, name: e.target.value })
					}
					autoFocus
					required
				/>
				<div className="mt-4 flex gap-4 justify-center">
					<Button className="btn-primary" onClick={handleSubmit}>
						{t("Save")}
					</Button>
					<Button className="btn" onClick={onClose}>
						{t("Cancel")}
					</Button>
				</div>
			</form>
		</Modal>
	);
};

export default EditFishOriginModal;
