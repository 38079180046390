import Select from "components/Select/Select";

const SortableListSelect = ({
  value,
  options,
  editMode,
  onBlur,
  onChange,
  ...props
}) => {
  //const selectedOption = options.find((option) => option.label === value);

  return (
    <>
      {editMode ? (
        <Select
          className="editable-select inline-block w-full"
          value={value}
          options={options}
          onBlur={onBlur}
          onChange={(value) => onChange && onChange(value)}
          autoFocus={true}
          {...props}
        />
      ) : (
        <div className="editable-select value inline-block w-full">
          <span
            title={value?.label}
            className="inline-block max-w-full whitespace-nowrap overflow-hidden overflow-ellipsis"
          >
            {value?.label}
            <i className="ri-pencil-line ml-2 leading-none" />
          </span>
        </div>
      )}
    </>
  );
};

export default SortableListSelect;
