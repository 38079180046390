import { useState } from "react";
import { Outlet, NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer, cssTransition } from "react-toastify";

import { useConsent } from "context/ConsentContext";
import { useAuth } from "context/AuthContext";

// import { useUser } from "context/UserContext";

import LanguageSwitch from "components/LanguageSwitch/LanguageSwitch";
import Button from "components/Button/Button";
import Sidebar from "components/Sidebar/Sidebar";
import CookieConsentModal from "components/CookieConsentModal";
import ConfirmModal from "components/ConfirmModal";

import "react-toastify/dist/ReactToastify.css";

const MainLayout = () => {
  const { showConsentModal, setShowConsentModal } = useConsent();
  const { authData, logout } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const { canI } = useUser();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navLinks = [];

  const toastySlide = cssTransition({
    enter: "slide-top",
    exit: "slide-top-hide",
  });

  const languages = [
    { name: "DE", code: "de" },
    { name: "EN", code: "en" },
  ];

  // if (canI("view", "Dashboard"))
  navLinks.push({
    path: "/",
    caption: t("Home"),
    icon: "ri-map-2-line",
  });

  // if (canI("view", "Classes"))
  navLinks.push({
    path: "/about",
    caption: t("About"),
    icon: "ri-question-line",
  });

  // if (canI("view", "Patients"))
  navLinks.push({
    path: "/contact",
    caption: t("Contact"),
    icon: "ri-mail-line",
  });

  /*
  navLinks.push({
    path: "https://wanderfische.eu/",
    caption: t("wanderfische.eu"),
    icon: "ri-external-link-line",
  });
  */

  //if (canI("view", "Users")) {
  navLinks.push({ caption: "_separator_" });
  //}

  if (authData)
    navLinks.push({
      path: "/dashboard",
      caption: t("Dashboard"),
      icon: "ri-dashboard-2-line",
    });

  navLinks.push({
    path: "/impressum",
    caption: t("Impressum"),
    icon: "ri-information-line",
    className: "nav-secondary mt-auto text-sm",
  });
  navLinks.push({
    path: "/terms",
    caption: t("Terms and Conditions"),
    icon: "ri-book-open-line",
    className: "nav-secondary text-sm",
  });
  navLinks.push({
    path: "/privacy",
    caption: t("Privacy"),
    icon: "ri-lock-line",
    className: "nav-secondary text-sm",
  });
  navLinks.push({
    action: () => setShowConsentModal(true),
    caption: t("Cookies"),
    icon: "ri-cake-3-line",
    className: "nav-secondary text-sm",
  });

  //if (canI("view", "Settings"))

  /*
  navLinks.push({
    path: "/profile",
    caption: t("My profile"),
    icon: "ri-account-circle-line",
    className: "mt-auto",
  });
  */

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        transition={toastySlide}
      />
      <header className="p-4 flex gap-4 flex-wrap items-baseline border-b border-1 border-neutral-300">
        <h2 className="text-xl w-full sm:w-auto">
          <NavLink to="/">
            {t("Wanderfische ohne Grenzen - NASF Deutschland e.V.")}
          </NavLink>
        </h2>
        <LanguageSwitch
          className="mx-0 sm:ml-auto mr-auto sm:mr-4"
          languages={languages}
        />
        {authData && (
          <Button
            className="btn btn-primary py-1"
            onClick={() => setShowLogoutModal(true)}
          >
            {t("Log out")}
          </Button>
        )}
        {!authData && (
          <NavLink to="/login" className="btn btn-primary py-1">
            {t("Log in")}
          </NavLink>
        )}
      </header>
      <div className="flex-grow overflow-auto flex">
        <Sidebar navLinks={navLinks.filter((item) => item !== false)} />
        <main className="main-content flex-grow flex overflow-auto">
          <Outlet />
        </main>
      </div>

      {showConsentModal && (
        <CookieConsentModal onClose={() => setShowConsentModal(false)} />
      )}

      {showLogoutModal && (
        <ConfirmModal
          title={t("Log out?")}
          message={t("Are you sure you want to log out?")}
          yesMessage={t("Log out")}
          className="w-auto"
          onClose={() => setShowLogoutModal(false)}
          onSubmit={handleLogout}
        />
      )}
    </>
  );
};

export default MainLayout;
