import React, { useCallback, useState, useEffect } from "react";
import { useTable, useFilters, useGlobalFilter, useSortBy } from "react-table";
import { useTranslation } from "react-i18next";

import Input from "components/Input/Input";
import Button from "components/Button/Button";
import Select from "components/Select/Select";

import "./Table.scss";

export const Actions = ({ row, actions = [] }) => {
  return (
    <div className="actions-wrapper flex gap-2">
      {actions.map(function (action, index) {
        return (
          <Button
            key={index}
            className="action bg-transparent"
            onClick={() => action.command(row)}
            title={action.title}
          >
            <i className={action.icon}></i>
          </Button>
        );
      })}
    </div>
  );
};

export const Table = ({
  columns = null,
  data,
  className,
  searchLabel = "Search",
  searchField = null,
  searchColumns = null,
  searchFilter = null,
  searchGlobal = false,
  manualSortBy = false,
  newAction = "",
  newActionLabel = null,
  initialState,
  showSearch = true,
  isLoading = false,
  onRowClick,
  onSortingChange = null,
}) => {
  const [filterInput, setFilterInput] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const { t } = useTranslation();

  const classes = ["table", "text-sm"];
  if (className) classes.push(className);

  /**
   * define a customFilter function to include only specific columns
   * if no searchColumns defined, it will do a global search
   * using useCallback to cache the function definition between re-renders.
   * the search is case-insensitive
   */
  const customFilter = useCallback(
    (rows, columns, query) => {
      return rows.filter((row) => {
        return searchColumns
          ? searchColumns.some((column) => {
              return row.values[column]
                .toString()
                .toLowerCase()
                .includes(query.toLowerCase());
            })
          : Object.values(row.values).some((cell) => {
              return cell
                ?.toString()
                .toLowerCase()
                .includes(query.toLowerCase());
            });
      });
    },
    [searchColumns],
  );

  // Update the state when input changes
  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilterInput(value);
    if (searchColumns) {
      if (searchColumns.length === 1) setFilter(searchColumns[0], value);
      if (searchColumns?.length > 1) setGlobalFilter(value);
    } else setGlobalFilter(value);
  };

  const renderSortingArrows = (column) => {
    if (!column.canSort) return "";

    return column.isSorted ? (
      column.isSortedDesc ? (
        <i className="ri-arrow-up-s-line"></i>
      ) : (
        <i className="ri-arrow-down-s-line"></i>
      )
    ) : (
      <i className="ri-expand-up-down-line"></i>
    );
  };

  const handleRowClick = (event, row) => {
    if (onRowClick) {
      setSelectedRow(row.id);
      onRowClick(row);
    }
  };

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)

    setFilter, // The useFilter Hook provides a way to set the filter
    setGlobalFilter,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,

      initialState,
      globalFilter: searchFilter ? searchFilter : customFilter,
      manualSortBy: manualSortBy,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
  );

  useEffect(() => {
    onSortingChange && onSortingChange(sortBy);
  }, [sortBy, onSortingChange]);

  return (
    <>
      {(showSearch || newActionLabel) && (
        <div
          className={`search-wrapper flex items-center ${
            showSearch && "mt-8 mb-4"
          }`}
        >
          {showSearch && (
            <Input
              className="w-1/3"
              label={t(searchLabel)}
              value={filterInput || ""}
              onChange={handleFilterChange}
            />
          )}
          {newActionLabel && (
            <Button
              className={`btn-primary m-0 ml-auto ${
                newAction ? "" : "disabled"
              }`}
              onClick={newAction}
            >
              {t(newActionLabel)}
            </Button>
          )}
        </div>
      )}

      <div className="table-wrapper">
        <table className={classes.join(" ")} {...getTableProps()}>
          <thead className="bg-neutral-200">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    title={
                      column.canSort
                        ? `${t("Sort by")} ${column["Header"].toLowerCase()}`
                        : ""
                    }
                    className={
                      (column.isSorted
                        ? column.isSortedDesc
                          ? "sort-desc "
                          : "sort-asc "
                        : "") +
                      column.cssClass +
                      " px-3 py-2 font-medium last-of-type:pr-3"
                    }
                  >
                    {column.render("Header")}
                    {renderSortingArrows(column)}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className={isLoading ? "is-loading opacity-10 duration-100" : ""}
          >
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={`${row.id === selectedRow ? "selected " : ""}${
                    onRowClick ? "selectable" : ""
                  }`}
                  onClick={(e) => handleRowClick(e, row)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={
                          cell.column.cssClass + " px-3 py-2 last-of-type:pr-3"
                        }
                        title={cell.value}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export const BulkActions = ({ data, actions = [] }) => {
  return (
    <div className="bulk-actions-wrapper mt-4 flex gap-2">
      {actions.map(function (action, index) {
        return (
          <Button
            key={index}
            className="action btn-delete min-w-10 px-3 py-1 disabled:hidden"
            onClick={() => action.command()}
            title={action.title}
            disabled={data.length === 0}
          >
            {action.icon && <i className={`mr-2 ${action.icon}`}></i>}
            {action.title}
          </Button>
        );
      })}
    </div>
  );
};

export const Pagination = ({
  className = "",
  pageNumber,
  setPageNumber,
  pageCount,
  pageSize = 50,
  setPageSize,
  itemsCount,
}) => {
  const { t } = useTranslation();
  const pageSizes = [
    { value: 20, label: 20 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
    { value: 200, label: 200 },
  ];
  return (
    <div
      className={`pagination mb-4 px-3 flex gap-8 justify-end items-center text-sm font-light ${className}`}
    >
      <span className="mr-auto">
        {t("Displaying {{from}}-{{to}} of {{total}}", {
          from: (pageNumber - 1) * pageSize + 1,
          to: pageNumber * pageSize,
          total: itemsCount,
        })}
      </span>
      <div className="flex gap-2 items-center">
        <Button
          className="w-[32px] h-[32px] min-w-min p-1 bg-transparent disabled:text-gray-400 disabled:hover:bg-transparent"
          disabled={pageNumber === 1}
          onClick={() => setPageNumber(1)}
        >
          <i className="ri-arrow-left-double-line"></i>
        </Button>
        <Button
          className="w-[32px] h-[32px] min-w-min p-1 bg-transparent disabled:text-gray-400 disabled:hover:bg-transparent"
          disabled={pageNumber === 1}
          onClick={() => {
            pageNumber > 1 && setPageNumber(pageNumber - 1);
          }}
        >
          <i className="ri-arrow-left-s-line"></i>
        </Button>
        <span className="mx-4">
          {pageNumber} / {pageCount}
        </span>
        <Button
          className="w-[32px] h-[32px] min-w-min p-1 bg-transparent disabled:text-gray-400 disabled:hover:bg-transparent"
          disabled={pageNumber === pageCount}
          onClick={() => {
            pageNumber < pageCount && setPageNumber(pageNumber + 1);
          }}
        >
          <i className="ri-arrow-right-s-line"></i>
        </Button>
        <Button
          className="w-[32px] h-[32px] min-w-min p-1 bg-transparent disabled:text-gray-400 disabled:hover:bg-transparent"
          disabled={pageNumber === pageCount}
          onClick={() => setPageNumber(pageCount)}
        >
          <i className="ri-arrow-right-double-line"></i>
        </Button>
      </div>
      <span className="ml-auto">
        {t("Rows")}
        <Select
          className="min-w-min w-[5rem] ml-2 inline-block"
          value={pageSizes.find((ps) => ps.value === pageSize)}
          options={pageSizes}
          allowNone={false}
          onChange={(value) => setPageSize(value.value)}
        />
      </span>
    </div>
  );
};
