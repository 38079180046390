import { useMemo, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useAuth } from "context/AuthContext";
import { useError } from "context/ErrorContext";

import { getAreas, createArea, updateArea, deleteArea } from "api/areas";

import { Table, Actions } from "components/Table/Table";
import EditAreaModal from "components/EditAreaModal";
import ConfirmModal from "components/ConfirmModal";

/**
 * FLUSSGEBIETE
 *
 * /areas
 *
 */

const Areas = () => {
  const { authData } = useAuth();
  const { showError } = useError();
  const { t } = useTranslation();

  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);

  const handleRowEdit = (data) => {
    setArea(data);
    setShowEditModal(true);
  };

  const handleRowDelete = (data) => {
    setArea(data);
    setShowConfirmDeleteModal(true);
  };

  const handleSubmitEdit = async (data) => {
    if (area) {
      const response = await updateArea({
        token: authData?.access_token,
        ...data,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        console.log(response);
      } else {
        toast.success(t("Area updated!"));
        setRefreshTable(true);
      }
    } else {
      const response = await createArea({
        token: authData?.access_token,
        ...data,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        console.log(response);
      } else {
        toast.success(t("Area created!"));
        setRefreshTable(true);
      }
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setShowConfirmDeleteModal(false);

      const response = await deleteArea({
        token: authData.access_token,
        id: area.id,
      });

      if (response.status === 200 || response.status === 204) {
        setArea(null);
        setRefreshTable(true);
        toast.success(t("Area deleted!"));
      }
    } catch (error) {
      console.error("Error deleting area:", error);
      showError(error.response.data.detail);
    }
  };

  // table - row actions

  const actions = useMemo(
    () => [
      {
        name: "edit",
        icon: "ri-pencil-line",
        command: handleRowEdit,
        title: t("Edit"),
      },
      {
        name: "delete",
        icon: "ri-delete-bin-line",
        command: handleRowDelete,
        title: t("Delete"),
      },
    ],
    [t],
  );

  // table - columns

  const columns = useMemo(
    () =>
      [
        /* {
          Header: t("ID"),
          accessor: "id",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) => addLeadingZeros(value, 5),
        }, */
        {
          Header: t("Area"),
          accessor: "name",
        },
        actions.length > 0 && {
          Header: t("Actions"),
          accessor: "accessor",
          cssClass: "text-center min-cell-width",
          disableSortBy: true,
          Cell: ({ row: { original } }) => (
            <Actions row={original} actions={actions} />
          ),
        },
      ].filter((item) => item !== false),
    [actions, t],
  );

  // get all areas

  useEffect(() => {
    (async () => {
      const data = await getAreas();
      if (data.code === "ERR_BAD_REQUEST") {
        toast.error(t(data.response.data.detail));
        console.log(data);
      } else {
        setAreas(data);
        setRefreshTable(false);
      }
    })();
  }, [refreshTable, t]);

  return (
    <>
      <div className="areas-container relative flex-grow h-full px-16 overflow-auto">
        <div className="content-wrapper max-h-full flex flex-col">
          <h1 className="flex items-center">
            {t("Areas")}
            <span className="ml-4 text-sm text-secondary">
              <NavLink to="/dashboard">{t("Back to Dashboard")}</NavLink>
            </span>
          </h1>
          <p className="max-w-prose">
            Sed turpis tincidunt id aliquet. Feugiat sed lectus vestibulum
            mattis ullamcorper velit sed ullamcorper morbi. Facilisis mauris sit
            amet massa vitae tortor condimentum lacinia quis. Elementum
            curabitur vitae nunc sed velit dignissim.
          </p>
          <Table
            className="max-h-full"
            columns={columns}
            data={areas}
            newAction={() => {
              setArea(null);
              setShowEditModal(true);
            }}
            newActionLabel={t("Create new area")}
            searchLabel={t("Search by area")}
            searchColumns={["name"]}
            onRowClick={(row) => handleRowEdit(row.original)}
          />
        </div>
      </div>

      {showEditModal && (
        <EditAreaModal
          data={area}
          onClose={() => setShowEditModal(false)}
          onSubmit={(data) => handleSubmitEdit(data)}
        />
      )}

      {showConfirmDeleteModal && (
        <ConfirmModal
          title={t("Delete area?")}
          message=<span>
            {t("Are you sure you want to delete the area {{name}}?", {
              name: `${area.name}`,
            })}
          </span>
          yesMessage={t("Delete")}
          destructive={true}
          onClose={() => {
            setArea(null);
            setShowConfirmDeleteModal(false);
          }}
          onSubmit={handleConfirmDelete}
        />
      )}
    </>
  );
};

export default Areas;
