import axiosInstance from "api/axiosInstance";

/**
 *  GET /locations
 *
 *  get all locations
 */
export const getLocations = async () => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get("/location", { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /location/history
 *
 *  get all locations with history items
 */
export const getHistoryLocations = async () => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get("/location/history", { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /location/fish_release
 *
 *  get all locations with fish stocking records
 */
export const getStockingLocations = async () => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get("/location/fish_release", {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /location/fish_return
 *
 *  get all locations with returners records
 */
export const getReturnersLocations = async () => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get("/location/fish_return", {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /location/{id}
 *
 *  get a location by id
 */
export const getLocation = async ({ id }) => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get(`/location/${id}`, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  POST /location
 *
 *  create an location
 */
export const createLocation = async ({
	token,
	name,
	river_id,
	region_id,
	country_id,
	gps_lat,
	gps_lng,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
		river_id: river_id,
		region_id: region_id,
		country_id: country_id,
		gps_lat: gps_lat,
		gps_lng: gps_lng,
	};

	try {
		const response = await axiosInstance.post(`/location`, data, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  PUT /location/{id}
 *
 *  update an location
 */
export const updateLocation = async ({
	token,
	id,
	name,
	river_id,
	region_id,
	country_id,
	gps_lat,
	gps_lng,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
		river_id: river_id,
		region_id: region_id,
		country_id: country_id,
		gps_lat: gps_lat,
		gps_lng: gps_lng,
	};

	try {
		const response = await axiosInstance.put(`/location/${id}`, data, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  DELETE /location/{id}
 *
 *  delete an location
 */
export const deleteLocation = async ({ token, id }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.delete(`/location/${id}`, { headers });
		return response;
	} catch (error) {
		throw error;
	}
};
