import React, { useEffect } from "react";
import { createPortal } from "react-dom";

import "./Modal.scss";

const Backdrop = () => {
  return <div className="backdrop" />;
};

function ModalWindow({ title, className, onClose, children }) {
  useEffect(() => {
    const closeOnEscapeKey = (e) => (e.key === "Escape" ? onClose() : null);

    document.body.addEventListener("keydown", closeOnEscapeKey);

    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [onClose]);

  return (
    <div className={`modal flex flex-col ${className}`}>
      <div className="modal-titlebar ps-4 pe-2 py-2 flex justify-between items-center">
        <h2 className="title pt-1 font-bold text-lg leading-none">{title}</h2>
        <button
          className="btn-close1 text-2xl leading-none"
          onClick={onClose}
          aria-label="close"
        >
          <i className="ri-close-line"></i>
        </button>
      </div>
      <div className="modal-content px-4 py-6 h-full overflow-hidden flex flex-col gap-4 bg-inherit">
        {children}
      </div>
    </div>
  );
}

const Modal = (props) => {
  return (
    <>
      {createPortal(
        <Backdrop {...props} />,
        document.getElementById("overlays"),
      )}
      {createPortal(
        <ModalWindow {...props}>{props.children}</ModalWindow>,
        document.getElementById("overlays"),
      )}
    </>
  );
};

export default Modal;
