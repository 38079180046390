import { useTranslation } from "react-i18next";

import Modal from "components/Modal/Modal";
import Button from "components/Button/Button";

const ConfirmModal = ({
	title = "Confirmation",
	message,
	yesMessage = "OK",
	onSubmit,
	onClose,
	destructive = false,
	...props
}) => {
	const { t } = useTranslation();

	const handleSubmit = () => {
		if (typeof onSubmit === "function") onSubmit();
		onClose();
	};

	return (
		<Modal title={title} onClose={onClose} {...props}>
			<form className="flex flex-col gap-8 w-full" onSubmit={handleSubmit}>
				<div className="m-0">{message}</div>
				<div className="mt-4 flex gap-4 justify-center">
					<Button className="btn" onClick={onClose}>
						{t("Cancel")}
					</Button>
					<Button
						className={destructive ? "btn-delete" : "btn-primary"}
						onClick={handleSubmit}
					>
						{t(yesMessage)}
					</Button>
				</div>
			</form>
		</Modal>
	);
};

export default ConfirmModal;
