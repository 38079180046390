import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { getWaters } from "api/waters";
import { getRegions } from "api/regions";
import { getCountries } from "api/countries";

import Modal from "components/Modal/Modal";
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import Button from "components/Button/Button";
import ErrorHint from "components/ErrorHint/ErrorHint";
import ContentMeta from "components/ContentMeta/ContentMeta";
import {
	GoogleMaps,
	getCenter,
	getZoom,
	getMarker,
} from "components/MapViewer/GoogleMaps";

const EditLocationModal = ({ data, onClose, onSubmit }) => {
	const { t } = useTranslation();
	const [mapRef, setMapRef] = useState();
	const [waters, setWaters] = useState([]);
	const [regions, setRegions] = useState([]);
	const [countries, setCountries] = useState([]);
	const [location, setLocation] = useState(
		data || {
			name: "",
			river_id: null,
			region_id: null,
			region_name: "",
			country_id: null,
			gps_lat: "",
			gps_lng: "",
		},
	);
	const [errors, setErrors] = useState({});

	const title = location.id ? t("Edit location") : t("Create new location");
	const description = location.id
		? t("You can change the details of the location.")
		: t("Fill in the location details.");

	const handleChangeName = (e) => {
		setLocation({ ...location, name: e.target.value });
		if (errors.name) setErrors({ ...errors, name: "" });
	};

	const handleChangeWaterBody = (value) => {
		setLocation({
			...location,
			river_id: value.value,
			river_name: value.label,
		});
		if (errors.river_id) setErrors({ ...errors, river_id: "" });
	};

	const handleChangeRegion = (value) => {
		setLocation({
			...location,
			region_id: value.value,
			region_name: value.label,
		});
		if (errors.region_id) setErrors({ ...errors, region_id: "" });
	};
	const handleChangeCountry = (value) => {
		setLocation({
			...location,
			country_id: value.value,
			country_name: value.label,
		});
		if (errors.country_id) setErrors({ ...errors, country_id: "" });
	};

	const handleChangeGPSLat = (e) => {
		setLocation({ ...location, gps_lat: Number(e.target.value) });
		if (errors.gps) setErrors({ ...errors, gps: "" });
	};

	const handleChangeGPSLng = (e) => {
		setLocation({ ...location, gps_lng: Number(e.target.value) });
		if (errors.gps) setErrors({ ...errors, gps: "" });
	};

	const handleUpdateCoords = () => {
		if (mapRef) {
			setLocation({
				...location,
				gps_lat: mapRef.center.lat(),
				gps_lng: mapRef.center.lng(),
			});
		}
		if (errors.gps) setErrors({ ...errors, gps: "" });
	};

	const validateData = () => {
		const errors = {};

		if (!location.name.trim()) {
			errors.name = t("Location name is required");
		}
		if (!location.river_id) {
			errors.river_id = t("Water body is required");
		}
		if (!location.region_id) {
			errors.region_id = t("Region is required");
		}
		if (!location.country_id) {
			errors.country_id = t("Country is required");
		}
		if (!location.gps_lat || !location.gps_lng) {
			errors.gps = t("GPS coordinates are required");
		}

		return errors;
	};

	const handleSubmit = () => {
		const validationErrors = validateData();

		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
			return;
		}

		if (typeof onSubmit === "function") onSubmit(location);
		setLocation(null);
		onClose();
	};

	// get all waters

	useEffect(() => {
		(async () => {
			const data = await getWaters();

			if (data.code === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.data.detail));
				console.log(data);
			} else {
				const rivers = data.map((obj) => ({
					value: obj.id,
					label: obj.name,
				}));
				setWaters(rivers);
			}
		})();
	}, [t]);

	// get all regions

	useEffect(() => {
		(async () => {
			const data = await getRegions();

			if (data.code === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.data.detail));
				console.log(data);
			} else {
				const regions = data.map((obj) => ({
					value: obj.id,
					label: obj.name,
					adornment: obj.area_name,
				}));
				setRegions(regions);
			}
		})();
	}, [t]);

	// get all countries

	useEffect(() => {
		(async () => {
			const data = await getCountries();

			if (data.code === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.data.detail));
				console.log(data);
			} else {
				const countries = data.map((obj) => ({
					value: obj.id,
					label: obj.name,
				}));
				setCountries(countries);
			}
		})();
	}, [t]);

	return (
		<Modal
			className="w-3/4 h-5/6 min-w-[1024px]"
			title={title}
			onClose={onClose}
			onSubmit={onSubmit}
		>
			<div className="h-full flex gap-8">
				<form
					className="min-w-80 basis-2/5 flex flex-col gap-10 w-full overflow-y-auto px-4 py-6 -my-6 -mx-4"
					onSubmit={handleSubmit}
				>
					<p className="m-0 text-sm">{description}</p>
					<Input
						id="location-name"
						label={t("Location name")}
						type="text"
						name="location-name"
						value={location.name}
						onChange={handleChangeName}
						autoFocus
						required
					/>
					<ErrorHint message={errors.name} />

					<Select
						id="location-water-body"
						label={t("Water body")}
						type="text"
						name="location-water-body"
						value={waters.find((water) => water.value === location.river_id)}
						options={waters}
						allowNone={false}
						onChange={handleChangeWaterBody}
						autoFocus
						required
					/>
					<ErrorHint message={errors.river_id} />

					<Select
						id="location-region"
						label={`${t("Region")} - ${t("Area")}`}
						type="text"
						name="location-region"
						value={regions.find(
							(region) => region.value === location.region_id,
						)}
						options={regions}
						allowNone={false}
						onChange={handleChangeRegion}
						autoFocus
						required
					/>
					<ErrorHint message={errors.region_id} />

					<Select
						id="location-country"
						label={t("Country")}
						type="text"
						name="location-country"
						value={countries.find(
							(country) => country.value === location.country_id,
						)}
						options={countries}
						allowNone={false}
						onChange={handleChangeCountry}
						autoFocus
						required
					/>
					<ErrorHint message={errors.country_id} />

					<div className="flex gap-1">
						<Input
							id="gps-lat"
							label={t("GPS Lat")}
							name="gps-lat"
							value={location.gps_lat}
							onChange={handleChangeGPSLat}
						/>
						<Input
							id="gps-lng"
							label={t("GPS Lng")}
							name="gps-lng"
							value={location.gps_lng}
							onChange={handleChangeGPSLng}
						/>
						<Button
							className="btn shrink w-10 min-w-10 bg-transparent"
							title={t("Update coordinates")}
							onClick={handleUpdateCoords}
						>
							<i className="ri-loop-left-line"></i>
						</Button>
					</div>
					<ErrorHint message={errors.gps} />

					<ContentMeta data={location} />

					<div className="mt-auto flex gap-4 justify-center">
						<Button className="btn-primary" onClick={handleSubmit}>
							{t("Save")}
						</Button>
						<Button className="btn" onClick={onClose}>
							{t("Cancel")}
						</Button>
					</div>
				</form>

				<div className="h-auto w-full relative -my-6 -mx-4">
					<GoogleMaps
						center={getCenter(location)}
						zoom={getZoom(location)}
						markers={getMarker(location)}
						onLoad={(map) => setMapRef(map)}
					/>
					<div className="cursor-center absolute top-1/2 left-1/2 text-red-500 text-2xl translate-x-[-50%] translate-y-[-50%]">
						<i className="ri-add-line"></i>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default EditLocationModal;
