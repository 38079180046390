import axiosInstance from "api/axiosInstance";

/**
 *  GET /user
 *
 *  get all users
 */
export const getProfiles = async ({ token }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get("/user", { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /user/{id}
 *
 *  get a user by id
 */
export const getProfile = async ({ token, id }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get(`/user/${id}`, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  POST /user
 *
 *  create a user
 */
export const createProfile = async ({
	token,
	name,
	// login,
	// password,
	street,
	zip_code,
	city,
	phone,
	mobile,
	fax,
	mail,
	comment,
	superuser = false,
	admin = false,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
		// login: login,
		// password: password,
		street: street,
		zip_code: zip_code,
		city: city,
		phone: phone,
		mobile: mobile,
		fax: fax,
		mail: mail,
		comment: comment,
		superuser: superuser,
		admin: admin,
	};

	try {
		const response = await axiosInstance.post(`/user`, data, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  PUT /user/{id}
 *
 *  update a user
 */
export const updateProfile = async ({
	token,
	id,
	name,
	// login,
	// password,
	street,
	zip_code,
	city,
	phone,
	mobile,
	fax,
	mail,
	comment,
	superuser,
	admin,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
		// login: login,
		// password: password,
		street: street,
		zip_code: zip_code,
		city: city,
		phone: phone,
		mobile: mobile,
		fax: fax,
		mail: mail,
		comment: comment,
		superuser: superuser,
		admin: admin,
	};

	try {
		const response = await axiosInstance.put(`/user/${id}`, data, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  DELETE /user/{id}
 *
 *  delete a user
 */
export const deleteProfile = async ({ token, id }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.delete(`/user/${id}`, { headers });
		return response;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /user_to_organization
 *
 *  get all user_to_organization
 */
export const getU2O = async ({ token }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get("/user_to_organization", {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};
