import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/react";

import "./Select.scss";

const Select = ({
  id,
  className = "",
  label,
  options = null,
  value,
  allowNone = true,
  noneLabel = "None",
  onChange,
  onBlur = null,
  onFocus = null,
  readOnly,
  required = false,
  ...rest
}) => {
  className += ` select-value__${String(value?.value)
    .toLowerCase()
    .replace(" ", "-")}`;

  return (
    <div
      id={id}
      className={`select-container ${className}`}
      readOnly={readOnly}
    >
      <label htmlFor={id} className={value?.label && "filled"}>
        {label}
        {required && " *"}
      </label>
      <Listbox
        defaultValue={value}
        onChange={onChange}
        /**
         * since Listbox is a Fragment, as="div" is needed for the implementation of onBlur
         * onBlur fires twice, once for the button and once for the list
         * the list is what is needed
         */
        as="div"
        onBlur={(e) => {
          if (e.target.tagName.toLowerCase() === "ul" && onBlur) onBlur(e);
        }}
        onFocus={(e) => {
          if (onFocus) onFocus();
        }}
      >
        <ListboxButton className="btn-select flex align-middle justify-between">
          <div className="overflow-ellipsis overflow-hidden max-w-full">
            {value?.label}
          </div>
          {value?.adornment && (
            <div className="adornment ml-auto mr-2 text-sm text-gray-400">
              {value.adornment}
            </div>
          )}
          <div className="ml-2">
            <i className="ri-arrow-down-s-line"></i>
          </div>
        </ListboxButton>
        <ListboxOptions
          anchor="bottom"
          className="listbox-options w-[calc(var(--button-width)+2px)] whitespace-pre-wrap [--anchor-max-height:17rem] z-50 border border-slate-300 rounded-md bg-white [--anchor-gap:0]"
        >
          {allowNone && (
            <ListboxOption
              key={-1}
              value={""}
              className="py-2 px-3 flex cursor-pointer items-center gap-2 select-none whitespace-nowrap data-[focus]:bg-gray-200"
            >
              <span className="mr-auto">
                <i>{noneLabel}</i>
              </span>
              {(value === "" || value === null) && (
                <i className="ri-check-line ml-auto"></i>
              )}
            </ListboxOption>
          )}
          {options &&
            options.map((option, index) => (
              <ListboxOption
                key={index}
                value={option}
                className="py-2 px-3 flex whitespace-pre-wrap cursor-pointer items-center gap-2 select-none whitespace-nowrap data-[focus]:bg-gray-200"
                aria-selected={value?.value === option.value}
              >
                <span
                  className="mr-auto overflow-ellipsis overflow-hidden"
                  title={option.label}
                >
                  {option.label}
                </span>
                {option?.adornment && (
                  <div className="adornment ml-auto mr-2 text-sm text-gray-400">
                    {option.adornment}
                  </div>
                )}
                <div className="w-4">
                  {value?.value === option.value && (
                    <i className="ri-check-line ml-auto"></i>
                  )}
                </div>
              </ListboxOption>
            ))}
        </ListboxOptions>
      </Listbox>
    </div>
  );
};

export default Select;
