import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { useTable } from "react-table";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import SortableListInput from "./SortableListInput";
import SortableListSelect from "./SortableListSelect";
import Button from "components/Button/Button";

import "./SortableList.scss";

const SortableList = ({ label, data, columns, className = "", onChange }) => {
  const { t } = useTranslation();
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [canAdd, setCanAdd] = useState(true);
  const [canUp, setCanUp] = useState(false);
  const [canDown, setCanDown] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const [existsEmpty, setExistsEmpty] = useState(false);
  const SortableListRef = useRef(null);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: columns,
      data: data,
    });

  const classes = ["sortable-list", "flex", "items-stretch"];
  if (className) classes.push(className);

  /* ------------- DATA ITEM ADD -------------
   *
   * deep clone the data array, create a new empty object and add it at the beginning,
   * set flags, call onChange
   */

  const handleRowAdd = useCallback(() => {
    const temp = _.cloneDeep(data);

    const newDataItem = {};
    for (let key in data[0]) {
      newDataItem[key] = undefined;
    }
    temp.unshift(newDataItem);

    setSelectedRowIndex(0);
    setSelectedCell(null);
    setExistsEmpty(true);

    onChange(temp);
  }, [data, onChange]);

  /* ------------- DATA ITEM UP -------------
   *
   * deep clone the data array, swap the row with the previous one,
   * set flags, call onChange
   */

  const handleRowMoveUp = useCallback(() => {
    if (selectedRowIndex === 0) return;

    const temp = _.cloneDeep(data);
    [temp[selectedRowIndex], temp[selectedRowIndex - 1]] = [
      temp[selectedRowIndex - 1],
      temp[selectedRowIndex],
    ];

    setSelectedRowIndex(selectedRowIndex - 1);

    onChange(temp);
  }, [data, selectedRowIndex, onChange]);

  /* ------------- DATA ITEM DOWN -------------
   *
   * deep clone the data array, swap the row with the next one,
   * set flags, call onChange
   */

  const handleRowMoveDown = useCallback(() => {
    if (selectedRowIndex === data.length) return;

    const temp = _.cloneDeep(data);
    [temp[selectedRowIndex], temp[selectedRowIndex + 1]] = [
      temp[selectedRowIndex + 1],
      temp[selectedRowIndex],
    ];

    setSelectedRowIndex(selectedRowIndex + 1);

    onChange(temp);
  }, [data, selectedRowIndex, onChange]);

  /* ------------- DATA ITEM DELETE -------------
   *
   * deep clone the data array, remove the current row,
   * set flags, call onChange
   */

  const handleRowDelete = useCallback(() => {
    // if the row is an empty one, reset the flag
    if (_.every(data[selectedRowIndex], _.flow(_.isUndefined, _.isEmpty)))
      setExistsEmpty(false);

    const temp = _.cloneDeep(data);
    temp.splice(selectedRowIndex, 1);

    // after deleting the last row, stay on the new last row
    if (temp.length > 0 && selectedRowIndex === temp.length) {
      setSelectedRowIndex(temp.length > 0 ? temp.length - 1 : null);
    }

    if (temp.length === 0) setSelectedRowIndex(null);

    onChange(temp);
  }, [data, selectedRowIndex, onChange]);

  /* ------- CELL EDIT (START, CHANGE, END) // TABLE EVENTS HANDLERS -------
   *
   * clicking on a cell starts edit mode
   * typing updates the cell value
   * ending edit sets edit mode to false
   */

  const handleCellStartEdit = useCallback((cell) => {
    setSelectedCell(cell);
    setEditMode(true);
  }, []);

  const handleCellChange = useCallback(
    (cell, value) => {
      const temp = _.cloneDeep(data);
      temp[cell.row.index][cell.column.id] = value;

      onChange(temp);
    },
    [data, onChange],
  );

  const handleCellEndEdit = useCallback(() => {
    console.log(
      "ending cell edit",
      data[selectedRowIndex],
      _.every(data[selectedRowIndex], _.isEmpty),
    );
    setEditMode(false);
    setExistsEmpty(_.every(data[selectedRowIndex], _.isEmpty));
  }, [data, selectedRowIndex]);

  /* ------------- CELL CLICK -------------
   *
   * start editing only when the text or the icon is clicked
   * stop editing when clicking directly on a table cell
   */

  const handleCellClick = useCallback(
    (event, cell) => {
      setSelectedRowIndex(cell.row.index);

      if (["span", "i"].includes(event.target.tagName.toLowerCase())) {
        handleCellStartEdit(cell);
      }

      if (event.target.tagName.toLowerCase() === "td") {
        handleCellEndEdit();
      }
    },
    [handleCellStartEdit, handleCellEndEdit],
  );

  const isEditable = (cell, j) => {
    // return true if a cell is editable and is selected
    // cell === selectedCell does not work maybe because of the "cyclic object value"

    return (
      editMode &&
      selectedCell &&
      cell.row.index === selectedCell.row.index &&
      cell.column.Header === selectedCell.column.Header
    );
  };

  /*---- update the permissions for the actions based on the selectedRowIndex ----*/

  console.log(
    "idx\texistsEmpty\tlen",
    `\n${selectedRowIndex}\t${existsEmpty}\t\t${data.length}`,
  );

  useEffect(() => {
    /*
    if (selectedRowIndex === null && !existsEmpty) {
      setCanAdd(true);
      setCanUp(false);
      setCanDown(false);
      setCanDelete(false);
    } else if (selectedRowIndex === null && existsEmpty) {
      setCanAdd(false);
      setCanUp(false);
      setCanDown(false);
      setCanDelete(false);
    } else if (selectedRowIndex !== null && !existsEmpty) {
      setCanAdd(true);
      setCanUp(selectedRowIndex > 0);
      setCanDown(selectedRowIndex < data.length - 1);
      setCanDelete(true);
    } else if (selectedRowIndex !== null && existsEmpty) {
      setCanAdd(false);
      setCanUp(selectedRowIndex > 0);
      setCanDown(selectedRowIndex < data.length - 1);
      setCanDelete(true);
    }
    */

    setCanAdd(true);
    setCanUp(selectedRowIndex !== null ? selectedRowIndex > 0 : false);
    setCanDown(
      selectedRowIndex !== null ? selectedRowIndex < data.length - 1 : false,
    );
    setCanDelete(selectedRowIndex !== null);
  }, [data, selectedRowIndex, existsEmpty]);

  /*---- debug code: display the rows values ----*/

  useEffect(() => {
    console.table(rows.map((row) => row.values));
  }, [rows]);

  /*---- clicking outside the component ends editing ----*/

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!SortableListRef.current) return;

      // remove selection when the target is not in the SortableListRef

      const ListBoxOptions = document.querySelector("#headlessui-portal-root");

      if (
        !SortableListRef.current.contains(event.target) &&
        !ListBoxOptions?.contains(event.target)
      ) {
        handleCellEndEdit();
        setSelectedRowIndex(null);
        console.log(
          "clicking outside",
          SortableListRef.current,
          event.target,
          SortableListRef.current.contains(event.target),
        );
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handleCellEndEdit]);

  const actions = useMemo(
    () => [
      {
        name: "add",
        icon: "ri-add-line",
        command: handleRowAdd,
        title: t("Add new"),
        disabled: canAdd ? false : true,
      },
      /*{
        name: "edit",
        icon: "ri-pencil-line",
        command: handleRowStartEdit,
        title: t("Edit"),
        disabled: selectedRowIndex ? false : true,
      },*/
      {
        name: "moveUp",
        icon: "ri-arrow-up-line",
        command: handleRowMoveUp,
        title: t("Move up"),
        disabled: canUp ? false : true,
      },
      {
        name: "moveDown",
        icon: "ri-arrow-down-line",
        command: handleRowMoveDown,
        title: t("Move down"),
        disabled: canDown ? false : true,
      },
      {
        name: "delete",
        icon: "ri-delete-bin-line",
        command: handleRowDelete,
        //title: t("Delete"),
        disabled: canDelete ? false : true, // TODO: change to "false : true" when backend fixed
        title: "Noch nicht implementiert",
      },
    ],
    [
      t,
      handleRowAdd,
      handleRowMoveUp,
      handleRowMoveDown,
      // handleRowStartEdit,
      handleRowDelete,
      canAdd,
      canUp,
      canDown,
      canDelete,
    ],
  );

  return (
    <div ref={SortableListRef} className={classes.join(" ")}>
      <div className="flex flex-col gap-4 w-full">
        {label && <h2 className="w-full leading-none">{label}</h2>}
        <div className="h-full flex gap-1">
          <div
            className={`table-wrapper h-[calc(100%-2px)] w-full overflow-scroll border border-gray-300 rounded`}
          >
            {data && (
              <table className="table w-full table-fixed" {...getTableProps()}>
                <thead className="bg-neutral-200">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps()}
                          className={
                            column.cssClass +
                            " text-sm text-bold last-of-type:pr-4"
                          }
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className={`text-left selectable hover:bg-neutral-200/50 ${
                          i === selectedRowIndex ? "selected " : ""
                        }`}
                      >
                        {row.cells.map((cell, j) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className={[
                                cell.column.cssClass,
                                "px-2",
                                `${cell.column.allowEdit ? "editable" : ""}`,
                              ].join(" ")}
                              onClick={(e) => handleCellClick(e, cell)}
                            >
                              {
                                /*
                                 * render the cell with an input, select or just value
                                 * when dataSource defined, then use a drop down, else an input
                                 */

                                !cell.column.allowEdit ? (
                                  cell.render("Cell")
                                ) : cell.column.dataSource ? (
                                  <SortableListSelect
                                    value={cell.column.dataSource.find(
                                      (option) => option.label === cell.value,
                                    )}
                                    options={cell.column.dataSource}
                                    editMode={isEditable(cell, j)}
                                    allowNone={false}
                                    onChange={(value) =>
                                      handleCellChange(cell, value.label)
                                    }
                                  />
                                ) : (
                                  <SortableListInput
                                    value={cell.value}
                                    editMode={isEditable(cell, j)}
                                    onBlur={() => setEditMode(false)}
                                    onChange={(value) =>
                                      handleCellChange(cell, value)
                                    }
                                  />
                                )
                              }
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>

          <div className="actions-wrapper flex flex-col whitespace-nowrap">
            {actions &&
              actions.map((action, index) => (
                <Button
                  type="button"
                  key={index}
                  className={`w-10 h-10 min-w-10 mr-0 mb-[2px] bg-transparent disabled:bg-transparent ${
                    action.disabled ? "disabled" : ""
                  }`}
                  title={action.title}
                  disabled={action.disabled}
                  onClick={(e) => action.command(e, selectedRowIndex)}
                >
                  <i className={action.icon} />
                </Button>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SortableList;
