import axiosInstance from "api/axiosInstance";

/**
 *  GET /state
 *
 *  get all states (state as in county, bundesland)
 */
export const getStates = async () => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get("/state", { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /state/{id}
 *
 *  get a state by id
 */
export const getState = async ({ id }) => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get(`/state/${id}`, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  POST /state
 *
 *  create a state
 */
export const createState = async ({ token, name, country_id }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
		country_id: country_id,
	};

	try {
		const response = await axiosInstance.post(`/state`, data, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  PUT /state/{id}
 *
 *  update a state
 */
export const updateState = async ({ token, id, name, country_id }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
		country_id: country_id,
	};

	try {
		const response = await axiosInstance.put(`/state/${id}`, data, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  DELETE /state/{id}
 *
 *  delete a state
 */
export const deleteState = async ({ token, id }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.delete(`/state/${id}`, { headers });
		return response;
	} catch (error) {
		throw error;
	}
};
