import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useAuth } from "context/AuthContext";

import { getU2O } from "api/profiles";
import { getOrganizations } from "api/organizations";

import Modal from "components/Modal/Modal";
import Input from "components/Input/Input";
import Textarea from "components/Textarea/Textarea";
import SortableList from "components/SortableList/SortableList";
import Button from "components/Button/Button";
import ErrorHint from "components/ErrorHint/ErrorHint";
import ContentMeta from "components/ContentMeta/ContentMeta";

const EditProfileModal = ({ data, onClose, onSubmit }) => {
	const { authData } = useAuth();
	const { t } = useTranslation();
	const [profile, setProfile] = useState({
		id: data.id || "",
		name: data.name || "",
		// login: data.login || "",
		// password: data.password || "",
		street: data.street || "",
		zip_code: data.zip_code || "",
		city: data.city || "",
		phone: data.phone || "",
		mobile: data.mobile || "",
		fax: data.fax || "",
		mail: data.mail || "",
		comment: data.comment || "",
		superuser: data.superuser || false,
		admin: data.admin || false,
	});
	const [organizations, setOrganizations] = useState([]);
	const [errors, setErrors] = useState({});

	const title = profile.id ? t("Edit profile") : t("Create new profile");
	const description =
		(profile.id
			? t("You can update the profile details here.")
			: t("Fill in the profile details.")) +
		t(
			"\nTo make the changes permanent, click the Save button. To discard the changes, close the window.",
		);

	console.log(data);

	const handleChangeName = (e) => {
		setProfile({ ...profile, name: e.target.value });
		if (errors.name) setErrors({ ...errors, name: "" });
	};

	const validateData = () => {
		const errors = {};

		if (!profile.name.trim()) {
			errors.name = t("Profile name is required");
		}

		return errors;
	};

	const handleSubmit = () => {
		const validationErrors = validateData();

		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
			return;
		}

		if (typeof onSubmit === "function") onSubmit(profile);
		setProfile(null);
		onClose();
	};

	// table - columns

	const columns = useMemo(
		() =>
			[
				/*{
					Header: t("ID"),
					accessor: "fish_age_id",
					cssClass: "text-center min-cell-width",
				},*/
				{
					Header: t("Organization name"),
					accessor: "organization_name",
					cssClass: "w-1/2",
					allowEdit: true,
					dataSource: organizations.map((organization) => ({
						value: organization.id,
						label: organization.name,
					})),
				},
				{
					Header: t("Position"),
					accessor: "function",
					cssClass: "w-[calc(50%-1rem)]",
					allowEdit: true,
				},
			].filter((item) => item !== false),
		[organizations, t],
	);

	// get with user_to_organization all organizations a profile is in

	useEffect(() => {
		(async () => {
			const data = await getU2O({ token: authData.access_token });
			if (data.code === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.data.detail));
				console.log(data);
			} else {
				setProfile((prevProfile) => ({
					...prevProfile,
					organizations: data.filter((obj) => obj.user_id === prevProfile.id),
				}));
			}
		})();
	}, [t, authData, setProfile]);

	// get all organizations

	useEffect(() => {
		(async () => {
			const data = await getOrganizations();
			if (data.code === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.data.detail));
				console.log(data);
			} else {
				setOrganizations(data);
			}
		})();
	}, [t]);

	console.log(organizations);

	return (
		<Modal
			className="w-[1000px]"
			title={title}
			onClose={onClose}
			onSubmit={onSubmit}
		>
			<form className="flex flex-col gap-8 w-full" onSubmit={handleSubmit}>
				<p className="m-0 text-sm whitespace-pre-line">{description}</p>
				<div className="flex gap-16">
					<fieldset className="flex-1 flex flex-col gap-10">
						<legend className="pl-3 mb-6 font-light leading-none text-primary">
							{t("Contact information")}
						</legend>
						<Input
							id="profile-name"
							label={t("Name")}
							type="text"
							name="profile-name"
							value={profile.name}
							onChange={handleChangeName}
							autoFocus
							required
						/>
						<ErrorHint message={errors.name} />

						<Input
							id="profile-street"
							label={t("Street")}
							type="text"
							name="profile-street"
							value={profile.street}
							onChange={(e) =>
								setProfile({ ...profile, street: e.target.value })
							}
						/>
						<div className="flex w-full gap-4">
							<Input
								id="profile-zip-code"
								className="w-auto flex-1 whitespace-nowrap min-w-20"
								label={t("ZIP Code")}
								type="text"
								name="profile-zip"
								value={profile.zip_code}
								onChange={(e) =>
									setProfile({ ...profile, zip_code: e.target.value })
								}
							/>
							<Input
								id="profile-city"
								className="w-full"
								label={t("City")}
								type="text"
								name="profile-city"
								value={profile.city}
								onChange={(e) =>
									setProfile({ ...profile, city: e.target.value })
								}
							/>
						</div>
						<div className="flex gap-4 w-full flex-wrap">
							<Input
								id="profile-phone"
								className="flex-1"
								label={t("Phone")}
								type="text"
								name="profile-phone"
								value={profile.phone}
								onChange={(e) =>
									setProfile({ ...profile, phone: e.target.value })
								}
							/>
							<Input
								id="profile-fax"
								className="flex-1"
								label={t("Fax")}
								type="text"
								name="profile-fax"
								value={profile.fax}
								onChange={(e) =>
									setProfile({ ...profile, fax: e.target.value })
								}
							/>
						</div>
						<Input
							id="profile-mobile"
							className="w-full"
							label={t("Mobile")}
							type="text"
							name="profile-mobile"
							value={profile.mobile}
							onChange={(e) =>
								setProfile({ ...profile, mobile: e.target.value })
							}
						/>
						<Input
							id="profile-mail"
							label={t("E-Mail")}
							type="text"
							name="profile-mail"
							value={profile.mail}
							onChange={(e) => setProfile({ ...profile, mail: e.target.value })}
						/>
					</fieldset>
					<div className="w-3/5 flex flex-col gap-6">
						{profile && profile.organizations && (
							<fieldset>
								<legend className="pl-3 mb-6 font-light leading-none text-primary">
									{t("Organizations")}
								</legend>
								<SortableList
									className="h-52"
									data={profile.organizations}
									columns={columns}
									allowEdit={false}
									onChange={(organizations) => {
										setProfile({ ...profile, organizations: organizations });
									}}
								/>
							</fieldset>
						)}
						<fieldset>
							<legend className="pl-3 mb-6 font-light leading-none text-primary">
								{t("Comments")}
							</legend>
							<Textarea
								id="comment"
								className="w-[calc(100%-44px)] h-28"
								label={t("Additional information")}
								name="profile-comment"
								value={profile.comment}
								onChange={(e) =>
									setProfile({ ...profile, comment: e.target.value })
								}
							/>

							<ContentMeta data={profile} />
						</fieldset>
					</div>
				</div>

				<div className="mt-4 flex gap-4 justify-center">
					<Button className="btn-primary" onClick={handleSubmit}>
						{t("Save")}
					</Button>
					<Button className="btn" onClick={onClose}>
						{t("Cancel")}
					</Button>
				</div>
			</form>
		</Modal>
	);
};

export default EditProfileModal;
