import axiosInstance from "api/axiosInstance";

/**
 *  GET /region
 *
 *  get all regions
 */
export const getRegions = async () => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get("/region", { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /region/{id}
 *
 *  get a region by id
 */

export const getRegion = async ({ id }) => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get(`/region/${id}`, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  POST /region
 *
 *  create a region
 */

export const createRegion = async ({ token, name, area_id }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
		area_id: area_id,
	};

	try {
		const response = await axiosInstance.post(`/region`, data, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  PUT /region/{id}
 *
 *  update a region
 */
export const updateRegion = async ({ token, id, name, area_id }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
		area_id: area_id,
	};

	try {
		const response = await axiosInstance.put(`/region/${id}`, data, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  DELETE /region/{id}
 *
 *  delete a region
 */

export const deleteRegion = async ({ token, id }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.delete(`/region/${id}`, { headers });
		return response;
	} catch (error) {
		throw error;
	}
};
