import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Table } from "components/Table/Table";

export const HistoryDetails = ({ data }) => {
  const { t, i18n } = useTranslation();

  // table - columns

  const columns = useMemo(
    () =>
      [
        {
          Header: t("Year"),
          accessor: "year",
          cssClass: "min-cell-width py-2",
        },
        {
          Header: t("Fish type"),
          accessor: "fish_type_name",
          cssClass: "min-cell-width py-2",
        },
        {
          Header: t("Fish age"),
          accessor: "fish_age_name",
          cssClass: "min-cell-width py-2",
        },
        {
          Header: t("Count"),
          accessor: "count",
          cssClass: "min-cell-width text-right py-2",
          Cell: ({ value, row }) => (
            <div className="pr-4">{value?.toLocaleString(i18n.language)}</div>
          ),
        },
        {
          Header: t("Weight"),
          accessor: "weight",
          cssClass:
            "min-cell-width max-w-[100px] overflow-hidden text-right text-ellipsis py-2",
          Cell: ({ value, row }) => (
            <div className="pr-4">
              {value ? value.toLocaleString(i18n.language) + " kg" : "-"}
            </div>
          ),
        },
        {
          Header: t("Fish origin"),
          accessor: "fish_origin_name",
          cssClass: "min-cell-width py-2 text-center",
          Cell: ({ value }) => value ?? <div className="text-center">-</div>,
        },
        {
          Header: t("Source"),
          accessor: "source",
          cssClass:
            "min-cell-width whitespace-nowrap overflow-hidden text-ellipsis py-2",
        },
      ].filter((item) => item !== false),
    [t, i18n],
  );

  return (
    <div className="details history-details">
      <Table
        className="max-h-full max-w-full text-sm font-light"
        columns={columns}
        data={data}
        showSearch={false}
      />
    </div>
  );
};
