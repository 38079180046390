import axiosInstance from "api/axiosInstance";

/**
 *  GET /fish
 *
 *  get all fish types
 */
export const getFishTypes = async () => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get("/fish", { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /fish/{id}
 *
 *  get a fish type by id
 */
export const getFishType = async ({ id }) => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get(`/fish/${id}`, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  POST /fish
 *
 *  create an fish type
 */
export const createFishType = async ({ token, name, stadiums }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
		stadiums: stadiums,
	};

	try {
		const response = await axiosInstance.post(`/fish`, data, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  PUT /fish/{id}
 *
 *  update an fish type
 */
export const updateFishType = async ({ token, id, name, stadiums }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
		stadiums: stadiums,
	};

	try {
		const response = await axiosInstance.put(`/fish/${id}`, data, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  DELETE /fish/{id}
 *
 *  delete a fish type
 */
export const deleteFishType = async ({ token, id }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.delete(`/fish/${id}`, { headers });
		return response;
	} catch (error) {
		throw error;
	}
};
