import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./Sidebar.scss";

const SideBar = ({ navLinks }) => {
  const { t } = useTranslation();
  const [sidebarIsOpen, setSidebarIsOpen] = useState(
    !(window.innerWidth < 1400),
  );

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 1400 && sidebarIsOpen) setSidebarIsOpen(false);
      if (window.innerWidth >= 1400 && !sidebarIsOpen) setSidebarIsOpen(true);
    };

    window.addEventListener("resize", handleWindowResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [sidebarIsOpen]);

  return (
    <div
      className={`sidebar overflow-auto shrink-0 ${
        sidebarIsOpen ? "sidebar--open" : "sidebar--closed"
      }`}
    >
      <nav className="sidebar__content nav h-full pt-4 border-r border-1 border-neutral-300">
        <ul className="nav__list h-full flex flex-col">
          {navLinks.map((item, index) => {
            if (item.caption === "_separator_")
              return (
                <li
                  className="nav__separator border-b border-neutral-300"
                  key={index}
                ></li>
              );

            const classes = ["nav__item"];
            if (item.className) classes.push(item.className);

            return (
              <li className={`nav__item ${classes.join(" ")}`} key={index}>
                {item.path && (
                  <NavLink
                    end
                    to={item.path}
                    className="nav__link p-2 flex gap-2 whitespace-normal"
                    title={item.title ? item.title : item.caption}
                  >
                    <i className={item.icon}></i>
                    <span className={`${!sidebarIsOpen && "hidden"}`}>
                      {item.caption}
                    </span>
                  </NavLink>
                )}
                {item.action && (
                  <button
                    className="nav__button p-2 flex gap-2 whitespace-normal"
                    onClick={() => item.action()}
                  >
                    <i className={item.icon}></i>
                    <span
                      className={`caption text-sm ${
                        !sidebarIsOpen && "hidden"
                      }`}
                    >
                      {item.caption}
                    </span>
                  </button>
                )}
              </li>
            );
          })}

          <li className="nav__item sidebar__control mt-8">
            <button
              className="nav__button"
              onClick={(e) => {
                e.preventDefault();
                setSidebarIsOpen(!sidebarIsOpen);
              }}
            >
              <i
                className={`ri-arrow-left-s-line ${
                  !sidebarIsOpen && "rotate-180"
                }`}
              ></i>
              <span className={`caption text-sm ${!sidebarIsOpen && "hidden"}`}>
                {t("Collapse")}
              </span>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default SideBar;
