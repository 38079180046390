import { useMemo, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useAuth } from "context/AuthContext";
import { useError } from "context/ErrorContext";

import {
  getProfiles,
  createProfile,
  updateProfile,
  deleteProfile,
} from "api/profiles";

import { Table, Actions } from "components/Table/Table";
import EditProfileModal from "components/EditProfileModal";
import ConfirmModal from "components/ConfirmModal";

/**
 * PROFILE
 *
 * /profiles
 *
 */

const Profiles = () => {
  const { authData } = useAuth();
  const { showError } = useError();
  const { t } = useTranslation();

  const [profiles, setProfiles] = useState([]);
  const [profile, setProfile] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);

  const handleRowEdit = (data) => {
    setProfile(data);
    setShowEditModal(true);
  };

  const handleRowDelete = (data) => {
    setProfile(data);
    setShowConfirmDeleteModal(true);
  };

  const handleSubmitEdit = async (data) => {
    if (profile) {
      try {
        const response = await updateProfile({
          token: authData?.access_token,
          ...data,
        });

        if (response) {
          toast.success(t("Profile updated!"));
          setRefreshTable(true);
        }
      } catch (error) {
        console.error("Error updating profile:", error);
        showError(error.response.data.detail);
      }
    } else {
      try {
        const response = await createProfile({
          token: authData?.access_token,
          ...data,
        });

        if (response && response?.id) {
          toast.success(t("Profile created!"));
          setRefreshTable(true);
        }
      } catch (error) {
        console.error("Error creating profile:", error);
        showError(error.response.data.detail);
      }
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setShowConfirmDeleteModal(false);

      const response = await deleteProfile({
        token: authData.access_token,
        id: profile.id,
      });

      if (response.status === 200 || response.status === 204) {
        setProfile(null);
        setRefreshTable(true);
        toast.success(t("Profile deleted!"));
      }
    } catch (error) {
      console.error("Error deleting profile:", error);
      showError(error.response.data.detail);
    }
  };

  // table - row actions

  const actions = useMemo(
    () => [
      {
        name: "edit",
        icon: "ri-pencil-line",
        command: handleRowEdit,
        title: t("Edit"),
      },
      {
        name: "delete",
        icon: "ri-delete-bin-line",
        command: handleRowDelete,
        title: t("Delete"),
      },
    ],
    [t],
  );

  // table - columns

  const columns = useMemo(
    () =>
      [
        {
          Header: t("Profile"),
          accessor: "name",
        },
        {
          Header: t("Phone"),
          accessor: "phone",
        },
        {
          Header: t("Mobile"),
          accessor: "mobile",
        },
        {
          Header: t("E-mail"),
          accessor: "mail",
        },
        actions.length > 0 && {
          Header: t("Actions"),
          accessor: "accessor",
          cssClass: "text-center min-cell-width",
          disableSortBy: true,
          Cell: ({ row: { original } }) => (
            <Actions row={original} actions={actions} />
          ),
        },
      ].filter((item) => item !== false),
    [actions, t],
  );

  // get all profiles

  useEffect(() => {
    (async () => {
      const data = await getProfiles({ token: authData.access_token });
      if (data.code === "ERR_BAD_REQUEST") {
        toast.error(t(data.response.data.detail));
        console.log(data);
      } else {
        setProfiles(data);
        setRefreshTable(false);
      }
    })();
  }, [refreshTable, t, authData]);

  return (
    <>
      <div className="profiles-container relative flex-grow h-full px-16 overflow-auto">
        <div className="content-wrapper max-h-full flex flex-col">
          <h1 className="flex items-center">
            {t("Profiles")}
            <span className="ml-4 text-sm text-secondary">
              <NavLink to="/dashboard">{t("Back to Dashboard")}</NavLink>
            </span>
          </h1>
          <p className="max-w-prose">
            Sed turpis tincidunt id aliquet. Feugiat sed lectus vestibulum
            mattis ullamcorper velit sed ullamcorper morbi. Facilisis mauris sit
            amet massa vitae tortor condimentum lacinia quis. Elementum
            curabitur vitae nunc sed velit dignissim.
          </p>
          <Table
            className="max-h-full"
            columns={columns}
            data={profiles}
            newAction={() => {
              setProfile(null);
              setShowEditModal(true);
            }}
            newActionLabel={t("Create new profile")}
            searchLabel={t("Search by name")}
            searchColumns={["name"]}
            onRowClick={(row) => handleRowEdit(row.original)}
          />
        </div>
      </div>

      {showEditModal && (
        <EditProfileModal
          data={profile}
          onClose={() => setShowEditModal(false)}
          onSubmit={(data) => handleSubmitEdit(data)}
        />
      )}

      {showConfirmDeleteModal && (
        <ConfirmModal
          title={t("Delete profile?")}
          message=<span>
            {t("Are you sure you want to delete the profile {{name}}?", {
              name: `${profile.name}`,
            })}
          </span>
          yesMessage={t("Delete")}
          destructive={true}
          onClose={() => {
            setProfile(null);
            setShowConfirmDeleteModal(false);
          }}
          onSubmit={handleConfirmDelete}
        />
      )}
    </>
  );
};

export default Profiles;
