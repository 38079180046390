import axiosInstance from "api/axiosInstance";

/**
 *  GET /waters
 *
 *  get all water bodies
 */
export const getWaters = async () => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get("/river", { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /river/{id}
 *
 *  get a water body by id
 */

export const getWater = async ({ id }) => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get(`/river/${id}`, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  POST /river
 *
 *  create a water body
 */

export const createWater = async ({
	token,
	name,
	area_id,
	gps_lat,
	gps_lng,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
		area_id: area_id,
		gps_lat: gps_lat || null,
		gps_lng: gps_lng || null,
	};

	try {
		const response = await axiosInstance.post(`/river`, data, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  PUT /river/{id}
 *
 *  update a water body
 */
export const updateWater = async ({
	token,
	id,
	name,
	area_id,
	gps_lat,
	gps_lng,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
		area_id: area_id,
		gps_lat: gps_lat || null,
		gps_lng: gps_lng || null,
	};

	try {
		const response = await axiosInstance.put(`/river/${id}`, data, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  DELETE /river/{id}
 *
 *  delete a water body
 */

export const deleteWater = async ({ token, id }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.delete(`/river/${id}`, { headers });
		return response;
	} catch (error) {
		throw error;
	}
};
