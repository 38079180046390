import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { getAreas } from "api/areas";

import Modal from "components/Modal/Modal";
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import Button from "components/Button/Button";
import ErrorHint from "components/ErrorHint/ErrorHint";
import ContentMeta from "components/ContentMeta/ContentMeta";

const EditRegionModal = ({ data, onClose, onSubmit }) => {
	const { t } = useTranslation();
	const [region, setRegion] = useState(
		data || { id: "", name: "", area_id: "", area_name: "" },
	);
	const [areas, setAreas] = useState([]);
	const [errors, setErrors] = useState({});

	const title = region.id ? t("Edit region") : t("Create new region");
	const description = region.id
		? t("You can change the name of the region or the area.")
		: t("Fill in the name of the region and select the area.");

	// get all areas

	useEffect(() => {
		(async () => {
			const data = await getAreas();

			if (data.code === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.data.detail));
				console.log(data);
			} else {
				const areas = data.map((obj) => ({
					value: obj.id,
					label: obj.name,
				}));
				setAreas(areas);
			}
		})();
	}, [region, t]);

	const handleChangeName = (e) => {
		setRegion({ ...region, name: e.target.value });
		if (errors.name) setErrors({ ...errors, name: "" });
	};

	const handleChangeArea = (value) => {
		setRegion({
			...region,
			area_id: value.value,
			area_name: value.label,
		});
		if (errors.area_id) setErrors({ ...errors, area_id: "" });
	};

	const validateData = () => {
		const errors = {};

		if (!region.name.trim()) {
			errors.name = t("Region name is required");
		}
		if (!region.area_id) {
			errors.area_id = t("Area is required");
		}

		return errors;
	};

	const handleSubmit = () => {
		const validationErrors = validateData();

		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
			return;
		}

		if (typeof onSubmit === "function") onSubmit(region);
		setRegion(null);
		onClose();
	};

	return (
		<Modal title={title} onClose={onClose} onSubmit={onSubmit}>
			<form className="flex flex-col gap-10 w-full" onSubmit={handleSubmit}>
				<p className="text-sm">{description}</p>
				<Input
					id="region-name"
					label={t("Region name")}
					type="text"
					name="region-name"
					value={region.name}
					onChange={handleChangeName}
					autoFocus
					required
				/>
				<ErrorHint message={errors.name} />

				<Select
					label={t("Area name")}
					options={areas}
					value={{ label: region.area_name, value: region.area_id }}
					allowNone={false}
					onChange={handleChangeArea}
					required
				/>
				<ErrorHint message={errors.area_id} />

				<ContentMeta data={region} />

				<div className="flex gap-4 justify-center">
					<Button className="btn-primary" onClick={handleSubmit}>
						{t("Save")}
					</Button>
					<Button className="btn" onClick={onClose}>
						{t("Cancel")}
					</Button>
				</div>
			</form>
		</Modal>
	);
};

export default EditRegionModal;
