import { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal/Modal";
import Input from "components/Input/Input";
import Button from "components/Button/Button";
import ErrorHint from "components/ErrorHint/ErrorHint";

const EditAreaModal = ({ data, onClose, onSubmit }) => {
	const { t } = useTranslation();
	const [area, setArea] = useState(data || { id: "", name: "" });
	const [errors, setErrors] = useState({});

	const title = area.id ? t("Edit area") : t("Create new area");
	const description = area.id
		? t("You can change the name of the area.")
		: t("Fill in the name of the area.");

	const handleChangeArea = (e) => {
		setArea({ ...area, name: e.target.value });
		if (errors.name) setErrors({ ...errors, name: "" });
	};

	const validateData = () => {
		const errors = {};

		if (!area.name.trim()) {
			errors.name = t("Area name is required");
		}

		return errors;
	};

	const handleSubmit = () => {
		const validationErrors = validateData();

		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
			return;
		}

		if (typeof onSubmit === "function") onSubmit(area);
		setArea(null);
		onClose();
	};

	return (
		<Modal title={title} onClose={onClose} onSubmit={onSubmit}>
			<form className="flex flex-col gap-10 w-full" onSubmit={handleSubmit}>
				<p className="m-0 text-sm">{description}</p>
				<Input
					id="area-name"
					label={t("Area name")}
					type="text"
					name="area-name"
					value={area.name}
					onChange={handleChangeArea}
					autoFocus
					required
				/>
				<ErrorHint message={errors.name} />

				<div className="mt-4 flex gap-4 justify-center">
					<Button className="btn-primary" onClick={handleSubmit}>
						{t("Save")}
					</Button>
					<Button className="btn" onClick={onClose}>
						{t("Cancel")}
					</Button>
				</div>
			</form>
		</Modal>
	);
};

export default EditAreaModal;
