import { useTranslation } from "react-i18next";

const Terms = () => {
  const { t } = useTranslation();

  return (
    <div className="content-container relative flex-grow h-full px-16 overflow-scroll">
      <div className="content-wrapper max-w-prose">
        <h1>{t("Terms and Conditions")}</h1>
        <p>
          Sed turpis tincidunt id aliquet. Feugiat sed lectus vestibulum mattis
          ullamcorper velit sed ullamcorper morbi. Facilisis mauris sit amet
          massa vitae tortor condimentum lacinia quis. Elementum curabitur vitae
          nunc sed velit dignissim. Sed ullamcorper morbi tincidunt ornare massa
          eget egestas purus viverra. Tortor condimentum lacinia quis vel eros
          donec ac odio. Viverra accumsan in nisl nisi. Consequat id porta nibh
          venenatis cras sed felis eget velit.
        </p>
      </div>
    </div>
  );
};

export default Terms;
