import axiosInstance from "api/axiosInstance";

/**
 *  GET /area
 *
 *  get all areas
 */
export const getAreas = async () => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get("/area", { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /area/{id}
 *
 *  get an area by id
 */
export const getArea = async ({ id }) => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get(`/area/${id}`, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  POST /area
 *
 *  create an area
 */
export const createArea = async ({ token, name }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
	};

	try {
		const response = await axiosInstance.post(`/area`, data, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  PUT /area/{id}
 *
 *  update an area
 */
export const updateArea = async ({ token, id, name }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
	};

	try {
		const response = await axiosInstance.put(`/area/${id}`, data, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  DELETE /area/{id}
 *
 *  delete an area
 */
export const deleteArea = async ({ token, id }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.delete(`/area/${id}`, { headers });
		return response;
	} catch (error) {
		throw error;
	}
};
