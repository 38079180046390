import Button from "components/Button/Button";

import "./Input.scss";

const File = ({
  id,
  className = "",
  label,
  value,
  buttonCaption = "Open",
  accept = "",
  adornment,
  description = "",
  onChange,
  onOpen,
  ...rest
}) => {
  let classes = ["upload-container flex flex-col"];

  if (className) classes.push(className);

  return (
    <div className={classes.join(" ")}>
      <div className="flex gap-4">
        <div className="input-container w-full">
          <label htmlFor={id} className={String(value) && "filled"}>
            {label}
            {rest.required && !rest.readOnly ? " *" : ""}
          </label>
          <input
            type="file"
            id={id}
            value={value}
            accept={accept}
            onChange={onChange}
            {...rest}
          />
        </div>
        <Button className="btn-primary" onClick={onOpen}>
          {buttonCaption}
        </Button>
      </div>
      {description && (
        <div className="mt-2 text-sm font-light">{description}</div>
      )}
    </div>
  );
};

export default File;
