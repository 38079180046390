import { useMemo, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useAuth } from "context/AuthContext";
import { useError } from "context/ErrorContext";

import {
  getOrganizations,
  createOrganization,
  updateOrganization,
  deleteOrganization,
} from "api/organizations";

import { Table, Actions } from "components/Table/Table";
import EditOrganizationModal from "components/EditOrganizationModal";
import ConfirmModal from "components/ConfirmModal";

/**
 * ORGANISATIONEN
 *
 * /organizations
 *
 */

const Organisations = () => {
  const { authData } = useAuth();
  const { showError } = useError();
  const { t } = useTranslation();

  const [organizations, setOrganizations] = useState([]);
  const [organization, setOrganization] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);

  const handleRowEdit = (organization) => {
    setOrganization(organization);
    setShowEditModal(true);
  };

  function handleRowDelete(organization) {
    setOrganization(organization);
    setShowConfirmDeleteModal(true);
  }

  const handleSubmitEdit = async (data) => {
    if (organization) {
      try {
        const response = await updateOrganization({
          token: authData?.access_token,
          ...data,
        });

        // if (response.status === 200) {
        if (response) {
          setRefreshTable(true);
          toast.success(t("Organization updated!"));
        }
      } catch (error) {
        console.error("Error updating organization:", error);
        showError(error.response.data.detail);
      }
    } else {
      try {
        const response = await createOrganization({
          token: authData?.access_token,
          ...data,
        });

        if (response && response?.id) {
          setRefreshTable(true);
          toast.success(t("Organization created!"));
        }
      } catch (error) {
        console.error("Error creating organization:", error);
        showError(error.response.data.detail);
      }
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setShowConfirmDeleteModal(false);

      const response = await deleteOrganization({
        token: authData.access_token,
        id: organization.id,
      });

      if (response.status === 200 || response.status === 204) {
        setOrganization(null);
        setRefreshTable(true);
        toast.success(t("Organization deleted!"));
      }
    } catch (error) {
      console.error("Error deleting organization:", error);
      showError(error.response.data.detail);
    }
  };

  // table - row actions

  const actions = useMemo(
    () => [
      {
        name: "edit",
        icon: "ri-pencil-line",
        command: handleRowEdit,
        title: t("Edit"),
      },
      {
        name: "delete",
        icon: "ri-delete-bin-line",
        command: handleRowDelete,
        title: t("Delete"),
      },
    ],
    [t],
  );

  // table - columns

  const columns = useMemo(
    () =>
      [
        {
          Header: t("Name"),
          accessor: "name",
        },
        {
          Header: t("State"),
          accessor: "state_name",
        },
        {
          Header: t("Address"),
          accessor: "street",
        },
        {
          Header: t("ZIP Code"),
          accessor: "zip_code",
        },
        {
          Header: t("City"),
          accessor: "city",
        },
        actions.length > 0 && {
          Header: t("Actions"),
          accessor: "accessor",
          cssClass: "text-center min-cell-width",
          disableSortBy: true,
          Cell: ({ row: { original } }) => (
            <Actions row={original} actions={actions} />
          ),
        },
      ].filter((item) => item !== false),
    [actions, t],
  );

  // get all organizations

  useEffect(() => {
    (async () => {
      const data = await getOrganizations();
      if (data.code === "ERR_BAD_REQUEST") {
        toast.error(t(data.response.data.detail));
        console.log(data);
      } else {
        setOrganizations(data);
        setRefreshTable(false);
      }
    })();
  }, [refreshTable, t]);

  return (
    <>
      <div className="organizations-container relative flex-grow h-full px-16 overflow-auto">
        <div className="content-wrapper max-h-full flex flex-col">
          <h1 className="flex items-center">
            {t("Organizations")}
            <span className="ml-4 text-sm text-secondary">
              <NavLink to="/dashboard">{t("Back to Dashboard")}</NavLink>
            </span>
          </h1>
          <p className="max-w-prose">
            Sed turpis tincidunt id aliquet. Feugiat sed lectus vestibulum
            mattis ullamcorper velit sed ullamcorper morbi. Facilisis mauris sit
            amet massa vitae tortor condimentum lacinia quis. Elementum
            curabitur vitae nunc sed velit dignissim.
          </p>
          <Table
            className="max-h-full"
            columns={columns}
            data={organizations}
            newAction={() => {
              setOrganization(null);
              setShowEditModal(true);
            }}
            newActionLabel={t("Create new organization")}
            searchLabel={t("Search by organization or city")}
            searchColumns={["name", "city"]}
            onRowClick={(row) => handleRowEdit(row.original)}
          />
        </div>
      </div>

      {showEditModal && (
        <EditOrganizationModal
          data={organization}
          onClose={() => setShowEditModal(false)}
          onSubmit={(data) => handleSubmitEdit(data)}
        />
      )}

      {showConfirmDeleteModal && (
        <ConfirmModal
          title={t("Delete organization?")}
          message=<span>
            {t("Are you sure you want to delete the organization {{name}}?", {
              name: `${organization.name}`,
            })}
          </span>
          yesMessage={t("Delete")}
          destructive={true}
          onClose={() => {
            setOrganization(null);
            setShowConfirmDeleteModal(false);
          }}
          onSubmit={handleConfirmDelete}
        />
      )}
    </>
  );
};

export default Organisations;
