import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Input from "components/Input/Input";
import Password from "components/Input/Password";
import Button from "components/Button/Button";

const Login = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg /*, setErrorMsg*/] = useState("");

  const handleSubmit = () => {};

  return (
    <div className="register-container relative flex-grow h-full overflow-scroll flex justify-center items-center">
      <div className="content-wrapper mb-32 flex flex-col gap-4 items-center">
        <h1>{t("Sign up")}</h1>
        <form className="card w-80 flex flex-col gap-4" onSubmit={handleSubmit}>
          {errorMsg ? (
            <p className="error">
              <i className="ri-error-warning-fill mr-1"></i>
              {errorMsg}
            </p>
          ) : (
            ""
          )}
          <Input
            id="email"
            label={t("E-mail")}
            type="email"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            id="firstName"
            label={t("First name")}
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Input
            id="lastName"
            label={t("Last name")}
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <Password
            id="password"
            label={t("Password")}
            value={password}
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          <p className="text-sm">
            {t("By clicking Sign Up, you agree to the")}{" "}
            <Link to="/policies">
              {t("Terms of Service and Privacy Policy")}
            </Link>
            .
          </p>
          <Button className="btn-primary">{t("Sign up")}</Button>
        </form>
        <Link className="text-sm" to="/login">
          {t("I already have an account")}
        </Link>
      </div>
    </div>
  );
};

export default Login;
