import axiosInstance from "api/axiosInstance";

/**
 *  GET /organization
 *
 *  get all organizations
 */
export const getOrganizations = async () => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get("/organization", { headers });

		// convert gps_long to gps_lng
		const modifiedData = response.data.map((organization) => ({
			id: organization.id,
			name: organization.name,
			street: organization.street || "",
			zip_code: organization.zip_code || "",
			city: organization.city || "",
			state_id: organization.state_id,
			state_name: organization.state_name,
			gps_lat: organization.gps_lat || "",
			gps_lng: organization.gps_long || "",
			comment: organization.comment || "",
		}));

		return modifiedData;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /organization/{id}
 *
 *  get an organization by id
 */

export const getOrganization = async ({ id }) => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get(`/organization/${id}`, {
			headers,
		});

		// convert gps_long to gps_lng
		return {
			...response.data,
			gps_lat: response.data.gps_lat || "",
			gps_lng: response.data.gps_long || "",
		};
	} catch (error) {
		throw error;
	}
};

/**
 *  POST /organization
 *
 *  create an organization
 */

export const createOrganization = async ({
	token,
	name,
	street,
	zip_code,
	city,
	state_id,
	gps_lat,
	gps_lng,
	comment,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
		street: street,
		zip_code: zip_code,
		city: city,
		state_id: state_id,
		gps_lat: gps_lat || null,
		gps_long: gps_lng || null,
		comment: comment,
	};

	try {
		const response = await axiosInstance.post(`/organization`, data, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  PUT /organization/{id}
 *
 *  update an organization
 */
export const updateOrganization = async ({
	token,
	id,
	name,
	street,
	zip_code,
	city,
	state_id,
	gps_lat,
	gps_lng,
	comment,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
		street: street,
		zip_code: zip_code,
		city: city,
		state_id: state_id,
		gps_lat: gps_lat || null,
		gps_long: gps_lng || null,
		comment: comment,
	};

	try {
		const response = await axiosInstance.put(`/organization/${id}`, data, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  DELETE /organization/{id}
 *
 *  delete an organization
 */
export const deleteOrganization = async ({ token, id }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.delete(`/organization/${id}`, {
			headers,
		});
		return response;
	} catch (error) {
		throw error;
	}
};
