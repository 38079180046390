import axiosInstance from "api/axiosInstance";

/**
 *  GET /fish_origin
 *
 *  get all fish origins
 */
export const getFishOrigins = async () => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get("/fish_origin", { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /fish_origin/{id}
 *
 *  get a fish origin by id
 */
export const getFishOrigin = async ({ id }) => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get(`/fish_origin/${id}`, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  POST /fish_origin
 *
 *  create a fish origin
 */
export const createFishOrigin = async ({ token, name }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
	};

	try {
		const response = await axiosInstance.post(`/fish_origin`, data, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  PUT /fish_origin/{id}
 *
 *  update a fish origin
 */
export const updateFishOrigin = async ({ token, id, name }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		name: name,
	};

	try {
		const response = await axiosInstance.put(`/fish_origin/${id}`, data, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  DELETE /fish_origin/{id}
 *
 *  delete a fish origin
 */
export const deleteFishOrigin = async ({ token, id }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.delete(`/fish_origin/${id}`, {
			headers,
		});
		return response;
	} catch (error) {
		throw error;
	}
};
