const ErrorHint = ({ message }) => {
	return (
		<>
			{message && (
				<div className="error-hint h-0 -mt-10 text-red-500 text-xs">
					<span className="p-[2px] inline-block" role="alert">
						{message}
					</span>
				</div>
			)}
		</>
	);
};

export default ErrorHint;
