import axiosInstance from "api/axiosInstance";

/**
 *  GET /history
 *
 *  get all history records (be careful, lots of data! use the endpoint with pagination)
 */
export const getHistories = async () => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get("/history", { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /history/page/{page_number}
 *
 *  get all history records using pagination
 */
export const getHistoriesPage = async ({
	pageNumber,
	pageSize = 20,
	sortBy = null,
	sortOrder = null,
}) => {
	const headers = {
		"Content-Type": "application/json",
	};
	const params = new URLSearchParams({
		page_number: pageNumber,
		page_size: pageSize,
		...(sortBy && { sort_by: sortBy }), // add sortBy only if it is not null
		...(sortOrder && { sort_order: sortOrder }), // add sortOrder only if it is not null
	});

	try {
		const response = await axiosInstance.get(
			`/history/page?${params.toString()}`,
			{ headers },
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /history/{id}
 *
 *  get a history record by id
 */
export const getHistory = async ({ id }) => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get(`/history/${id}`, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  GET /history/location/{id}
 *
 *  get all history records for a specific location
 */
export const getLocationHistory = async ({ id }) => {
	const headers = {
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.get(`/history/location/${id}`, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  POST /history
 *
 *  create a history record
 */
export const createHistory = async ({
	token,
	year,
	fish_type_id,
	fish_age_id,
	fish_origin_id,
	count,
	weight,
	city_id,
	comment,
	source,
	import_id,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		year: year,
		fish_type_id: fish_type_id,
		fish_age_id: fish_age_id,
		fish_origin_id: fish_origin_id,
		count: count,
		weight: weight,
		city_id: city_id,
		comment: comment,
		source: source,
		import_id: import_id,
	};

	try {
		const response = await axiosInstance.post(`/history`, data, { headers });
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  PUT /history/{id}
 *
 *  update a history record
 */
export const updateHistory = async ({
	id,
	token,
	year,
	fish_type_id,
	fish_age_id,
	fish_origin_id,
	count,
	weight,
	city_id,
	comment,
	source,
	import_id,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = {
		year: year,
		fish_type_id: fish_type_id,
		fish_age_id: fish_age_id,
		fish_origin_id: fish_origin_id,
		count: count,
		weight: weight,
		city_id: city_id,
		comment: comment,
		source: source,
		import_id: import_id,
	};

	try {
		const response = await axiosInstance.put(`/history/${id}`, data, {
			headers,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

/**
 *  DELETE /history/{id}
 *
 *  delete a history record
 */
export const deleteHistory = async ({ token, id }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	try {
		const response = await axiosInstance.delete(`/history/${id}`, { headers });
		return response;
	} catch (error) {
		throw error;
	}
};

/**
 *  POST /history/bulk_delete
 *
 *  delete multiple recordes from history
 */
export const deleteHistoryBulk = async ({ token, ids }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	const data = ids;

	console.log(data);

	try {
		const response = await axiosInstance.post(`/history/bulk_delete`, data, {
			headers,
		});
		console.log(response);
		return response;
	} catch (error) {
		console.log(error);
		throw error;
	}
};
