import { useTranslation } from "react-i18next";

export const OrganizationDetails = ({ data }) => {
  const { t } = useTranslation();

  if (!data) return;

  // exit if no details available. this way the modal-content remins empty and can be hidden in Details.jsx
  if (
    data.street === "" &&
    data.zip_code === "" &&
    data.city === "" &&
    data.contacts.length === 0 &&
    data.comment === ""
  )
    return;

  return (
    <div className="details organization-details flex flex-col gap-3 text-sm">
      {(data?.street || data?.zip_code || data?.city) && (
        <div className="flex gap-2" title={t("Address")}>
          <div className="w-6 text-right">
            <i className="ri-map-pin-line"></i>
          </div>
          <p className="font-normal">
            {data?.street}, {data?.zip_code} {data?.city}
          </p>
        </div>
      )}
      {data?.contacts?.length > 0 && data.contacts[0].name && (
        <div className="flex gap-2" title={t("Contact")}>
          <div className="w-6 text-right">
            <i className="ri-user-line"></i>
          </div>
          <p className="font-normal">
            {data.contacts.map((contact, index) => (
              <div key={index}>
                {contact.name}
                {contact.function && (
                  <span className="font-light"> - {contact.function}</span>
                )}
                <br />
              </div>
            ))}
          </p>
        </div>
      )}
      {data?.comment && (
        <div className="flex gap-2" title={t("Comments")}>
          <div className="w-6 text-right">
            <i className="ri-sticky-note-line"></i>
          </div>
          <p className="max-w-prose">{data?.comment}</p>
        </div>
      )}

      {/*!(
        data?.street ||
        data?.zip_code ||
        data?.city ||
        data?.contact ||
        data?.comment
      ) && (
        <div className="flex gap-2" title={t("Comments")}>
          <div className="w-6 text-right">
            <i className="ri-sticky-note-line"></i>
          </div>
          <p className="text-sm">{t("No details available")}</p>
        </div>
      )*/}
    </div>
  );
};
